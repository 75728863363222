import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommunicationService } from '../services/communication.service';
import { CatastrophicError } from '../services/interfaces/OrgConfig';
import { UiFlowStateService } from '../services/ui-flow-state.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cp-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements   OnInit {
  error: CatastrophicError | null = null;
  constructor(private commSvc: CommunicationService, private router: Router, private stateSvc: UiFlowStateService) {}

  ngOnInit() {
    this.stateSvc.$catastrophicError.pipe(map((error) => {
      this.error = error;
    })).subscribe();
  }

  nextClicked() {
    this.commSvc.skipSubmitNextRoute();
  }
}

<p>{{"%leasingOrgName% is verifying your account. We’ll email you when your charging benefits are confirmed." |T: {leasingOrgName: companyName} }}</p>
<p>
  {{"Download the free ChargePoint app to find stations." |T}}
</p>
<div class="phone-container">
  <img class="phone-image" src="/assets/images/ttc_phones.png" alt="" />
</div>
<div class="pt-2 pb-4">
  <div class="mobile-app-buttons hidden-xs">
    <a (click)="clickDownload('apple')" class="mr-3" href="https://itunes.apple.com/app/apple-store/id356866743?pt=309768&amp;ct=Driver%20Mobile%20Web%20Page&amp;mt=8" target="_blank"><img src="/assets/images/i_app_ios_n.png"/></a>
    <a (click)="clickDownload('google')" href="https://play.google.com/store/apps/details?id=com.coulombtech&amp;referrer=utm_source%3DDriver%2520Mobile%26utm_medium%3DWeb%2520Page" target="_blank"><img src="/assets/images/i_app_android_n.png"/></a>
  </div>
</div>

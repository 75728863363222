import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FrocusTrapService {

  private lastFocusedElement: HTMLElement;

  setLastFocusedElement(element: HTMLElement): void {
    this.lastFocusedElement = element;
  }

  getLastFocusedElement(): HTMLElement {
    return this.lastFocusedElement;
  }

}

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AriaAnnouncementService } from '../services/aria-announcement.service';
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'cp-aria-announce',
  templateUrl: './aria-announce.component.html',
  styleUrls: ['./aria-announce.component.scss']
})
export class AriaAnnounceComponent implements OnInit {

  announcement$: Observable<string>;
  shouldAnnounce: boolean = false;
  constructor(private ariaAnnouncementService: AriaAnnouncementService, private cdRef: ChangeDetectorRef) { }
  ngOnInit(): void {
    this.announcement$ = this.ariaAnnouncementService.getAnnouncement().pipe(
      tap(() => {
        this.shouldAnnounce = true;
        this.cdRef.detectChanges();
        setTimeout(() => {
          this.shouldAnnounce = false;
          this.cdRef.detectChanges();
        }, 10000);
      })
    );
  }

  getAriaLive(): string {
    return this.shouldAnnounce ? 'assertive' : null;
  }
}

import { Component } from '@angular/core';
import { HomeChargerDoneComponent } from '../../home-charger-done/home-charger-done.component';

@Component({
  selector: 'cp-connection-home-charger-done',
  templateUrl: '../../home-charger-done/home-charger-done.component.html',
  styleUrls: ['../../home-charger-done/home-charger-done.component.scss']
})
export class ConnectionHomeChargerDoneComponent extends HomeChargerDoneComponent {
  ngOnInit() {
    super.ngOnInit();
    this.uiFlowState.setDriverConnectionFinished();
  }
}

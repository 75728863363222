import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CpInputComponent } from '../cp-input/cp-input.component';
import { LanguageService } from 'src/app/services/language.service';
import { AriaAnnouncementService } from 'src/app/services/aria-announcement.service';

@Component({
  selector: 'cp-user-password',
  templateUrl: './cp-user-password.component.html',
  styleUrls: ['./cp-user-password.component.scss']
})
export class CpUserPasswordComponent extends CpInputComponent implements OnInit, AfterViewInit  {
  constructor(languageService: LanguageService, private announcementService: AriaAnnouncementService) {
    super(languageService, announcementService);
  }
}

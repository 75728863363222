<div [formGroup]="notifications" *ngIf="notifications">
  <h1>{{"Notifications" | T }}</h1>
  <p>{{"How should we let you know when you are done charging?"|T}}</p>

  <div class="form-group  notification-delivery-item">
    <div class="checkbox-container">
      <div class="custom-control custom-checkbox ">
        <input type="checkbox" class="custom-control-input" id="yes-notify-sms" #notificationSMS
          formControlName="notificationSMSChkBox" (change)="checkedSMS($event)" />
        <label class="custom-control-label" for="yes-notify-sms">{{"Text" |T}}</label>
      </div>
    </div>
    <div class=" row sms-holder" [ngStyle]="{display: (notificationSMS.checked ? 'block': 'none')}">

      <cp-phone-w-country [parentForm]="notifications" controlnamePrefix="notifications" [pressedSubmit]="pressedSubmit"
        [addValidation]="isValidatePhone" #notificationPhone></cp-phone-w-country>

    </div>

  </div>

  <div class="form-group  notification-delivery-item">
    <div class="checkbox-container">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="yes-notify-email" #notificationEmail
          formControlName="notificationEmailChkBox" (change)="checkedEmail($event)" />
        <label class="custom-control-label" for="yes-notify-email">{{"Email"|T}}</label>
      </div>
    </div>
    <div class="email-input" *ngIf="notificationEmail.checked">
      <input type="text" 
        class="form-control" 
        data-private="lipsum" 
        formControlName="notificationEmail"
        [class.invalid]="updateError('notificationEmail')" 
        [attr.aria-label]="translatedEmailLabel"
        aria-describedby="email-error"
        />
      <div *ngIf="updateError('notificationEmail')" class="has-error">
        <div *ngIf="notifications.get('notificationEmail').errors.required" id="email-error">
          {{"errorMsgRequiredField" | T: {fieldName: translatedEmailLabel} }}
        </div>
        <div *ngIf="notifications.get('notificationEmail').errors.email" id="email-error">
          {{ "Please enter a valid email." | T}}
        </div>
      </div>
    </div>
  </div>

</div>
import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { AppConfig } from '../app-interfaces/interfaces';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { DiscoveryStoreService } from './discovery.service';
import { UserFromSession } from './interfaces/UserFromSession';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private userData = null;

  constructor(private discoveryService: DiscoveryStoreService, private sessionConfig: AppConfigService, private http: HttpClient) { }
  isLoggedIn() {
    return this.sessionConfig.getConfig().pipe(
      map((config: AppConfig) => {
        if (config.user) {
          return true;
        } else {
          return false;
        }
      })
    );
  }

  resetUserData() {
    this.userData = null;
  }

  getUser(isReload = false) {
    if (isReload) {
      this.userData = null;
    }
    if (this.userData !== null) {
      return of(this.userData);
    } else {
      return this.http.get<UserFromSession>(
        `${this.discoveryService.getAccountV1ApiEndpoint()}/driver/profile/account/userfromsession`,
        { withCredentials: true }
      ).pipe(
        catchError((error) => {
          this.userData = false;
          return of(false);
        }),
        map((user) => {
          this.userData = user;
          return user;
        })
      );
    }
  }
}

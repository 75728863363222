<div [formGroup]="parentForm" *ngIf="fieldsConfig">

  <div *ngIf="fieldsConfig.address1">
    <div>
      <cp-input [setParentForm]="parentForm" [setConfig]="fieldsConfig.address1" [pressedSubmit]="pressedSubmit" [addValidation]="needValidate" required="true"></cp-input>
      <cp-input [setParentForm]="parentForm" [setConfig]="fieldsConfig.address2" [pressedSubmit]="pressedSubmit" [addValidation]="needValidate" required="false"></cp-input>
    </div>

    <div class="row">
      <div class="col-lg">
        <cp-input [setParentForm]="parentForm" [setConfig]="fieldsConfig.city" [pressedSubmit]="pressedSubmit" [addValidation]="needValidate" required="true"></cp-input>
      </div>
      <div class="col-lg">
        <cp-input [setParentForm]="parentForm" [setConfig]="fieldsConfig.zipCode" [pressedSubmit]="pressedSubmit" [addValidation]="needValidate" required="true"></cp-input>
      </div>
    </div>

    <div class="row">
      <div class="col-lg">
        <cp-select [setParentForm]="parentForm" [setConfig]="fieldsConfig.stateId" [options]="states" [pressedSubmit]="pressedSubmit" [addValidation]="needValidate" required="true"></cp-select>
      </div>
      <div class="col-lg">
        <cp-select [setParentForm]="parentForm" [setConfig]="fieldsConfig.countryId" [options]="countries" (valueChange)="onCountryChange($event)" [pressedSubmit]="pressedSubmit" [addValidation]="needValidate" required="true"></cp-select>
      </div>
    </div>
  </div>
  <div *ngIf="!fieldsConfig.address1">
    <div class="row">
      <div class="col-lg">
        <cp-input [setParentForm]="parentForm" [setConfig]="fieldsConfig.zipCode" [pressedSubmit]="pressedSubmit" [addValidation]="needValidate" required="true"></cp-input>
      </div>
      <div class="col-lg">
        <cp-select [setParentForm]="parentForm" [setConfig]="fieldsConfig.countryId" [options]="countries" (valueChange)="onCountryChange($event)" [pressedSubmit]="pressedSubmit" [addValidation]="needValidate" required="true"></cp-select>
      </div>
    </div>
  </div>

</div>

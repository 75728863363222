import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'cp-android-banner',
  templateUrl: './android-banner.component.html',
  styleUrls: ['./android-banner.component.scss']
})
export class AndroidBannerComponent implements OnInit {
  @Output() bannerClose = new EventEmitter();
  constructor() { }

  ngOnInit() {}
}

<cp-android-banner *ngIf="showAndroidBanner" [ngClass]="{'slideUp': !showAndroidBanner}" ></cp-android-banner>
<cp-guest-header *ngIf="showGuestHeader"></cp-guest-header>
<cp-aria-announce></cp-aria-announce>
<div class="body-container" [ngClass]="langClassName">
  <cp-header *ngIf="!showGuestHeader"></cp-header>
  <cp-banner class="d-none d-sm-block" *ngIf="!showGuestHeader"></cp-banner>

    <h1 class="body-title" *ngIf="!showGuestHeader" [innerHTML]="title"></h1>
    <div *ngIf='serverErrorMessage' class="text-danger error_msg" role="alert" aria-live="assertive">{{serverErrorMessage}}</div>
    <router-outlet id="body-router-outlet"></router-outlet>
    <cp-loader *ngIf="loadingIndicatorServiceService.isLoading$ | async"></cp-loader> 

</div>
<cp-footer></cp-footer>

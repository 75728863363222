// MDN Docs: Testing for Availability (slightly modified from example)
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#testing_for_availability
export const localStorageAvailable = (): boolean => {
    try {
      const x = '__storage_test__';
      window.localStorage.setItem(x, x);
      window.localStorage.removeItem(x);
      return true;
      // eslint-disable-next-line
    } catch (err: any) {
      const e = err as DOMException;
      const everythingExceptFirefox = e.code === 22;
      const firefox = e.code === 1014;
      const quotaExceededError = e.name === 'QuotaExceededError';
      const quotaReachedError = e.name === 'NS_ERROR_DOM_QUOTA_REACHED';
      return (
        (everythingExceptFirefox || firefox || quotaExceededError || quotaReachedError) &&
        window.localStorage &&
        window.localStorage.length !== 0
      );
    }
  };

  export const defaultLang = 'en-US';

  export const findLocaleFromLocalStorage = (): string => {
    if (localStorageAvailable()) {
      return window.localStorage.getItem('user_locale');
    }
  };

  export const setUserLocale = (locale: string): void => {
    if (localStorageAvailable()) {
        window.localStorage.setItem('user_locale', locale);
    }
  };


import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LangPipe } from '../pipes/lang.pipe';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class  AriaAnnouncementService {

  private announcementSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private queuedAnnouncement: string = '';
  langPipe: LangPipe;

  constructor(private languageService: LanguageService) {
    this.langPipe = new LangPipe(languageService);
  }

  getAnnouncement(): Observable<string> {
    return this.announcementSubject.asObservable();
  }

  /**
   * @param content lang key to be translated and set as the announcement
   */
  setAnnouncement(content: string): void {
    const translatedContent = this.langPipe.transform(content);
    this.announcementSubject.next(translatedContent);
  }
  anounceQueuedAnnouncement(): void {
    this.announcementSubject.next(this.queuedAnnouncement);
    this.queuedAnnouncement = '';
  }

  queueAnnouncement(content: string): void {
    const translatedContent = this.langPipe.transform(content);
    this.queuedAnnouncement = translatedContent;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from '../../services/app-config.service';
import { AppConfig } from '../../app-interfaces/interfaces';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { Country, CountryState } from '../../services/models/address-with-zip';
import { Observable } from 'rxjs/index';
import { LanguageService } from 'src/app/services/language.service';
import { DiscoveryStoreService } from 'src/app/services/discovery.service';

export interface PhoneCountry {
  id: number;
  name: string;
  originalName: string;
  code: string;
  callingCode: number;
  phoneFormat: string;
  phoneVerificationSupported: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  memLoc: any;

  constructor(private http: HttpClient, private appConfig: AppConfigService, private langService: LanguageService, private discoveryService: DiscoveryStoreService) {
    this.memLoc = {
      allCountries: [],
      countries: [],
      states: {}
    };
  }

  getAllSupportedCountriesAccrossNoslets(): Observable<Array<Country>> {
    return this.appConfig.getConfig().pipe(
      mergeMap((config: AppConfig) => {
        const httpOptions = this.getHttpOptions();
        return this.http.post<any>(
          config.discoveryURL + 'discovery/v4/globalconfig', {}, httpOptions).pipe(
            map(discoveryData => {
              const countries = discoveryData.supportedCountries;
              return countries;
            }),
            catchError(err => {
              return [];
            })
          );
      })
    );
  }
  getObservableCountry(returnAll = false): Observable<Array<Country>> {
    const httpOptions = this.getHttpOptions();

    if (returnAll) {
      return this.http.get<any>(
        `${this.discoveryService.getAccountV1ApiEndpoint()}/metadata/countries?onlySupported=false`,
        httpOptions
      ).pipe(
        catchError((error) => {
          return of([]);
        })
      );
    } else {
      return this.http.get<any>(
        `${this.discoveryService.getAccountV1ApiEndpoint()}/metadata/countries`,
        httpOptions
      ).pipe(
        catchError((error) => {
          return of([]);
        })
      );
    }
  }

  getObservableState(countryID): Observable<Array<CountryState>> {
    const httpOptions = this.getHttpOptions();

    return this.http.get<any>(
      `${this.discoveryService.getAccountV1ApiEndpoint()}/metadata/countries/${countryID}/states`,
      httpOptions
    ).pipe(
      catchError((error) => {
        return of([]);
      })
    );
  }

  getCountries(): Promise<Array<Country>> {
    return new Promise(resolve => {
      this.getObservableCountry().subscribe(data => {
        resolve(data);
      });
    });
  }

  getStates(countryID): Promise<Array<CountryState>> {
    return new Promise(resolve => {
      if (this.memLoc.states[countryID]) {
        resolve(this.memLoc.states[countryID]);
      } else {
        this.getObservableState(countryID).subscribe(data => {
          this.memLoc.states[countryID] = data;
          resolve(data);
        });
      }
    });
  }

  getAllCountries(): Promise<Array<Country>> {
    return new Promise(resolve => {
      if (this.memLoc.allCountries.length > 0) {
        resolve(this.memLoc.allCountries);
      } else {
        this.getObservableCountry(true).subscribe(data => {
          this.memLoc.allCountries = data;
          resolve(data);
        });
      }
    });
  }

  getCountriesByParam(onlyPhoneVerificationSupported: boolean, onlySupported: boolean): Observable<Array<PhoneCountry>> {
    const httpOptions = this.getHttpOptions();
    // tslint:disable-next-line:max-line-length
    return this.http.get<Array<PhoneCountry>>(
      `${this.discoveryService.getAccountV1ApiEndpoint()}/metadata/countries?onlySupported=${onlySupported}&onlyPhoneVerificationSupported=${onlyPhoneVerificationSupported}`, httpOptions)
      .pipe(
        catchError(err => {
          return [];
        })
      );
  }

  getHttpOptions() {
    return {
      withCredentials: true,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.langService.getLocale(),
      })
    };
  }
}

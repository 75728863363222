<div class="modal modal-open fade" *ngIf="isModalOpen" [class.show]="isModalOpen" id="localePickerModal" tabindex="-1" role="dialog" 
     aria-labelledby="localePicker" aria-modal="true" [class.modal-block]="isModalOpen" data-close="true" (click)="modalToggle($event)" cpFocustrap>
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="localePicker"> {{ "Select Language" | T | htmlEntity}}</h5>
          <button type="button" class="btn-close" data-dismiss="modal" [attr.aria-label]="'Close' | T" data-close="true">
          </button>
        </div>
        <div class="modal-body">
          <div *ngFor="let region of localeConfig.regions; trackBy: trackByFn">
              <h6 id="region_{{region.regionCode}}">{{region.regionName}}</h6>
              <div class="locale-list" role="group" [attr.aria-labelledby]="'region_' + region.regionCode">
                <div *ngFor="let locale of region.locales">
                  <div *ngIf="isSupportedLocale(locale.localeCode)">
                    <button type="link" class="btn btn-link locale-button" (click)="changeLanguage(locale.localeCode)" lang="{{locale.localeCode}}">{{locale.localizedLanguageName}}</button>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isModalOpen" class="modal-backdrop fade show"></div>
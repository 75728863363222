<div [formGroup]="cpCardsGroup" *ngIf="cpCardsGroup">
  <div class="row">
    <div class="col-md-6">
      <h3>{{"How do you want to charge?"|T}}</h3>
      <p *ngIf="showTapToCharge">{{"With the ChargePoint app, you can tap your phone on a station to start charging. You can also get a ChargePoint card if you’d prefer."|T}}</p>
      <div>
        <div *ngIf="showTapToCharge" class="custom-control custom-radio">
          <input type="radio" id="use-phone-later" class="custom-control-input" name="cp-card-options" formControlName="cp-card-options"
            value="usePhoneLater" (change)="changeCardOptions($event)"/>
          <label class="custom-control-label" for="use-phone-later">{{"I’ll use my phone"|T}} <a href="https://www.chargepoint.com/support/driver-faq/#q4" target="_blank">{{"Learn More" |T }}</a></label>
        </div>
        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" id="send-me-card" name="cp-card-options" formControlName="cp-card-options"
            value="send-me-card" #freeCPCard (change)="changeCardOptions($event)"/>
          <label class="custom-control-label" for="send-me-card">{{"Send me a free ChargePoint Card"|T}}</label>
        </div>
        <div class="custom-control custom-radio">
          <input 
            type="radio" 
            id="I-have-card" 
            class="custom-control-input" 
            name="cp-card-options" 
            formControlName="cp-card-options"
            value="i-have-card" 
            #iHaveCard 
            (change)="changeCardOptions($event)"
            [attr.aria-label]="'useMyCard' | T"
            aria-describedby="use-my-chargepoint-card-description"
            />
          <label class="custom-control-label" for="I-have-card">{{"I have a ChargePoint Card"|T}}</label>
          <div id="use-my-chargepoint-card-description" class="">
            {{ "If you already have a ChargePoint card, you can use it to start charging. You can also get a free ChargePoint card if you’d prefer." | T }}
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="img-responsive driver-card-signup-image">
      </div>
    </div>
  </div>
  <div class="account-section">
    <p *ngIf="freeCPCard.checked">
     {{" Your free ChargePoint card will be sent to %address1% within 7-10 business days. In the meantime, you can always start charging from the ChargePoint mobile app."|T : {address1:address1} }}
    </p>
    <div class="custom-control custom-checkbox" *ngIf="freeCPCard.checked">
      <input 
        type="checkbox" 
        class="custom-control-input" 
        id="send-card-to-diff-addy" 
        formControlName="send-card-to-diff-addy"
        #diffAddy 
        (change)="checkAddressValidation(!diffAddy.checked)"
        [attr.aria-label]="'getNewCard' | T"
        />
      <label class="custom-control-label" for="send-card-to-diff-addy">{{"Send my free ChargePoint card to a different address"|T}}</label>
      <cp-address-form [setParentForm]="cpCardsGroup" [controlnamePrefix]="addressPrefix" *ngIf="diffAddy.checked" [needValidate]="validateAddress" [pressedSubmit]="pressedSubmit"></cp-address-form>
    </div>
    <div class="add-cp-cards-container" formArrayName="cards" *ngIf="iHaveCard.checked">
      <div *ngFor="let card of cpCards.controls; let i=index" [formGroupName]="i">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <input type="text" class="form-control nickname" data-private="lipsum" formControlName="nickname" placeholder="{{'Card Nickname'|T}}" [attr.aria-label]="'Card Nickname' | T" [class.invalid]="updateError(i, 'nickname')" required="true" />
            </div>
            <div *ngIf="updateError(i, 'nickname')" class="has-error">
              {{"This field is required" | T}}
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group minus-container">
              <input type="text" class="form-control serialnumber" data-private="lipsum" formControlName="serialNumber" placeholder="{{'Card Serial Number'|T}}" [attr.aria-label]="'Card Serial Number' | T" [class.invalid]="updateError(i, 'serialNumber')" required="true"/>
              <i *ngIf="i != 0" class="material-icons add-remove-icon minus" (click)="removeCard(i)">remove_circle</i>
            </div>
            <div *ngIf="updateError(i, 'serialNumber')" class="has-error">
              <div *ngIf="cpCards.get(i.toString()).get('serialNumber').errors.required">
                {{"This field is required" | T}}
              </div>
              <div *ngIf="cpCards.get(i.toString()).get('serialNumber').errors.pattern">
                {{ "Invalid serial number." | T}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <i class="material-icons add-remove-icon plus" (click)="addCard($event)">add_circle</i> <a href="" (click)="addCard($event)"
            class="add-cp-card-link">{{"Add another ChargePoint card"|T}}</a>
        </div>
        <div class="col-md-6">
          <div class="serial-card-info-image">
            <p>{{"Located on back of ChargePoint card"|T}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

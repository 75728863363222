import {
  Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, OnDestroy, AfterViewInit
} from '@angular/core';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { FieldConfig } from '../../services/models/default-config';
import { LangPipe } from 'src/app/pipes/lang.pipe';
import { LanguageService } from 'src/app/services/language.service';
import { AriaAnnouncementService } from 'src/app/services/aria-announcement.service';

@Component({
  selector: 'cp-input',
  templateUrl: './cp-input.component.html',
  styleUrls: ['./cp-input.component.scss']
})
export class CpInputComponent implements OnInit, OnDestroy, AfterViewInit {
  private static idCounter = 0;
  inputId: string;
  langpipe: LangPipe;
  fieldConfig: FieldConfig;
  @Input() pressedSubmit = false;
  @Input() needValidate = false;
  @Input() setFocus = false;
  @Input() onlyNumbers = false;
  @Input() ariaLabelOveride: string; // optional aria-label overide; othewise, will use the translated placeholder.
  @Input() required: boolean;
  @ViewChild('textInput') textInput: ElementRef<HTMLInputElement>;

  @Output() enterKeyPressed = new EventEmitter();

  control: AbstractControl;
  parentForm: FormGroup;
  validatorsIsSet = false;
  translatedPlaceholder: any;

  @Input() set setParentForm(frmGrp: FormGroup) {
    this.parentForm = frmGrp;
  }

  @Input() set setConfig(config) {
    if (config) {
      this.fieldConfig = config;
      if (!this.parentForm.get(this.fieldConfig.name)) {
        this.parentForm.addControl(
          this.fieldConfig.name,
          new FormControl('', {updateOn: 'change'})
        );
      }

      this.control = this.parentForm.get(this.fieldConfig.name);
      if (typeof config.value !== 'undefined') {
        this.setValue(config.value);
      }
    }
  }

  @Input() set addValidation(isValidate: boolean) {
    if (this.needValidate !== isValidate) {
      this.needValidate = isValidate;
      if (this.needValidate) {
        this.setValidators();
      } else {
        this.cleanValidators();
      }
    }
  }

  constructor(private langSvc: LanguageService,  private ariaAnnouncementService: AriaAnnouncementService) {
    CpInputComponent.idCounter++;
    this.inputId = `cp-input-${CpInputComponent.idCounter}`;
    this.langpipe = new LangPipe(this.langSvc);

   }

  ngOnInit() {
    this.translatedPlaceholder = this.langpipe.transform(this.fieldConfig.placeholder);
   }

  ngAfterViewInit() {
    if (this.setFocus) {
      this.focus();
      setTimeout(() => {
        this.ariaAnnouncementService.anounceQueuedAnnouncement();
      }, 500);
    }
  }

  ngOnDestroy () {
    if (this.control) {
      this.control.reset();
    }
    this.cleanValidators();
  }

  setValue(value) {
    if (this.control) {
      this.control.setValue(value);
    }
  }

  getValue() {
    if (this.control) {
      return this.control.value;
    }
    return null;
  }

  setValidators() {
    if (this.control && !this.validatorsIsSet) {
      if (this.fieldConfig.validations.length > 0 || this.fieldConfig.asyncValidations.length > 0) {
        this.control.setValidators(this.fieldConfig.validations);
        this.control.setAsyncValidators(this.fieldConfig.asyncValidations);
        this.control.updateValueAndValidity();
      }
      this.validatorsIsSet = true;
    }
  }

  cleanValidators() {
    if (this.control && this.validatorsIsSet) {
      this.control.clearValidators();
      this.control.updateValueAndValidity();
      this.validatorsIsSet = false;
    }
  }

  updateError() {
    return (((this.control.touched && !this.control.pristine) || this.pressedSubmit) && this.control.invalid);
  }

  focus() {
    if (this.textInput) {
      this.textInput.nativeElement.focus();
    }
  }

  checkChange(event) {
    if (this.onlyNumbers) {
      event = (event) ? event : window.event;
      const charCode = (event.which) ? event.which : event.keyCode;

      // Delete = 46, Backspace = 8, Tab = 9, Space = 32, Home = 36, End = 35
      if (charCode !== 8 && event.which !== 0 && (charCode < 48 || charCode > 57)) {
        return false;
      }
    }

    return true;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { map, catchError, debounceTime, distinctUntilChanged, switchMap, take, retry } from 'rxjs/operators';
import { Country } from 'src/app/services/models/address-with-zip';
import { ValidationService } from 'src/app/services/validation.service';
import { AddressService } from '../address/address.service';

@Component({
  selector: 'cp-phone-w-country-simple',
  templateUrl: './phone-w-country-simple.component.html',
  styleUrls: ['./phone-w-country-simple.component.scss']
})
export class PhoneWCountrySimpleComponent implements OnInit {
  @Output() formSubmit = new EventEmitter<FormGroup>();
  @Output() errorLoadingCountries = new EventEmitter<boolean>();
  @Input() phoneNumber: string = '';
  @Input() countryId: string = '';
  @Input() onlyPhoneSupportedCountries = false;
  @Input() onlyNosletSupportedCountries = false;

  selectedCountry: Country;
  showCountriesDropdown = false;
  countries: Array<Country>;
  phoneWithCountryForm: FormGroup;

  constructor(private addressSvc: AddressService, private validationService: ValidationService) {}

  ngOnInit(): void {
    this.phoneWithCountryForm = new FormGroup({
      phoneNumber: new FormControl('', [Validators.required], [this.validatePhoneNumber()]),
      countryId: new FormControl(''),
    });

    this.addressSvc.getCountriesByParam(this.onlyPhoneSupportedCountries, this.onlyNosletSupportedCountries).pipe(
      retry(3),
      catchError(err => {
        console.error('error', err);
        this.errorLoadingCountries.emit(true);
        return of([]);
      })
    ).subscribe(data => {
      this.countries = data;
      this.selectedCountry = this.countries.find(country => country.id.toString() === this.countryId);
      this.phoneWithCountryForm.controls.phoneNumber.markAllAsTouched();
      this.phoneWithCountryForm.controls.countryId.setValue(this.countryId);
      this.phoneWithCountryForm.controls.phoneNumber.setValue(this.phoneNumber);
      this.phoneWithCountryForm.updateValueAndValidity({ emitEvent: true });
    });

  }

  selectCountry(country: Country) {
    this.phoneWithCountryForm.get('countryId').setValue(country.id.toString());
    this.selectedCountry = country;
  }
  validatePhoneNumber(): AsyncValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value || !this.selectedCountry) {
        return of(null);
      } else {
        return control.valueChanges.pipe(
          debounceTime(700),
          distinctUntilChanged(),
          take(1),
          switchMap((phoneValue) => {
            return this.validationService.isPhoneValid(phoneValue, this.selectedCountry.id).pipe(
              map(resp => {
                if (resp.error) {
                  return { phoneIsBadFormat: true };
                }
                return null;
              }),
              catchError(() => {
                return of({ phoneIsBadFormat: true });
              })
            );
          }
          ));
      }
    };
  }

  submitForm() {
    if (this.phoneWithCountryForm.valid) {
      this.formSubmit.emit(this.phoneWithCountryForm);
    }
  }
}



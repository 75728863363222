import { Component, OnInit } from '@angular/core';
import { GetHomeChargerService } from '../get-home-charger/get-home-charger.service';

@Component({
  selector: 'cp-mobile-home-charger-done',
  templateUrl: './mobile-home-charger-done.component.html',
  styleUrls: ['./mobile-home-charger-done.component.scss']
})
export class MobileHomeChargerDoneComponent implements OnInit {
  companyName = '';
  constructor(
    private homeChargerSvc: GetHomeChargerService
  ) { }

  ngOnInit() {
    this.homeChargerSvc.getHomeChargerStatus().subscribe(statusObj => {
      if (statusObj.companyName) {
        this.companyName = statusObj.companyName;
      }
    });
  }
}

import { Component, HostListener, OnInit } from '@angular/core';
import { CommunicationService } from '../services/communication.service';
import { FrontendContentConfigService } from '../services/frontend-content-config.service';
import { LanguageService } from '../services/language.service';
import { LangPipe } from '../pipes/lang.pipe';
import { VIEW_ACCOUNT } from './translate';
import { UiFlowStateService } from '../services/ui-flow-state.service';

@Component({
  selector: 'cp-den-partner-success',
  templateUrl: './den-partner-success.component.html',
  styleUrls: ['./den-partner-success.component.scss']
})
export class DenPartnerSuccessComponent implements OnInit {
  redirectUrl = '';
  langPipe: LangPipe;
  nextButton = '';
  @HostListener('window:beforeunload', ['$event'])
  closePage($event: any) {
    this.uiFlowState.clearSessionStorage();
  }

  constructor(
    private communicationSvc: CommunicationService,
    private frontendConfigSvc: FrontendContentConfigService,
    private langSvc: LanguageService,
    private uiFlowState: UiFlowStateService
  ) {
    this.langPipe = new LangPipe(langSvc);
  }

  ngOnInit() {
    this.uiFlowState.setSignUpFinished();
    this.frontendConfigSvc.setBodyTitle('successTitle');
    this.nextButton = this.langPipe.transform(VIEW_ACCOUNT);
    this.communicationSvc.getComponentDefinition('daimlersuccess').subscribe((componentDefinition) => {
      if (componentDefinition && componentDefinition.fields) {
        componentDefinition.fields.forEach(field => {
          if (field.id === 'cpRedirectUrl') {
            if (field.value) {
              this.redirectUrl = field.value;
            }
          }
        });
      }
    });
  }

  navigateDriverPortal() {
    window['location'].href = this.redirectUrl ;
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NotificationMethod, UserInfo } from 'src/app/services/models/backend-save-classes';
import { CommunicationService } from 'src/app/services/communication.service';
import { FrontendContentConfigService } from 'src/app/services/frontend-content-config.service';
import { CPMixPanel } from 'src/app/services/mixpanel.service';

@Component({
  selector: 'cp-notification-delivery-v2',
  templateUrl: './notification-delivery-v2.component.html',
  styleUrls: ['./notification-delivery-v2.component.scss']
})
export class NotificationDeliveryV2Component implements OnInit {
  notificationFormGroup: FormGroup ;
  pressedSubmit = false;
  constructor(private commsvc: CommunicationService, private frontEndConfig: FrontendContentConfigService, private mixpanel: CPMixPanel) { }

  ngOnInit() {
    this.frontEndConfig.setBodyTitle('notificationsTitle');
    this.notificationFormGroup = new FormGroup({});
  }
  submitForm() {
    this.pressedSubmit = true;
    if (this.notificationFormGroup.invalid) {
      return;
    }
    const formvalue =  this.notificationFormGroup.value;
    formvalue.profile_countryId = formvalue.selectedCountryObj.id;
    const notificationObj = {
      notificationMethod: new NotificationMethod(formvalue),
      userInfo: new UserInfo(formvalue)
    };

    this.commsvc.submitFormFields(formvalue, notificationObj);
    this.mixpanel.notificataionsSet(notificationObj.notificationMethod);
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserPaymentComponent } from './user-payment/user-payment.component';
import { GetHomeChargerComponent } from './get-home-charger/get-home-charger.component';
import { HomeChargerDoneComponent } from './home-charger-done/home-charger-done.component';
import { GetHomeChargerSorryComponent } from './get-home-charger-sorry/get-home-charger-sorry.component';
import { HomeChargerAddressComponent } from './home-charger-address/home-charger-address.component';
import { ConnectionComponent } from './connection/connection.component';
import { WebviewBodyComponent } from './webview-body/webview-body.component';
import { BodyComponent } from './body/body.component';
import { MobileHomeChargerDoneComponent } from './mobile-home-charger-done/mobile-home-charger-done.component';
import { LoginComponent } from './login/login/login.component';
import { DenPartnerSuccessComponent } from './den-partner-success/den-partner-success.component';
import { SplashComponent } from './splash/splash.component';
import { ChargepointCardsV2Component } from './account/chargepoint-cards-v2/chargepoint-cards-v2.component';
import { VerifyAccountComponent } from './account/verify-account/verify-account.component';
import { ElectricVehicleV2Component } from './account/electric-vehicle-v2/electric-vehicle-v2.component';
import { NotificationDeliveryV2Component } from './account/notification-delivery-v2/notification-delivery-v2.component';
import { GenericPartnerSuccessComponent } from './generic-partner-success/generic-partner-success.component';
import { ConnectionBodyComponent } from './driver-connection/body.component';
import { ConnectionHomeChargerComponent } from './driver-connection/home-charger/get-home-charger.component';
import { ConnectionHomeChargerAddressComponent } from './driver-connection/home-charger/home-charger-address.component';
import { ConnectionHomeChargerDoneComponent } from './driver-connection/home-charger/home-charger-done.component';
import { DriverConnectionFuelCardComponent } from './driver-connection-fuelcard/driver-connection-fuelcard.component';
import { VersionComponent } from './version/version.component';
import { CpCommonSuccessRefactorComponent } from './cp-common-success-refactor/cp-common-success-refactor.component';
import { AriaAnnouncementGuard } from './guards/aria-announcement.guard';

export const routes: Routes = [
  { path: 'version', component: VersionComponent },
  { path: '', redirectTo: 'signup/profile', pathMatch: 'full' },
  { path: 'register', redirectTo: 'signup/profile', pathMatch: 'full' },
  {
    path: 'signup', component: BodyComponent, children: [
      { path: 'splash', component: SplashComponent, data: { title: 'titleSignup' } },
      { path: 'card', component: ChargepointCardsV2Component, data: { title: 'titleCharge' } },
      { path: 'charge', component: ChargepointCardsV2Component, data: { title: 'titleCharge' } },
      { path: 'verifyaccount',
        component: VerifyAccountComponent,
        canActivate: [AriaAnnouncementGuard],
        data: {
        title: 'titleVerifyAccount',
        ariaAnnouncementKey: 'ariaAnnouncementVerfiyAccount'
        }
      },
      { path: 'vehicle', component: ElectricVehicleV2Component,
        canActivate: [AriaAnnouncementGuard],
        data: {
          title: 'titleVehicle',
          ariaAnnouncementKey: 'Tell us what you drive. We’ll estimate range and cost of charging.'
        }
      },
      { path: 'notification', component: NotificationDeliveryV2Component,
        canActivate: [AriaAnnouncementGuard],
        data: {
          title: 'titleNotification',
          ariaAnnouncementKey: 'How should we let you know when you are done charging?'
        }
      },
      { path: 'profile', component: UserProfileComponent,
        canActivate: [AriaAnnouncementGuard],
        data: {
        title: 'titleSignup',
        ariaAnnouncementKey: 'ariaAnnouncementProfile'
        }
      },
      { path: 'payment',
        component: UserPaymentComponent,
        canActivate: [AriaAnnouncementGuard],
        data:
          {
            title: 'titlePayment',
            ariaAnnouncementKey: 'ariaAnnouncementPayment'
          }
      },
      { path: 'paypal-success', component: UserPaymentComponent, data: { title: 'titlePaypalSuccess' } },
      { path: 'paypal-failure', component: UserPaymentComponent, data: { title: 'titlePaypalFailure' } },
      { path: 'homeCharger', component: GetHomeChargerComponent, data: { title: 'titleHomeCharger' } },
      { path: 'get-home-charger-sorry', component: GetHomeChargerSorryComponent, data: { title: 'titleHomeCharger' } },
      { path: 'home-charger-address', component: HomeChargerAddressComponent, data: { title: 'titleHomeCharger' } },
      { path: 'leasecoSuccess', component: HomeChargerDoneComponent, data: { title: 'titleSuccessfullyRegistered' } },
      { path: 'driverConnectionFuelCard', component: DriverConnectionFuelCardComponent, data: { title: 'titleDriverConnection' } },
      { path: 'driverConnection', component: ConnectionComponent, data: { title: 'titleDriverConnection' } },
      { path: 'login', component: LoginComponent, data: { guestHeader: true, title: 'titleLogin' } },
      { path: 'daimlersuccess', component: DenPartnerSuccessComponent, data: { title: 'titleSuccessfullyRegistered' } }, // Daimler specific
      { path: 'densignupsuccess',
        component: GenericPartnerSuccessComponent,
        data: { title: 'titleSuccessfullyRegistered' }
      }, // General Den Partners
      { path: 'thankyou',
        component: CpCommonSuccessRefactorComponent,
        canActivate: [AriaAnnouncementGuard],
        data: {
          title: 'titleSuccessfullyRegistered',
          ariaAnnouncementKey: 'ariaAllDoneAnnouncement',
          ariaTriggerImmediately: true
        }
      }
    ]
  },
  { path: 'dc-lang-change', redirectTo: 'driver-connection', pathMatch: 'full' },
  {
    path: 'driver-connection', component: ConnectionBodyComponent, children: [
      { path: 'driverConnection', component: ConnectionComponent, data: { title: 'titleDriverConnection' } },
      { path: 'driverConnectionFuelCard', component: DriverConnectionFuelCardComponent, data: { title: 'titleDriverConnection' } },
      { path: 'homeCharger', component: ConnectionHomeChargerComponent, data: { title: 'titleHomeCharger' } },
      { path: 'get-home-charger-sorry', component: GetHomeChargerSorryComponent, data: { title: 'titleHomeCharger' } },
      { path: 'home-charger-address', component: ConnectionHomeChargerAddressComponent, data: { title: 'titleHomeCharger' } },
      { path: 'leasecoSuccess', component: ConnectionHomeChargerDoneComponent, data: { title: 'titleSuccessfullyRegistered' } },
    ]
  },
  {
    path: 'webview', component: WebviewBodyComponent, children: [
      { path: 'get-home-charger', component: GetHomeChargerComponent, data: { title: 'homeCharger' } },
      { path: 'get-home-charger-sorry', component: GetHomeChargerSorryComponent, data: { title: 'homeCharger' } },
      { path: 'home-charger-done', component: MobileHomeChargerDoneComponent, data: { title: 'successfullyRegistered' } },
      { path: 'home-charger-address', component: HomeChargerAddressComponent, data: { title: 'homeCharger' } }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

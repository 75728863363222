import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { LanguageService } from './language.service';
import { DiscoveryStoreService } from './discovery.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private discoveryService: DiscoveryStoreService, private langSvc: LanguageService) { }

  getAccountApiEndPoint() {
    return this.discoveryService.getAccountV1ApiEndpoint();
  }

  getAccountApiHeaders() {
        return {
          withCredentials: true,
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept-Language': this.langSvc.getLocale(),
          })
        };  
  }
}

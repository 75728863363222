<div class="modal modal-open fade" 
  [class.show]="isModalOpen"
  id="termsModal" 
  tabindex="-1" 
  role="dialog" 
  aria-labelledby="termsModalLabel" 
  aria-modal="true" 
  [class.modal-block]="isModalOpen" 
  data-close="true" 
  (click)="modalToggle($event)"
  cpFocustrap
  >
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="termsModalLabel"> {{ "Terms & Conditions" | T | htmlEntity}}</h5>
        <button type="button" class="btn-close" data-dismiss="modal" [attr.aria-label]="'Close' | T" data-close="true">
        </button>
      </div>
      <div class="modal-body" [innerHTML]="content"></div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" data-close="true">{{"Close" | T }}</button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isModalOpen" class="modal-backdrop fade show"></div>
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

/**
 * Service to manage the loading status across the application.
 *
 * This service utilizes a BehaviorSubject to emit the current loading status to all subscribers
 * whenever a request starts or finishes. It maintains an internal counter of active requests to
 * determine whether the application is currently processing any asynchronous operations, which
 * allows multiple requests to be tracked simultaneously without prematurely hiding the loading indicator.
 *
 * @example
 * // In a component making HTTP requests:
 * this.loadingIndicatorService.loadingRequestStarted('componentName');
 * // ... HTTP request logic
 * this.loadingIndicatorService.loadingRequestFinished();
 *
 * @example
 * // In a component's template:
 * <div *ngIf="loadingIndicatorService.isLoading$ | async">Loading...</div>
 *
 * The service can be injected into components to start and stop the loading indicator,
 * typically during HTTP requests or other asynchronous operations. The `isLoading$` public observable
 * can be subscribed to or used with the async pipe in Angular templates to reactively display a
 * loading spinner or other indicators of background activity.
 *
 * The `callerName` parameter in `loadingRequestStarted` method is a string that represents the name of the
 * caller component or service and can be used for debugging purposes to track which parts of the application
 * are initiating loading requests.
 */
@Injectable({
  providedIn: 'root'
})
export class LoadingIndcatorServiceService {
  private requestCount = 0;
  public isLoading$ = new BehaviorSubject<boolean>(false);
  constructor() { }

  public loadingRequestStarted(callerName: string) {
    if (!environment.production) {
      console.log(`%cloadingRequestStarted: %c ${callerName}`, 'color: #ccc', 'font-weight: bold');
    }
    this.requestCount++;
    this.emitLoadingStatus();
  }

  private emitLoadingStatus() {
    this.isLoading$.next(this.requestCount > 0);
  }

  public loadingRequestFinished() {
    this.requestCount--;
    this.emitLoadingStatus();
  }
}

import EnvironmentInterface, {Environment} from './environment-types';

export const environment: EnvironmentInterface = {
  production: true,
  DISCOVERY_API: 'https://discovery.chargepoint.com/discovery/v3/globalconfig',
  DRIVER_PORTAL_URL: 'https://driver.chargepoint.com',
  MIXPANEL_PROJECT_TOKENS: {
    'NA-CA': 'd86e39c8a3585426601be59214915a52',
    'NA-US': 'a6645c8c93ebd1957d28333b6c95eda3',
    'ANZ': '676425b1a1418b90107e09b548734e8c',
    'EU': '0325dd9f2906be99d4877bd1087f9d46',
  },
  envType: Environment.Production,
};

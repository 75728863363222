import { Injectable } from '@angular/core';
import { mergeMap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AccountService } from 'src/app/services/account.service';

export interface VerifySMS {
  code: string;
  countryId: number;
  number: number;
}

@Injectable({
  providedIn: 'root'
})

export class VerifyAccountService {

  constructor(private http: HttpClient, private accountSvc: AccountService) {}

  sendverifycode(payload: VerifySMS) {
    return this.http.post<any>(
      this.accountSvc.getAccountApiEndPoint() + '/driver/profile/user/sendverifycode',
      payload,
      this.accountSvc.getAccountApiHeaders()
    );
  }
}

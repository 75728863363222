import { Directive, ElementRef, Renderer2, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { FrocusTrapService } from '../services/frocus-trap.service';


@Directive({
  selector: '[cpFocustrap]'
})
export class FocusTrapDirective implements AfterViewInit, OnDestroy {
  private firstFocusableElement: HTMLElement;
  private lastFocusableElement: HTMLElement;

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private focusTrapService: FrocusTrapService) {

   }

   @HostListener('keydown', ['$event'])
   onKeydown(event: KeyboardEvent): void {
     if (event.key === 'Tab') {
       if (event.shiftKey && document.activeElement === this.firstFocusableElement) {
         event.preventDefault();
         this.lastFocusableElement.focus();
       } else if (!event.shiftKey && document.activeElement === this.lastFocusableElement) {
         event.preventDefault();
         this.firstFocusableElement.focus();
       }
     }
   }

  ngAfterViewInit(): void {
    const focusableElements = this.getFocusableElements();
    this.firstFocusableElement = focusableElements[0];
    this.lastFocusableElement = focusableElements[focusableElements.length - 1];

    // Set focus to the first focusable element
    if (this.firstFocusableElement) {
      this.firstFocusableElement.focus();
    }
  }

  ngOnDestroy(): void {
    // Restore focus to the element that triggered the modal
    if (this.focusTrapService.getLastFocusedElement()) {
      this.focusTrapService.getLastFocusedElement().focus();
    }
  }

  private getFocusableElements(): HTMLElement[] {
    const focusableElements = this.elementRef.nativeElement.querySelectorAll(
      'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
    );
    return Array.from(focusableElements) as HTMLElement[];
  }
}

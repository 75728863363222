import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { FrontendContentConfigService } from 'src/app/services/frontend-content-config.service';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { ChargepointCardsComponent } from '../chargepoint-cards/chargepoint-cards.component';
import { CommunicationService } from 'src/app/services/communication.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ValidationService } from 'src/app/services/validation.service';
import { LanguageService } from 'src/app/services/language.service';
import { LangPipe } from 'src/app/pipes/lang.pipe';
import { Charge } from 'src/app/services/models/backend-save-classes';
import { CountryConfig } from '../../app-interfaces/interfaces';


@Component({
  selector: 'cp-chargepoint-cards-v2',
  templateUrl: './chargepoint-cards-v2.component.html',
  styleUrls: ['./chargepoint-cards-v2.component.scss']
})
export class ChargepointCardsV2Component extends ChargepointCardsComponent implements OnInit {
  smsForApp = new FormControl(true);
  useCPCardChckBx = new FormControl(false);
  cardOptions = new FormControl();
  formgroup: FormGroup;
  layoutConfig: any;
  supportHelpContent: any;
  langpipe: LangPipe;
  isLoading = false;
  showBackButton = true;
  addAnotherCardTxt = '';
  private communcationSvc: CommunicationService;

  constructor(
    private router: Router,
    private frontendSvc: FrontendContentConfigService,
    fb: FormBuilder,
    ref: ChangeDetectorRef,
    comsvc: CommunicationService,
    appConfigSvc: AppConfigService,
    validationService: ValidationService,
    private activatedRoute: ActivatedRoute,
    private lngSvc: LanguageService
  ) {
    super(fb, ref, comsvc, appConfigSvc, validationService, frontendSvc);
    this.communcationSvc = comsvc;
    this.langpipe = new LangPipe(lngSvc);
    this.cardFormGroup = new FormGroup({});
  }

  ngOnInit() {
    this.updateTemplateTextFromConfig();
    this.layoutConfig = this.frontendSvc.getLayoutConfig('cards');
    this.formgroup = new FormGroup({});

    this.activatedRoute.url.subscribe((activatedRouteUrl) => {
      if (activatedRouteUrl[0].path.search('card|charge') !== -1  ) {
        this.frontendSvc.setBodyTitle('cardTitle');
      }
    });

    this.communcationSvc.getComponentDefinition('charge').subscribe(componentDefinition => {
      if (componentDefinition) {
        this.showBackButton = componentDefinition.showBackButton;
      }
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd ) {
        if (event.url.search('/signup/card') !== -1) {
          this.frontendSvc.setBodyTitle('cardTitle');
        }
      }
    });

    const masterObj = this.communcationSvc.getMasterObject();
    let countryCode = null;

    if (masterObj.profile_countryCode) {
      countryCode = masterObj.profile_countryCode;
    } else if (masterObj.selectedCountryObj && masterObj.selectedCountryObj.code) {
      countryCode = masterObj.selectedCountryObj.code;
    }

    if (masterObj['cp-card-options']) {
      this.useCPCardChckBx.patchValue(masterObj['use-card']);
      this.cardOptions.patchValue(masterObj['cp-card-options']);
      this.changeCardOptions(masterObj['cp-card-options']);
      this.useCPCardChckBx.updateValueAndValidity();
    } else if (countryCode) {
      this.appConfigSvc.getCountryConfig(countryCode).subscribe((country: CountryConfig) => {
        if (country.signupPromptsRFID) {
          const useCard = masterObj['use-card']? masterObj['use-card'] : true;
          this.useCPCardChckBx.patchValue(useCard);
          this.useCPCardChckBx.updateValueAndValidity();
          this.changeCardOptions('use-card');
        }
      });
    }
  }
  // label: "Don't have a card?",
  // content: 'If you need a card call Mercedes-Benz 24/7 %phoneCallLetters% (%phoneNumber%) or contact your local dealer.',
  // phoneCallLetters: '1-800-FOR-MERCEDES',
  // phoneNumber: '1-800-367-6372'
  updateTemplateTextFromConfig() {
    this.addAnotherCardTxt = this.frontendSvc.getTextContent('addAnotherCardTxt');
    this.addAnotherCardTxt = this.langpipe.transform(this.addAnotherCardTxt);
    this.supportHelpContent = this.frontendSvc.getTextContent('dontHaveACardContent');
    if (this.supportHelpContent) {
      this.supportHelpContent.label = this.langpipe.transform(this.supportHelpContent.label);
      if (this.supportHelpContent.content.hasOwnProperty('40')) {
        this.supportHelpContent.supportTranslated = this.langpipe.transform(this.supportHelpContent.content['40'].text, {phoneCallLetters: this.supportHelpContent.content['40'].phoneCallLetters, phoneNumber: this.supportHelpContent.content['40'].phoneNumber} );
      } else  {
        this.supportHelpContent.supportTranslated = this.langpipe.transform(this.supportHelpContent.content['default'].text, {phoneCallLetters: this.supportHelpContent.content['default'].phoneCallLetters, phoneNumber: this.supportHelpContent.content['default'].phoneNumber} );
      }
    }
  }

  canShowCPCardRadios() {
    if (this.useCPCardChckBx.value) {
      return true;
    }

    return false;
  }

  submitForm() {
    this.pressedSubmit = true;
    this.isLoading = true;

    if (this.cpCardsGroup.valid) {
      const formValue = this.cpCardsGroup.value;
      formValue['cp-card-options'] = this.cardOptions.value;
      const backendSaveObj = new Charge(formValue);
      this.communcationSvc.submitFormFields(formValue, backendSaveObj);
    } else {
      if (this.cpCardsGroup.status === 'PENDING') {
        setTimeout(() => {
          this.submitForm();
        }, 500);
      } else {
        this.isLoading = false
      }
    }
  }

  changeCardOptions(value) {
    debugger;
    if (value !== 'i-have-card' && value !== 'send-me-card') {
      this.validateAddress = false;
      this.cleanValidation();
      this.cardOptions.reset();

      if (this.canShowCPCardRadios()) {
        this.changeCardOptions('send-me-card');

      setTimeout(() => {
        const targetElement = document.getElementById("order-card");
          if(targetElement) {
            this.cardOptions.setValue('send-me-card');
            targetElement.focus();
          }  
      }, 500);
      }
    } else {
      if (value === 'i-have-card') {
        this.setCardsValidation();
      } else {
        this.cleanValidation();
      }

      if (value === 'send-me-card') {
        this.validateAddress = true;
      } else {
        this.validateAddress = false;
      }
    }
  }

  shouldShowOnlySerialLayout() {
    if (this.layoutConfig && this.layoutConfig.showNumberIsOnBack) {
      return false;
    }

    return true;
  }

}

import { Injectable } from '@angular/core';
import { Observable, distinctUntilChanged } from 'rxjs';
import { of } from 'rxjs';
import { LanguageService } from './language.service';
import { Vehicle } from 'src/app/services/models/backend-save-classes';
import { NotificationMethod } from 'src/app/services/models/backend-save-classes';
import mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';
import { DiscoveryStoreService } from './discovery.service';
import * as Sentry from '@sentry/browser';


declare let require: any;

const SIGN_UP_LEASECO = 'Sign-up-button';
const SIGN_UP_ACCOUNT_CONTINUE = 'Account-Page-continue';
const SIGN_UP_PAYMENT_PAYPAL = 'PayPal-continue';
const SIGN_UP_PAYMENT_CARD_CONTINUE = 'Credit-card-continue';
const SIGN_UP_PAYMENT_SKIP = 'payment-skip';
const SIGN_UP_SUCCESS_APPLE = 'success-apple-store';
const SIGN_UP_SUCCESS_GOOGLE = 'success-google-store';
const SIGN_UP_DONT_NEED_HC = 'I-dont-need-homecharger';
const SIGN_UP_CONNECTION_CONTINUE = 'Driver-connection-page-continue';
const SIGN_UP_HC_CONTINUE = 'home-charger-continue';
const SIGN_UP_HC_UNAVAILABLE = 'home-charger-unavailable-continue';
const SIGN_UP_HC_ADDRESS_CONTINUE = 'home-charger-address-continue';
const SIGN_UP_LEASECO_SUCCESS = 'leaseCo-success';
const SIGN_UP_GET_CHARGER_SUBMIT = 'get-charger-submitted';
const SIGN_UP_CHANGE_REQUEST_SUBMIT = 'change-request-submitted';
const SIGN_UP_DAIMLER_SUCCESS = 'daimler-success';

const SEND_PHONE_VERIFICATION_CODE = 'Send Phone Validation Code';
const VALIDATE_PHONE_VALIDATION_CODE = 'Validate Phone Validation Code';
const RESEND_PHONE_VALIDATION_CODE = 'Resend Phone Validation Code';
const TAP_NEXT_BUTTON = 'Tap Create Account Button';
const ACCOUNT_CREATED = 'Account Created';
const ACCOUNT_INFORMATION = 'Account Information';
const ADD_EV = 'Add EV';
const EV_INFORMATION = 'EV Information';
const SKIP_EV = 'Skip EV';
const NOTIFICATIONS = 'Notifications';
const SKIP_NOTIFICATIONS = 'Skip Notifications';
const SIGNUP_START = 'User Flow Start - Signup';
const SIGNUP_END = 'User Flow End - Signup';

const CARD_3DS_INITIALIZATION = '3DS - Initialization';
const CARD_3DS_CHECK_VERSION = '3DS - Check Version';
const CARD_3DS_AUTHENTICATE = '3DS - Authenticate';
const CARD_3DS_CHALLANGE = '3DS - Challenge';

@Injectable()
export class CPMixPanel {
  mixpanel: Observable<any>;

  constructor(
    private languageSvc: LanguageService,
    private discoveryService: DiscoveryStoreService
  ) {
    this.initMixPanel();
    
  }


  private mixpanelTokenAliasToRegionMap = new Map([
    ['qa.us.mixpanel', 'NA-US'],
    ['prod.us.mixpanel', 'NA-US'],
    ['qa.eu.mixpanel', 'EU'],
    ['qa.ca.mixpanel', 'NA-CA'],
    ['prod.eu.mixpanel', 'EU'],
    ['prod.au.mixpanel', 'ANZ'],
    ['prod.ca.mixpanel', 'NA-CA']
  ]);

  initMixPanel() {
    
    this.discoveryService.mixpanelTokeAlias$.pipe(
      distinctUntilChanged(),
    ).subscribe((tokenAlias) => {  
      let mixpanelToken = null
      if (tokenAlias) {
        const region = this.mixpanelTokenAliasToRegionMap.get(tokenAlias);
        if (!region) {
          Sentry.captureException(new Error('Mixpanel region not found'));
          console.error('Mixpanel region not found');
          return;
        }
        mixpanelToken = environment.MIXPANEL_PROJECT_TOKENS[region];
      }
      if (!mixpanelToken) {
        Sentry.captureException(new Error('Mixpanel token not found'));
        console.error('Mixpanel token not found');
        return;
      }
      
      mixpanel.init(mixpanelToken);
      mixpanel.register({
        'User Flow': 'Sign Up',
        'User Type': 'Driver',
        'Chargepoint Product': 'Driver Portal',
        'Analytics Reporting Version': 2,
      });
      this.mixpanel = of(mixpanel)
    });
  }

  identifyUser(userId: string) {
    this.mixpanel.subscribe(mixPanelObj => {
      mixPanelObj.identify(userId);
    });
  }

  trackSuperProperties(data: {}) {
    this.mixpanel.subscribe(mixPanelObj => {
      mixPanelObj.register(data);
    });
  }

  trackEvent(event: string, data: {}) {
    this.mixpanel.subscribe(mixPanelObj => {
      mixPanelObj.track(event, data);
    });
  }

  signUpLeaseco (data: Object) {
    this.trackEvent(SIGN_UP_LEASECO, data);
  }

  profileContinue() {
    this.trackEvent(TAP_NEXT_BUTTON, {});
  }

  accountContinue() {
    this.trackEvent(SIGN_UP_ACCOUNT_CONTINUE, {});
  }

  connectionContinue() {
    this.trackEvent(SIGN_UP_CONNECTION_CONTINUE, {});
  }

  homeChargerContinue(data: Object) {
    this.trackEvent(SIGN_UP_HC_CONTINUE, data);
  }

  homeChargerUnavailable() {
    this.trackEvent(SIGN_UP_HC_UNAVAILABLE, {});
  }

  homeChargerAddressContinue() {
    this.trackEvent(SIGN_UP_HC_ADDRESS_CONTINUE, {});
  }

  paymentPayPalContinue() {
    this.trackEvent(SIGN_UP_PAYMENT_PAYPAL, {});
  }

  paymentCardContinue() {
    this.trackEvent(SIGN_UP_PAYMENT_CARD_CONTINUE, {});
  }

  paymentSkip() {
    this.trackEvent(SIGN_UP_PAYMENT_SKIP, {});
  }

  successAppleApp() {
    this.trackEvent(SIGN_UP_SUCCESS_APPLE, {});
  }

  successGoogleApp() {
    this.trackEvent(SIGN_UP_SUCCESS_GOOGLE, {});
  }

  dontNeedHC() {
    this.trackEvent(SIGN_UP_DONT_NEED_HC, {});
  }

  successLeaseco() {
    this.trackEvent(SIGN_UP_LEASECO_SUCCESS, {});
  }

  successDaimler() {
    this.trackEvent(SIGN_UP_DAIMLER_SUCCESS, {});
  }

  submitGetCharger() {
    this.trackEvent(SIGN_UP_GET_CHARGER_SUBMIT, {});
  }

  submitChangeRequest() {
    this.trackEvent(SIGN_UP_CHANGE_REQUEST_SUBMIT, {});
  }

  sendPhoneVerificationCode(data: any) {
    this.trackEvent(SEND_PHONE_VERIFICATION_CODE, data);
  }

  validatePhoneValidationCode() {
    this.trackEvent(VALIDATE_PHONE_VALIDATION_CODE, {});
  }
  resendPhoneValidationCode() {
    this.trackEvent(RESEND_PHONE_VALIDATION_CODE, {});
  }

  accountCreated() {
    this.trackEvent(ACCOUNT_CREATED, {});
  }

  signupStart() {
    this.trackEvent(SIGNUP_START, {});
  }

  signupEnd() {
    this.trackEvent(SIGNUP_END, {});
  }

  card3dsInitialization(data: any) {
    this.trackEvent(CARD_3DS_INITIALIZATION, data);
  }

  card3dsCheckVersion(data: any) {
    this.trackEvent(CARD_3DS_CHECK_VERSION, data);
  }

  card3dsAuthenticate(data: any) {
    this.trackEvent(CARD_3DS_AUTHENTICATE, data);
  }

  card3dsChallenge(data: any) {
    this.trackEvent(CARD_3DS_CHALLANGE, data);
  }

  accountInformation(country: string) {
    this.trackEvent(ACCOUNT_INFORMATION, {
      'Country': country,
      'Locale': this.languageSvc.getLocale()
    });
  }

  addEV() {
    this.trackEvent(ADD_EV, {});
  }

  evInformation(ev: Vehicle) {
    this.trackEvent(EV_INFORMATION, {
      'Color': ev.colorId,
      'Make': ev.makeId,
      'Model': ev.modelId,
      'Year': ev.year
    });
  }

  skipEV() {
    this.trackEvent(SKIP_EV, {});
  }

  notificataionsSet(notification: NotificationMethod) {
    if (!notification.email && !notification.push && !notification.text) {
      this.trackEvent(SKIP_NOTIFICATIONS, {});
    } else {
      this.trackEvent(NOTIFICATIONS, notification);
    }
  }
}

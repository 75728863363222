import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {routeSlide} from '../animations/animations';
import {UiFlowStateService} from '../services/ui-flow-state.service';
import {CommunicationService} from '../services/communication.service';
import {DriverSignupUXService} from '../services/driver-signup-ux.service';
import {catchError, distinctUntilChanged, filter, finalize, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {LangPipe} from '../pipes/lang.pipe';
import {LanguageService} from '../services/language.service';
import {FrontendContentConfigService} from '../services/frontend-content-config.service';
import {serverError} from './translate';
import {BrandService} from '../services/brand.service';
import {ThemeService} from '../services/theme.service';
import {LoadingIndcatorServiceService} from '../services/loading-indcator-service.service';
import {environment} from 'src/environments/environment';
import {GENERAL_SERVER_ERROR} from '../services/translation-files/translate';
import * as Sentry from '@sentry/browser';


@Component({
  selector: 'cp-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.scss'],
  animations: [routeSlide]
})
export class BodyComponent implements OnInit  {
  showGuestHeader = false;
  protected l10n: LangPipe;
  title = '';
  loading = false;
  serverErrorMessage: string;
  showAndroidBanner = false;
  langClassName: string;
  public loadingIndicatorServiceService: LoadingIndcatorServiceService;

  constructor(
    protected stateSvc: UiFlowStateService,
    protected comSvc: CommunicationService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected langSvc: LanguageService,
    protected uxConfigService: DriverSignupUXService,
    protected frontendContentSvc: FrontendContentConfigService,
    private brandService: BrandService,
    private themeService: ThemeService,
    private loadingIndcatorServiceService: LoadingIndcatorServiceService
  ) {
    this.loadingIndicatorServiceService = loadingIndcatorServiceService;
  }

  ngOnInit() {
    this.themeService.themeChanged.subscribe(() => {
      this.showAndroidBanner = this.isAndroidBannerVisible();
    });
    this.updateThemeBasedOnSubdomain();
    this.l10n = new LangPipe(this.langSvc);
    this.langSvc.localeEmmiter.subscribe((locale: string) => {
      if (!locale) {
        return;
      }
      this.langClassName = 'lang-' + locale.toLocaleLowerCase();
    });
    this.frontendContentSvc.$bodyTitle.subscribe((bodyTitle: string) => {
        if (bodyTitle) {
            setTimeout(() => {
              this.title = this.l10n.transform(bodyTitle);
            });
        }
    });
    this.stateSvc.$currentRoute.pipe(
      distinctUntilChanged((prev, curr) => {
        return prev?.route === curr?.route;
      })
    ).subscribe(routeObj => {

      if (!routeObj) {
        return;
      }
      if (!environment.production) {
        console.log(`%c New route:%c ${routeObj.route}`, 'background: #222', 'color: #bada55' );
      }

      const {route, queryParams} = routeObj;
      /*
      route can come from local storage, local storage value can be only string
      so undefined type converted to string 'undefined'
      */
      if (typeof route === 'string' && route.length > 0 && route !== 'undefined') {
        this.router.navigate([route], { relativeTo: this.activatedRoute, queryParams});
      }
    });

    this.stateSvc.$serverErrorMessage.subscribe(error => {
      this.serverErrorMessage = error;
    });

    this.activatedRoute.children.forEach(child => {
      child.data.subscribe(data => {
        if (data.guestHeader) {
          this.showGuestHeader = data.guestHeader;
        } else  {
          this.showGuestHeader = false;
        }
      });
    });

    this.router.events
      .pipe(
        filter(e => {
          return e instanceof NavigationEnd;
        }),
        map(() => {
          let route = this.activatedRoute.firstChild;
          let child = route;

          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
              route = child;
            } else {
              child = null;
            }
          }

          return route;
        }),
        mergeMap(route => {
          if (route && route.data) {
            return route.data;
          } else {
            return of({});
          }
        })
      )
      .subscribe(routeData => {
        this.showGuestHeader = routeData.guestHeader;
      });

    this.loadConfiguration();
  }

  updateThemeBasedOnSubdomain(): void {
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.has('subdomain')) {
      const subdomain = urlSearchParams.get('subdomain');
      this.brandService.getBrandFromApi(subdomain).subscribe((response) => {
        if (response?.brand) {
          if (this.themeService.hasTheme(response.brand)) {
            this.themeService.updateTheme(response.brand);
          }
        }
      });
    }
  }

  loadConfiguration() {
    let token = '';
    if (this.comSvc.getMasterObject().partnerToken) {
      token = this.comSvc.getMasterObject().partnerToken;
    }
    const signupGetParams = this.comSvc.getMasterObject().urlSearchParams;
    this.loadingIndicatorServiceService.loadingRequestStarted('config loading');
    this.uxConfigService.getDriverSignupConfiguration(token, signupGetParams).pipe(
      catchError(() => {
        return of(false);
      }),
      finalize(() => {
        this.loadingIndicatorServiceService.loadingRequestFinished();
      })
    ).subscribe(data => {
      if (data.components && data.components.length > 0) {
        this.stateSvc.setUXConfig(data);
      } else if (data.errorId) {
        Sentry.captureException(new Error(`${data.errorCategory}:${data.errorId} TOKEN:${token}`));
        this.serverErrorMessage = this.l10n.transform(GENERAL_SERVER_ERROR) + ` (${data.errorCategory}: ${data.errorId})`;
      } else {
        this.serverErrorMessage = this.l10n.transform(serverError);
      }
    });
  }

  isAndroidBannerVisible() {
    const isAndroid = navigator.userAgent.includes('Android');
    const isDaimler = this.frontendContentSvc.cobrand === 'daimler';
    const hasPartnerToken = location.search.includes('partnerToken');
    return isAndroid && !isDaimler && !hasPartnerToken;
  }
}

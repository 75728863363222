import { Injectable } from '@angular/core';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { AddressWithZip } from './models/address-with-zip';
import { UserPaymentService } from '../user-payment/user-payment.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { catchError, finalize } from 'rxjs/operators';
import { Payment } from './models/backend-save-classes';
import { CPMixPanel } from './mixpanel.service';
import { DriverSignupUXService } from './driver-signup-ux.service';
import { SessionService } from './session.service';
import { GENERAL_SERVER_ERROR } from './translation-files/translate';
import { LanguageService } from './language.service';
import {CatastrophicError} from './interfaces/OrgConfig';
import { DiscoveryStoreService } from './discovery.service';
import { ThemeService } from './theme.service';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/browser';
import { LoadingIndcatorServiceService } from './loading-indcator-service.service';

@Injectable({
  providedIn: 'root'
})
export class UiFlowStateService {

  // Observables that emit the current state of the UI flow for consumers. They are public as they need to be subscribed to by components.
  public $currentRoute: BehaviorSubject<{route: string, queryParams: Record<string, string>}> = new BehaviorSubject(null);
  public $serverErrorMessage: Subject<string> = new Subject();
  public $catastrophicError: BehaviorSubject<CatastrophicError> = new BehaviorSubject(null);

  // Private properties used internally within the service
  // This is an array of strings that contain the ids of the routes they are in order of appearance in the config;
  private routes: string[] = [];
  // This is a string representation of the componentId that comes from Account Config service.
  // It is used to route to the correct component or keep track of the current component
  private currentRouteComponentId: string | null = null;
  private saveAllEndpoint: string | null = null; // This comes from Discovery API
  private isRouteFromMemory = false; // Flag to indicate whether to route from sessionStorage or from config

  // State management properties used to maintain and update the state based on user interaction or system events.
  private masterObj: any = {};
  private savedBackendData: any = { deviceData: { type: 'web' } };
  private config: any; // Again, consider replacing 'any' with a specific interface
  public $configObs: BehaviorSubject<any> = new BehaviorSubject(null);

  // Standard properties
  private mainAddress: AddressWithZip;
  // Error message to be used internally within the service, not intended for external use,
  // but is emitted by the $serverErrorMessage observable
  private currentErrorMessage = '';
  public locale = '';

  constructor(
    private router: Router,
    private userPaymentService: UserPaymentService,
    private appConfig: AppConfigService,
    private httpClient: HttpClient,
    private mixPanel: CPMixPanel,
    private configService: DriverSignupUXService,
    private session: SessionService,
    private langService: LanguageService,
    private discoveryService: DiscoveryStoreService,
    private themeService: ThemeService,
    private loadingIndicatorService: LoadingIndcatorServiceService
  ) {
    // Setting the masterObj and component id from session to memory
    const masterFormObj = window['sessionStorage'].getItem('masterFormObj');
    const componentId = window['sessionStorage'].getItem('componentId');
    const schemaObj = window['sessionStorage'].getItem('schemaObj');

    this.savedBackendData = schemaObj ? JSON.parse(schemaObj) : {};
    this.masterObj = masterFormObj ? JSON.parse(masterFormObj) : {};

    this.checkLoggedInUser();

    this.themeService.themeChanged.subscribe((theme) => {
      this.mixPanel.trackSuperProperties({
        themeTemplateName: theme
      });
    });

    if (!this.masterObj.urlSearchParams) {
      this.masterObj.urlSearchParams = {};
    }

    this.currentRouteComponentId = componentId ? componentId : null;
    if (componentId) {
      this.isRouteFromMemory = true;
    }
    // extracting the partner Token

    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.has('partnerToken')) {
      this.masterObj.partnerToken = decodeURIComponent(urlSearchParams.get('partnerToken'));
    }

    if (urlSearchParams.has('code')) {
      this.masterObj.partnerToken = decodeURIComponent(urlSearchParams.get('code'));
    }

    if (urlSearchParams.has('locale')) {
      this.locale = decodeURIComponent(urlSearchParams.get('locale'));
    }

    if (!urlSearchParams.has('brand')) {
      delete this.masterObj.urlSearchParams?.brand;
    } else {
      Sentry.setTag('brand', urlSearchParams.get('brand'));
      mixPanel.trackSuperProperties({
        brand: urlSearchParams.get('brand')
      });
    }

    Array.from(urlSearchParams).forEach(( [key, val] ) => {
      if (key === 'brand' && themeService.getTheme() === 'default') {
        return;
      }

      if (key !== 'token') {
        this.masterObj.urlSearchParams[key] = val;
      }
    });

    if (window.location.href.includes('partnerToken') || window.location.href.includes('code')) {
      this.mixPanel.signUpLeaseco({ type: 'LeaseCo' });
    } else {
      if (!masterFormObj) {
        this.mixPanel.signUpLeaseco({ type: 'New' });
      }
    }
  }

  public setUXConfig(uxConfig: any, resetRoutes = false) {
    if (uxConfig.orgConfig && uxConfig.orgConfig.shouldRedirect) {
      window.location.href = uxConfig.orgConfig.redirectUrl;

      return;
    }
    if (resetRoutes) {
      this.routes = [];
      this.currentRouteComponentId = null;
    }
    this.config = uxConfig;
    if (uxConfig.error) {
      this.$catastrophicError.next(uxConfig.error);
    } else {
      this.$catastrophicError.next(null);
    }
    // this sets the initial state of the UI flow
    this.processConfig(this.config);
    // this puts the config object in an observable so it can be subscribed to by components
    // for additional information. This is not used by the UI flow service itself.
    this.$configObs.next(this.config);
    this.initRouterEvents();
    // paypal success
    if (this.currentRouteComponentId && window.location.href.includes('/signup/paypal-success')) {
      this.processPayPal();
    } else {
      // set the router
      this.triggerRouteChange(this.currentRouteComponentId);
    }
  }

  private initRouterEvents() {
    this.router.events.subscribe(routeEvent => {
      // setting up the componentID
      if (routeEvent instanceof NavigationEnd) {
        // this is required for proper navigation. TODO: rethink this to something more elegant
        this.updateErrorMessage();
        const urlArr = new URL(`${window.location.origin}${routeEvent.url}`).pathname.split('/');
        const urlPathSegment = urlArr[urlArr.length - 1];
        const routeIndex = this.routes.indexOf(urlPathSegment);
        if (this.isAccountCreated()) {
          const submitIndex = this.routes.indexOf('submit');
          // block pre creation views for now
          if (routeIndex < submitIndex && routeIndex !== -1) {
            this.triggerRouteChange(this.currentRouteComponentId);
          } else if (routeIndex !== -1) {
            this.currentRouteComponentId = urlPathSegment;
          }
        } else if (routeIndex !== -1) {
          this.currentRouteComponentId = urlPathSegment;
        }
      }
    });
  }

  /**
   * function returns true if the user is on the payment page, came from captch refresh, and the next route is the create account.
   * @returns boolean
   */
  private checkForRefreshFromCaptcha(): boolean {
    const indexOfSubmit = this.routes.indexOf('submit');
    const indexOfPayment = this.routes.indexOf('payment');
    const isSubmitNextRoute = this.routes[indexOfPayment + 1] === this.routes[indexOfSubmit];
    return sessionStorage.getItem('callNextRoute') && isSubmitNextRoute;

  }

  public saveToSessionStorage(backendData?) {
    window.sessionStorage.setItem('masterFormObj', JSON.stringify(this.masterObj));
    if (backendData) {
      window.sessionStorage.setItem('schemaObj', JSON.stringify(this.savedBackendData));
    }
  }

  public getConfig() {
    return this.config;
  }

  public getRoutes(): Observable<string[]> {
    return of(this.config);
  }

  // TODO refactor this to protect the masterObj and to have a clearly defined state.
  public getMasterObject() {
    return this.masterObj;
  }

  // Saving form fields to masterObj (memory)
  public updateSignUpData(data) {
    this.masterObj = { ...this.masterObj, ...data };
  }

  public saveBackendData(backendData) {
    if (!this.savedBackendData[this.currentRouteComponentId]) {
      this.savedBackendData[this.currentRouteComponentId] = {};
    }
    this.savedBackendData[this.currentRouteComponentId] = backendData;
  }

  /**
   * Advances to the next route in the application's signup process.
   * This function is responsible for handling the transition between the current and next steps in the signup flow.
   * It performs several checks and actions sequentially:
   * - Calls updateErrorMessage to reset any previous error messages.
   * - Checks if the current route is 'profile' to set the signup process flag.
   * - Starts a new loading sequence with the LoadingIndicatorService.
   * - Attempts to fetch the current user's session information.
   * - If a user session exists, it proceeds to submit any data required for the current route, handling errors,
   *   setting the account creation flag, and managing the loading indicator's state throughout the process.
   * - In the absence of a user session, it checks if the account was previously created and directly proceeds to the next step.
   * - if the next route is the 'submit' route, it submits all data, handles potential errors, and manages the session accordingly.
   * - If the user's session is successfully submitted, it triggers Mixpanel analytics calls
   * and logs in the user if credentials are present.
   * - Finally, it updates the sessionStorage with the new componentId and triggers the route change.
   *
   * Side effects include:
   * - Updating the loading state to the subscribers of $isLoading.
   * - Updating sessionStorage with the componentId of the next route.
   * - Potentially logging in the user and updating the user session with new data.
   *
   * Note: This function contains nested subscriptions and should be refactored to avoid complex chains of observables
   * for better maintainability. Too messy and hard to follow!
   */
  public nextRoute() {
    this.updateErrorMessage();
    const currentIndex = this.routes.indexOf(this.currentRouteComponentId);
    if (this.currentRouteComponentId === 'profile') {
      this.setSignUpStarted();
    }
    this.loadingIndicatorService.loadingRequestStarted('get user');
    this.session.getUser().pipe(
      finalize(() => this.loadingIndicatorService.loadingRequestFinished())
    ).subscribe((user) => {
      if (user) {
        // edit mode
        this.setAccountCreated(); // setting the flag to account for browser refresh
        this.loadingIndicatorService.loadingRequestStarted('submit all data');
        this.submitAllData(this.currentRouteComponentId).pipe(
          catchError(err => {
            if (err && err.error && err.error.errorMessage) {
              this.updateErrorMessage(err.error.errorMessage);
            } else {
              this.updateErrorMessage(GENERAL_SERVER_ERROR);
            }
            return of(false);
          }),
          finalize(() => this.loadingIndicatorService.loadingRequestFinished())
        ).subscribe((resp) => {
          if (!resp) {
            return;
          }
          this.triggerRouteChange(this.routes[currentIndex + 1]);
          window.sessionStorage.setItem('componentId', this.routes[currentIndex + 1]);
        });
      } else if (this.isAccountCreated()) {
        // account created, but no session.
        this.triggerRouteChange(this.routes[currentIndex + 1]);
        window.sessionStorage.setItem('componentId', this.routes[currentIndex + 1]);
      } else {
        if (this.routes[currentIndex + 1] === 'submit') {
          // this is the last route, submit
          // also delete the session storage
          this.loadingIndicatorService.loadingRequestStarted('submit all data and create account');
          this.submitAllData().pipe(
            finalize(() => this.loadingIndicatorService.loadingRequestFinished()),
            catchError(err => {
              if (err?.status === 403 || err?.status === 0) {
                sessionStorage.setItem('callNextRoute', 'true');
               }
              if (err && err.error && err.error.errorMessage) {
                this.updateErrorMessage(err.error.errorMessage);
              } else {
                this.updateErrorMessage(GENERAL_SERVER_ERROR);
              }
              return of(false);
            })
          ).subscribe((resp) => {
            if (!resp) {
              return;
            }
            this.setAccountCreated();
            if (this.savedBackendData.session && this.savedBackendData.session.signupType) {
              switch (this.savedBackendData.session.signupType) {
                case 'LEASECO':
                  this.mixPanel.successLeaseco();
                  break;
                case 'DAIMLER':
                  this.mixPanel.successDaimler();
                  break;
                default:
                  this.mixPanel.accountCreated();
                  break;
              }
              if (this.masterObj.selectedCountryObj) {
                this.mixPanel.accountInformation(this.masterObj.selectedCountryObj.name);
              }
            }

            if (this.masterObj.installHomeCharger) {
              this.mixPanel.submitGetCharger();
            }

            if (this.masterObj.username && this.masterObj.password) {
              this.loadingIndicatorService.loadingRequestStarted('log in');
              this.logUserIn(this.masterObj.username, this.masterObj.password).pipe(
                finalize(() => this.loadingIndicatorService.loadingRequestFinished()),
                catchError(() => {
                  return of(false);
                })
              ).subscribe((response: any) => {
                if (!response?.userid || !response?.sessionObj) {
                  return;
                }
                this.mixPanel.identifyUser(response.userid);
                this.mixPanel.trackSuperProperties({
                  locale: response.sessionObj.locale,
                  companyID: response.sessionObj.company_id,
                  isLogged: true,
                  subDomainTemplate: response.sessionObj.subDomainTemplate,
                });
                this.session.resetUserData();
                this.triggerRouteChange(this.routes[currentIndex + 2]);
              });
            } else {
              this.triggerRouteChange(this.routes[currentIndex + 2]);
            }
            window.sessionStorage.setItem('componentId', this.routes[currentIndex + 2]);
          });
        } else {
          this.triggerRouteChange(this.routes[currentIndex + 1]);
          window.sessionStorage.setItem('componentId', this.routes[currentIndex + 1]);
        }
      }
    });
  }

  public prevRoute() {
    let currentIndex = this.routes.indexOf(this.currentRouteComponentId);

    if (this.router.url.indexOf('get-home-charger-sorry') !== -1
      || this.router.url.indexOf('home-charger-address') !== -1) {
      currentIndex++;
    } else if (this.routes[currentIndex - 1] === 'submit') {
      currentIndex--;
    }

    if (typeof this.routes[currentIndex - 1] !== 'undefined') {
      this.triggerRouteChange(this.routes[currentIndex - 1]);
      window.sessionStorage.setItem('componentId', this.routes[currentIndex - 1]);
    }
  }

  public navigateToLast() {
    this.clearSessionStorage();
    this.triggerRouteChange(this.routes[this.routes.length - 1]);
  }

  public getMainAddress() {
    if (!this.mainAddress && this.savedBackendData) {
      if (this.savedBackendData['profile'] && this.savedBackendData['profile']['address']) {
        this.mainAddress = this.savedBackendData['profile']['address'];
      }
    }

    return this.mainAddress;
  }

  public setMainAddress(address) {
    this.mainAddress = address;
  }

  public submitAllData(component = null) {
    const saveData: any = this.configService.getSaveData(this.savedBackendData, component);
    saveData.session = { ...this.savedBackendData.session, ...this.masterObj.urlSearchParams };
    if (!saveData.session.subDomain) {
      saveData.session.subDomain = location.hostname.split('.')[0];
    }

    if (Object.keys(saveData).length > 0) {
      return this.httpClient.post(
        `${this.discoveryService.getBaseAccountApiEndpoint()}${this.saveAllEndpoint}`,
        saveData,
        {
          withCredentials: true,
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept-Language': this.langService.getLocale()
          })
        }
      );
    }

    return of({});
  }

  private logUserIn(username, password) {
    const data = new HttpParams({
      fromObject: {
        user_name: username,
        user_password: password
      }
    });

    return this.httpClient.post(
      `${this.discoveryService.getNOSApiEndpoint()}index.php/users/validate`,
      data,
      {
        withCredentials: true,
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      }
    );
  }

  public clearSessionStorage() {
    window.sessionStorage.removeItem('masterFormObj');
    window.sessionStorage.removeItem('componentId');
    window.sessionStorage.removeItem('accountCreated');
    window.sessionStorage.removeItem('schemaObj');
    window.sessionStorage.removeItem('signUpConfiguration');
    window.sessionStorage.removeItem('driverConnectionStarted');
  }

  private setAccountCreated() {
    if (!this.isAccountCreated() && !this.isDriverConnectionStarted()) {
      window.sessionStorage.setItem('accountCreated', 'true');
    }
  }

  private isAccountCreated() {
    return window.sessionStorage.getItem('accountCreated') === 'true';
  }

  private setSignUpStarted() {
    if (!this.isDriverConnectionStarted()) {
      if (!this.isSignUpStarted() || this.isSignUpFinished()) {
        window.sessionStorage.setItem('signUpStarted', 'true');
        window.sessionStorage.removeItem('signUpFinished');
        this.mixPanel.signupStart();
      }
    }
  }

  private isSignUpStarted() {
    return window.sessionStorage.getItem('signUpStarted') === 'true';
  }

  public setSignUpFinished() {
    if (!this.isSignUpFinished()) {
      window.sessionStorage.setItem('signUpFinished', 'true');
      this.mixPanel.signupEnd();
    }
  }

  private isSignUpFinished() {
    return window.sessionStorage.getItem('signUpFinished') === 'true';
  }

  private isDriverConnectionStarted() {
    return window.sessionStorage.getItem('driverConnectionStarted') === 'true';
  }

  public setDriverConnectionStarted() {
    if (!this.isDriverConnectionStarted()) {
      window.sessionStorage.setItem('driverConnectionStarted', 'true');
    }
  }

  public setDriverConnectionFinished() {
    if (!this.isDriverConnectionStarted()) {
      window.sessionStorage.removeItem('driverConnectionStarted');
    }
    window.sessionStorage.setItem('driverConnectionFinished', 'true');
  }

  private checkLoggedInUser() {
    const DRIVER_CONNECTION_PATH = '/driver-connection';
    const DRIVER_PORTAL_URL = environment.DRIVER_PORTAL_URL;

    this.appConfig.getConfig().subscribe(config => {
      // DMS Connection check
      if (document.location.pathname.search(DRIVER_CONNECTION_PATH) !== -1 || document.location.pathname.search('/webview') !== -1) {
        if (!config.user) {
          window.location.href = environment.DRIVER_PORTAL_URL;
          return;
        }
        this.langService.updateLanguage(config.locale);
        return;
      }

      if (config.user) {
        if (!this.isSignUpStarted() || this.isSignUpFinished()) {
          window.location.href = DRIVER_PORTAL_URL;
        }
        this.mixPanel.identifyUser(config.user);
      }
    });
  }

  private triggerRouteChange(route: string) {
    this.syncMasterQueryParamsWithLangSvc();
    this.$currentRoute.next({route, queryParams: this.masterObj.urlSearchParams});
  }

  private processPayPal() {
    // extracting token
    const token = new URLSearchParams(window.location.search).get('token');
    this.masterObj.processPayPalToken = true;

    this.appConfig.getConfig().subscribe(data => {
      const obj = {
        'currency': data.currencyCode,
        'paymentType': 'AUTO_TOP',
        'token': token.toString()
      };

      // get the paymentID
      this.userPaymentService.authorizePaypal(obj)
        .subscribe(payObj => {
          // set the router
          this.triggerRouteChange(this.currentRouteComponentId);
          const backendSaveObj = new Payment(payObj);
          this.updateSignUpData(payObj);
          this.saveBackendData(backendSaveObj);
          this.masterObj.processPayPalToken = false;
          this.saveToSessionStorage(backendSaveObj);
          this.nextRoute();
        });
    });
  }

  private processConfig(config) {
    this.setInitialRouteComponentId(config);
    this.setConfig(config);
    this.setOrgTypeFromConfig(config);

    // todo refactor this and move it to it's own function without any side effects
    let hideBackButton = true;
    config.components.forEach((component, i) => {

      if (hideBackButton === true) {
        component.showBackButton = false;
        hideBackButton = false;
      } else {
        component.showBackButton = true;
      }
      if (component.componentId === 'submit') {
        hideBackButton = true;
      }

      if (component.hidden) {
        this.buildDataFromHiddenComponent(component, config.schema);
      } else {
        this.routes.push(component.componentId);
      }
      if (component.componentId === 'session') {
        this.masterObj.signupType = component.fields.find(field => {
          return field.id === 'signupType';
        }).value;
      }
      if (component.componentId === 'profile') {
        component.fields.forEach(field => {
          if (!field.value) {
            return;
          }
          switch (field.id) {
            case 'email':
              this.masterObj.signupEmail = field.value;
              break;
            case 'givenName':
              this.masterObj.givenName = field.value;
              break;
            case 'familyName':
              this.masterObj.familyName = field.value;
              break;
            default:
              break;
          }
        });
      }
      if (component.componentId === 'payment') {
        this.masterObj.paymentOptional = component.optional;
      }
      // setting the last component for the continue/create account button
      if (component.isFinal) {
        this.masterObj.lastComponent = config.components[i - 1].componentId;
      }
    });

    const cleanedComponents = config.components.filter(component => {
      return (!component.hidden || component.id === 'submit');
    });

    cleanedComponents.forEach((component, i) => {
      if (component.componentId === 'submit') {
        this.masterObj.createComponent = cleanedComponents[i - 1].componentId;
      }
    });

    // save to storage
    this.saveToSessionStorage();


    // on refresh, this code will redirect to the right place
    if (this.checkForRefreshFromCaptcha()) {
      this.nextRoute();
      sessionStorage.removeItem('callNextRoute');
      return;
    }

    const index = this.routes.indexOf(this.currentRouteComponentId);
    if ((index > 0 && index < this.routes.length) || (this.isRouteFromMemory)) {
      this.triggerRouteChange(this.currentRouteComponentId);
    } else {
      this.triggerRouteChange(this.routes[0]);
    }
  }

  // initial config processing functions
  private setConfig(config) {
    this.config = config;
    this.saveAllEndpoint = config.endpoint;
  }
  private setInitialRouteComponentId(config) {
    if (!this.currentRouteComponentId) {
      this.currentRouteComponentId = config.components[0].componentId;
    }
  }

  private setOrgTypeFromConfig(config) {
    if (config?.orgConfig?.orgType) {
      this.userPaymentService.setOrgType(config.orgConfig.orgType);
    }
  }


  private buildDataFromHiddenComponent(component: any, schema: any) {
    component.fields.forEach((field) => {
      if (schema[component.componentId] && schema[component.componentId][field.id]) {
        if (!this.masterObj[component.componentId]) {
          this.masterObj[component.componentId] = {};
        }
        this.masterObj[component.componentId][field.id] = field.value;

        if (!this.savedBackendData[component.componentId]) {
          this.savedBackendData[component.componentId] = {};
        }
        this.savedBackendData[component.componentId][field.id] = field.value;
      }
    });
  }

  public updateErrorMessage(errorMessage?: string) {
    if (errorMessage === this.currentErrorMessage) {
      return;
    }
    if (errorMessage) {
      this.currentErrorMessage = errorMessage;
    } else {
      this.currentErrorMessage = '';
    }
    this.$serverErrorMessage.next(this.currentErrorMessage);
  }

  private syncMasterQueryParamsWithLangSvc() {
    this.masterObj.urlSearchParams['locale'] = this.langService.getLocale();
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { LanguageModule } from '../language/language.module';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { CpCommonModule } from '../cp-common/cp-common.module';
import { LoaderComponent } from '../cp-common/loader/loader.component';

@NgModule({
  imports: [
    CommonModule,
    LanguageModule,
    HttpClientModule,
    ReactiveFormsModule,
    CpCommonModule
  ],
  exports: [LoginComponent, LoaderComponent],
  declarations: [LoginComponent, LoaderComponent]
})
export class LoginModule { }

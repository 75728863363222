import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import * as Sentry from '@sentry/browser';


@Injectable({
  providedIn: 'root'
})
export class TraceIdInterceptorService implements HttpInterceptor{

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const started = Date.now();
    let ok: string;
    const transactionId = `trace-${Math.random().toString(36).substring(2, 11)}`;

    Sentry.configureScope((scope: Sentry.Scope) => {
      scope.setTag('request_id', transactionId);
    });

    const clonedRequest = req.clone({
      headers: req.headers.set('Request-trace-id', transactionId)
    });
    
   // Pass the cloned request instead of the original request to the next handle
   return next.handle(clonedRequest);
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'cp-terms-dialog',
  templateUrl: './terms-dialog.component.html',
  styleUrls: ['./terms-dialog.component.scss']
})
export class TermsDialogComponent {
  isModalOpen = false;
  @Input() content = '';

  @Input()
  set openModal(show: boolean) {
    this.isModalOpen = show;
  }

  constructor() { }

  modalToggle(e?) {
    if (e) {
      this.isModalOpen = e.target.dataset.close ? false : true;
    } else {
      this.isModalOpen = true;
    }
  }
}

import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CommunicationService } from '../services/communication.service';
import { GetHomeChargerService } from '../get-home-charger/get-home-charger.service';
import { HomeCharger } from '../services/models/backend-save-classes';
import { CPMixPanel } from '../services/mixpanel.service';
import { FrontendContentConfigService } from '../services/frontend-content-config.service';

@Component({
  selector: 'cp-get-home-charger-sorry',
  templateUrl: './get-home-charger-sorry.component.html',
  styleUrls: ['./get-home-charger-sorry.component.scss']
})
export class GetHomeChargerSorryComponent {

  isEdit = false;

  constructor(
    private router: Router,
    private comSvc: CommunicationService,
    private hmChargerService: GetHomeChargerService,
    private mixPanel: CPMixPanel,
    private frontendConfigSvc: FrontendContentConfigService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.indexOf('webview') !== -1) {
          this.isEdit = true;
          this.frontendConfigSvc.setBodyTitle('splashTitle');
        } else {
          this.frontendConfigSvc.setBodyTitle('homeChargerTitle');
        }
      }
    });
  }

  submitHomeChargerSorry() {
    this.mixPanel.homeChargerUnavailable();
    const formGrp = this.hmChargerService.homeChargerFormGroup;
    const backendSave = new HomeCharger(formGrp.value);
    this.comSvc.submitFormFields(formGrp.value, backendSave);
  }
}

<div class="form-group" [formGroup]="parentForm" *ngIf="fieldConfig">
  <input type="text"
     #textInput 
     class="form-control"
     data-private="lipsum" 
     [formControlName]="fieldConfig.name"
     placeholder="{{translatedPlaceholder}}"
     [attr.aria-label]="ariaLabelOveride? ariaLabelOveride : translatedPlaceholder"
     [class.invalid]="updateError()"
     [attr.aria-describedby]="inputId + 'error'"
     [required]="required"
    />
  <p *ngIf="showLoginPrompt && !control.hasError('emailExists')" class="login-prompt-container" [id]="inputId + 'error'">
    {{'Have a ChargePoint account with a different email?' | T}} <a routerLink="/signup/login">{{'Log in' | T }}</a>
  </p>
  <div *ngIf="updateError()" class="has-error">
    <span *ngIf="control.errors.required" [id]="inputId + 'error'">
      {{"errorMsgRequiredField" | T: {fieldName: translatedPlaceholder} }}
    </span>
    <span *ngIf="control.errors.email" [id]="inputId + 'error'">
      {{ "Please enter a valid email." | T }}
    </span>
    <span *ngIf="control.errors.emailExists" [id]="inputId + 'error'">
      {{ "This email is not available." | T }}  <a *ngIf="showLoginPrompt" routerLink="/signup/login" >{{'Log in to existing account' | T }}</a>
    </span>
  </div>
</div>

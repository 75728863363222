import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, retry, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment'; 
import { BehaviorSubject, lastValueFrom, Observable, of } from 'rxjs';
import { DefaultCountry, DiscoveryGlobalConfig, UserAgreement } from './interfaces/DiscoveryGlobalConfig';
import { LanguageService } from './language.service';
import * as Sentry from '@sentry/browser';


@Injectable({
  providedIn: 'root',
})
export class DiscoveryStoreService {
  private readonly _discoveryDataSource =
    new BehaviorSubject<DiscoveryGlobalConfig | null>(null);

  // Exposed observable (read-only).
  readonly discoveryData$ = this._discoveryDataSource.asObservable();

  readonly userAgreements$: Observable<UserAgreement[]> = this.discoveryData$.pipe(
    map((data: DiscoveryGlobalConfig) => data.userAgreements)
  );

  readonly nosPortalDomainEndpoint$: Observable<string> = this.discoveryData$.pipe(
    map((data: DiscoveryGlobalConfig) => data.endPoints.portal_domain_endpoint.value)
  );

  readonly mixpanelTokeAlias$: Observable<string> = this.discoveryData$.pipe(
    map((data: DiscoveryGlobalConfig) => data.analytics.mixpanel.tokenAlias)
  );

  defaultRegionCountry: DefaultCountry = null;
  locale: string = null;

  constructor(private http: HttpClient, private languageService: LanguageService) {}

  getDiscoveryData(): DiscoveryGlobalConfig {
    return this._discoveryDataSource.getValue();
  }

  private _setDiscoveryData(data: DiscoveryGlobalConfig | null): void {
    this._discoveryDataSource.next(data);
  }

  private selectedCountry: string = null;

  /**
   * Initializes the App component by loading discovery data and subscribing to language changes.
   * This function determines the user's preferred locale, sets it as the initial locale,
   * and updates the discovery data accordingly. If the user changes the language during
   * runtime, this function reloads the discovery data to reflect the new language settings.
   * Returns a Promise that resolves once the initialization is complete.
   */
  initialize() {
    return new Promise<void>((resolve) => {
      this.locale = this.languageService.determineUserLocale();
      lastValueFrom(this.loadDiscoveryData()).then((data) => {
        this._setDiscoveryData(data);
        this.languageService.localeEmmiter.subscribe((locale) => {
          if (this.locale !== locale) {
            this.locale = locale;
            this.reloadDiscoveryData(this.selectedCountry);
          }     
        });
        resolve();
      });
    });
  }

  loadDiscoveryData(countryCode?: string): Observable<DiscoveryGlobalConfig | null> {

    const params = new URLSearchParams(window.location.search);
    const requiredInstance = params.get('requiredInstance') || params.get('requiredinstance') || undefined;
    const subdomain = params.get('subdomain') || undefined;

    if (requiredInstance) {
      Sentry.setTag('requiredInstance', requiredInstance);
    }

    if (subdomain) {
      Sentry.setTag('subdomain', subdomain);
    }
    
    this.selectedCountry = countryCode;

    return this.http.post<DiscoveryGlobalConfig>(
      environment.DISCOVERY_API,{countryCode: this.selectedCountry, requiredInstance, subdomain}, {
        withCredentials: true,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept-Language': this.locale || 'en-US',
        })
      }).pipe(
        retry(3),
        tap((data) => {
          Sentry.setTag('region', data.region);
        }),
        catchError((error) => {
          return of(null);
        })
      );
  }

  getAccountV1ApiEndpoint(): string {
    return `${this.getDiscoveryData().endPoints.accounts_endpoint.value}v1`;
  }

  getAccountV2ApiEndpoint(): string {
    return `${this.getDiscoveryData().endPoints.accounts_endpoint.value}v2`;
  }
  getBaseAccountApiEndpoint(): string {
    return this.getDiscoveryData().endPoints.accounts_endpoint.value;
  }

  getNOSApiEndpoint(): string {
    return this.getDiscoveryData().endPoints.portal_domain_endpoint.value;
  }

  getSSOUrl(): string {
    const ssoUrl = new URL(this.getDiscoveryData().endPoints.sso_endpoint.value);
    return ssoUrl.origin;
  }

  getDefaultRegionCountry(): DefaultCountry {
    return this.getDiscoveryData().defaultCountry;
  }

  getPaymentApiEndpoint(): string {
    return this.getDiscoveryData().endPoints.payment_java_endpoint.value;
  }

  reloadDiscoveryData(countryCode?: string): Promise<DiscoveryGlobalConfig> {
    if (countryCode) {
      this.selectedCountry = countryCode;
    }
    return new Promise((resolve) => {
      this.loadDiscoveryData(this.selectedCountry)
        .pipe(take(1))
        .subscribe((data: DiscoveryGlobalConfig) => {
          this._setDiscoveryData(data);
          resolve(data);
        });
    });
  }

  getLanguageList() {
    return this.http.get<any>(`${this.getAccountV1ApiEndpoint()}/metadata/languages`).pipe(
      catchError(() => {
        return of({});
      })
    )
  }
}


export function applicationInitFactory(discoveryStoreService: DiscoveryStoreService) {
  return () => discoveryStoreService.initialize();
}

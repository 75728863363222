import { Injectable,  } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { frontEndConfig } from './frontend-config-models/config';
import { ThemeService } from './theme.service';

@Injectable({
  providedIn: 'root'
})

export class FrontendContentConfigService {
  cobrand = 'default';
  $bodyTitle = new BehaviorSubject(null);
  bodyTitle = null;
  constructor(private themeService: ThemeService) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const searchParamBrand = urlSearchParams.get('brand');
    const theme = themeService.getTheme();
    if (searchParamBrand || theme !== 'default') {
      const cobrand = searchParamBrand || theme;
      this.updateCobrand(cobrand);
    }
    this.themeService.themeChanged.subscribe((theme) => {
      this.updateCobrand(theme);
    });
  }

  updateCobrand(cobrand?: string) {
    if (cobrand) {
      this.cobrand = cobrand;
      if (this.bodyTitle) {
        this.setBodyTitle(this.bodyTitle);
      }
    }
  }

  setBodyTitle(bodyTitle: string) {
    this.bodyTitle = bodyTitle;
    let cobrandConfig = frontEndConfig[this.cobrand];
    if (!cobrandConfig) {
      cobrandConfig = frontEndConfig['default']; // fallback for missing configs
    }
    if (cobrandConfig[bodyTitle]) {
      this.$bodyTitle.next(cobrandConfig[bodyTitle]);
    } else {
      this.$bodyTitle.next(bodyTitle); // fallback for missing config or proper names that don't need translation.
    }
  }

  getLayoutConfig(propName: string) {
    let cobrandConfig = frontEndConfig[this.cobrand];
    if (!cobrandConfig) {
      cobrandConfig = frontEndConfig['default']; // fallback for missing configs
    }
    return cobrandConfig['layout'][propName];
  }

  getTextContent(propName: string) {
    let cobrandConfig = frontEndConfig[this.cobrand];
    if (!cobrandConfig) {
      cobrandConfig = frontEndConfig['default']; // fallback for missing configs
    }
    return cobrandConfig[propName];
  }

  isDaimler() {
    return this.cobrand === 'daimler';
  }
}

<form>
  <div *ngIf="serverErrorMessage" class="text-danger" role="alert">
    {{serverErrorMessage}}
  </div>

  <div [ngStyle]="{display: (codeSent ? 'none': 'block')}">
      <b class="info-label">
        {{"What’s your number?" | T}}
      </b>
      <p class="info-paragraph">{{"We’ll send you a code to verify your account" | T}}</p>
    <cp-phone-w-country [parentForm]="formGroup" #phone [needValidate]=true [onlyPhoneVerificationSupported]=true
      [onlySupported]="false" [phoneValue]="enteredNumber" [pressedSubmit]="pressedSubmit"
      (enterKeyPressed)="submitFunction()"></cp-phone-w-country>
  </div>

  <div [ngStyle]="{display: (codeSent ? 'block': 'none')}">
    <b class="info-label">
      {{"What’s the code?" | T}}
    </b>
    <p class="info-paragraph _lr-hide">{{"Enter the code we sent to %phoneNumber%"| T: {phoneNumber:phoneNumber} }}</p>
    <cp-input 
      [setParentForm]="codeFormGroup" 
      [setConfig]="smsCodeInputConfig" 
      #codeInput
      (enterKeyPressed)="submitFunction()" 
      [pressedSubmit]="codePressedSubmit" 
      [addValidation]="true"
      [onlyNumbers]="true"
      [ariaLabelOveride]="'SMS verification code' | T"
      required="true"
      ></cp-input>
  </div>

  <cp-signup-footer-controls  (nextClicked)="submitFunction()"
    focusOnError="true" [nextButtonText]="continueButtonText" [backButtonPreventDefault]="true"
    (backClicked)="backClick()"></cp-signup-footer-controls>
  <div class="senditagain-container">
    <button 
      *ngIf="codeSent"
      class="senditagain btn btn-link" 
      (click)="verifyAccount(true); 
      $event.preventDefault();"
      [attr.aria-label] = "'ariaLabelSendVerifyCodeAgain' | T">
    {{"Send it again" | T}}
  </button>
  </div>  
  <cp-loader *ngIf="isLoading"></cp-loader>
</form>
import { AbstractControl, FormGroup } from "@angular/forms";

export function setControlMetadata(controlName: string, form: FormGroup, metadata: any): void {
    const control: AbstractControl | null = form.get(controlName);
    if (control) {
      Object.keys(metadata).forEach((key: string) => {
        control[key] = metadata[key];
      });
    }
  }

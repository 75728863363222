// tslint:disable-next-line:max-line-length
import { defaultBodyTitle, daimlerAccount, daimlerPayment, daimlerProfileTitle, daimlerSplashTitle, THANK_YOU,
  daimlerCardTitle, cardTitle, daimlerDontHaveCard, ADD_ANOTHER_CARD, VERIFY_ACCOUNT, successTitle,
  whatAreYouDrivingTitle, notificationsTitle, addPaymentTitle, homeChargerTitle , daimlerAddAnotherCard} from './translate';

export const frontEndConfig = {
  default: {
    profileTitle: defaultBodyTitle,
    accountTitle: defaultBodyTitle,
    paymentTitle: addPaymentTitle,
    splashTitle: '',
    successTitle: successTitle,
    whatAreYouDrivingTitle: whatAreYouDrivingTitle,
    notificationsTitle: notificationsTitle,
    cardTitle: cardTitle,
    verifyAccountTitle: VERIFY_ACCOUNT,
    homeChargerTitle: homeChargerTitle,
    dontHaveACardContent: null,
    addAnotherCardTxt: ADD_ANOTHER_CARD,
    layout: {
      cards: {
        showWithAppChckBx: true,
        showIhaveACardOnly: false,
        showNumberIsOnBack: true
      },
      payment: {
        showSubHeader: true
      }
    }
  },
  daimler: {
    profileTitle: daimlerProfileTitle,
    accountTitle: daimlerAccount,
    paymentTitle: daimlerPayment,
    splashTitle: daimlerSplashTitle,
    successTitle: THANK_YOU,
    cardTitle: daimlerCardTitle,
    dontHaveACardContent: daimlerDontHaveCard,
    addAnotherCardTxt: daimlerAddAnotherCard,
    notificationsTitle: notificationsTitle,
    verifyAccountTitle: '',
    layout: {
      cards: {
        showWithAppChckBx: false,
        showIhaveACardOnly: true,
        showNumberIsOnBack: false,
        validateOnlySerial: true
      },
      payment: {
        showSubHeader: false
      }
    }
  }
};


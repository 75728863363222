import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { DiscoveryStoreService } from './discovery.service';
import { CommunicationService } from './communication.service';
@Injectable({
  providedIn: 'root'
})
export class LinkAccountService {

  constructor(private http: HttpClient, private discoveryService: DiscoveryStoreService, private comSvc: CommunicationService) { }

  linkAccounts(email: string, password: string, sessionId: string, username: string) {

    const subDomain = this.comSvc.getDenPartnerSubdomain();

    const body = {
      email: email,
      password: password,
      sessionId: sessionId,
      subDomain: subDomain,
      username: username
    };
    return this.http.put(
        `${this.discoveryService.getAccountV1ApiEndpoint()}/den/register`,
        body,
        { withCredentials: true }
    ).pipe(
      catchError(error => of(error))
    );
  }
}

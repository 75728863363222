<cp-loader *ngIf="isLoading"></cp-loader>
<div *ngIf="!isLoading">
  <div>
    <p class="head-blurb">
      {{"%connectionName% provides you a ChargePoint Home to charge at home. The charger can only be installed in your personal garage or driveway." | T:{connectionName:connectionName} }}
    </p>
  </div>
  <form [formGroup]="parkedCarForm" (ngSubmit)="submitWhereParked()">
    <div class="where-parked-container">
      <div>
      <p>{{"Do you have a personal garage or driveway?" |T}}</p>
      <div>
        <div class="custom-control custom-radio">
          <input type="radio" id="driveway" class="custom-control-input" formControlName="whereIsCarParked" value="garage"/>
          <label class="custom-control-label" for="driveway">{{"Yes" |T}}</label>
        </div>
        <div class="custom-control custom-radio">
          <input type="radio" id="on-street" class="custom-control-input" formControlName="whereIsCarParked" value="onstreet"/>
          <label class="custom-control-label" for="on-street">{{"No" |T }}</label>
        </div>
      </div>
      <div *ngIf="parkedCarForm.get('whereIsCarParked').invalid && parkedCarForm.get('whereIsCarParked').touched" class="alert alert-danger">
        <div *ngIf="parkedCarForm.get('whereIsCarParked').errors.required">
          {{"Field is required." |T}}
        </div>
      </div>
      <div class="text-center isloggedInControl" *ngIf="!isEdit && !isMobile">
        <a href="#" (click)="submitNoChargerNeeded($event)" class="text-center">{{"I don’t need a home charger" |T }}</a>
      </div>
    </div>


    </div>
    <cp-signup-footer-controls (nextClicked)="submitWhereParked()" [showBackButton]='showBackButton' [nextButtonText]="nextButtonText"></cp-signup-footer-controls>
  </form>
</div>

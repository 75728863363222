import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { ConnectionService } from './connection.service';
import { CommunicationService } from '../services/communication.service';
import { DriverConnection } from '../services/models/backend-save-classes';
import { CPMixPanel } from '../services/mixpanel.service';
import { FrontendContentConfigService } from '../services/frontend-content-config.service';
import { UiFlowStateService } from '../services/ui-flow-state.service';

@Component({
  selector: 'cp-connection',
  templateUrl: './connection.component.html',
  styleUrls: ['./connection.component.scss']
})
export class ConnectionComponent implements OnInit {
  token: string;
  errorToken = false;
  fieldsLoaded = false;
  pressedSubmit = false;
  showTermsModal = false;
  isLoading = false;
  termsText = '';
  connectionFields: any = {};
  connectionForm: FormGroup = new FormGroup({
    chargepointPolicyAccept: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'change'
    })
  });

  constructor(
      private connectionService: ConnectionService,
      private communicationSvc: CommunicationService,
      private mixPanel: CPMixPanel,
      private frontEndConfigSvc: FrontendContentConfigService,
      protected stateSvc: UiFlowStateService
  ) { }

  ngOnInit() {
    this.token = this.communicationSvc.getPartnerToken();
    const masterObj = this.communicationSvc.getMasterObject();
    const urlSearchParams = new URLSearchParams(window.location.search);

    this.connectionService.getFields(this.token).subscribe((data: any) => {
      if (data.connectionFields) {
        data.connectionFields.forEach((x, index) => {
          this.connectionForm.addControl(x.key, new FormControl('', {
            validators: [Validators.required],
            updateOn: 'change'
          }));

          if (masterObj && masterObj['connectionFields'] && masterObj['connectionFields'][index]) {
            this.connectionForm.get(x.key).setValue(masterObj['connectionFields'][index].value);
          } else if (urlSearchParams.get(x.key)) {
            this.connectionForm.get(x.key).setValue(urlSearchParams.get(x.key));
          }
        });

        if (masterObj['connectionFields'] && masterObj['connectionFields'].length === data.connectionFields.length) {
          this.connectionForm.get('chargepointPolicyAccept').setValue(true);
        }
      }

      this.fieldsLoaded = true;
      this.connectionFields = data;
      this.frontEndConfigSvc.setBodyTitle(data.connectionName);
      if (data.companyTcLink) {
        this.connectionForm.addControl('companyPolicyAccept', new FormControl('', {
          validators: [Validators.required],
          updateOn: 'change'
        }));
        if (masterObj['connectionFields'] && masterObj['connectionFields'].length === data.connectionFields.length) {
          this.connectionForm.get('companyPolicyAccept').setValue(true);
        }
      }
    }, () => {
      // error handling if token is wrong
      this.frontEndConfigSvc.setBodyTitle('');
      this.errorToken = true;
    });
  }

  submitConnection() {
    this.pressedSubmit = true;
    this.isLoading = true;
    if (this.connectionForm.valid) {
      const finalObj = {token: this.token, connectionFields: []};
      if (this.connectionFields.connectionFields) {
        this.connectionFields.connectionFields.forEach((x) => {
          finalObj.connectionFields.push({
            id: x.id,
            value: this.connectionForm.get(x.key).value
          });
        });
      }

      this.mixPanel.connectionContinue();
      const backendSaveObj = new DriverConnection(finalObj);
      this.communicationSvc.submitFormFields(finalObj, backendSaveObj);
      // DRIPOR-445 Corner case with error from account
      this.stateSvc.$serverErrorMessage.subscribe(error => {
        if (error) {
          this.isLoading = false;
        }
      });
    } else {
      if (this.connectionForm.status === 'PENDING') {
        setTimeout(() => {
          this.submitConnection();
        }, 500);
      } else {
        this.isLoading = false;
      }
    }
  }

  updateError(type) {
    return (this.connectionForm.get(type).invalid && this.connectionForm.get(type).touched)
      || (this.connectionForm.get(type).invalid && this.pressedSubmit);
  }

  continueError() {
     if (this.connectionFields.companyTcLink) {
       return !(this.connectionForm.value.chargepointPolicyAccept && this.connectionForm.value.companyPolicyAccept);
     } else {
      return !this.connectionForm.value.chargepointPolicyAccept;
     }
  }

  openTermsDialog(e, link: string) {
    if (e.target.nodeName === 'A') {
      e.stopPropagation();
      e.preventDefault();
      this.showTermsModal = false;
      this.termsText = '';
      if (link) {
        this.connectionService.getTermsContent(link).subscribe(content => {
          if (content) {
            this.showTermsModal = true;
            this.termsText = content;
          }
        });
      }
    }
  }
}

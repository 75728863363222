<div class="form-group" [formGroup]="parentForm" *ngIf="fieldConfig">
  <input 
    type="password"
    #textInput 
    class="form-control" 
    data-private="lipsum" 
    [formControlName]="fieldConfig.name" 
    placeholder="{{translatedPlaceholder}}" 
    [attr.aria-label]="translatedPlaceholder"
    [class.invalid]="updateError()" 
    autocomplete="new-password"
    [attr.aria-describedby]="inputId + 'error'"
    [required]="required"
    />
  <div *ngIf="updateError()" class="has-error">
    <span *ngIf="control.errors.required" [id]="inputId + 'error'">
      {{"errorMsgRequiredField" | T: {fieldName: translatedPlaceholder} }}
    </span>
    <span *ngIf="control.errors.needDigit" [id]="inputId + 'error'">
      {{"Your password must contain at least one digit." | T }}
    </span>
    <span *ngIf="control.errors.needChar" [id]="inputId + 'error'">
      {{"Your password must contain at least one character." |T }}
    </span>
    <span *ngIf="control.errors.wrongSize" [id]="inputId + 'error'">
      {{"Please enter a password between 8-64 characters in length." |T }}
    </span>
  </div>
</div>

import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { CommunicationService } from '../services/communication.service';
import { UiFlowStateService } from '../services/ui-flow-state.service';
import { PhoneWCountrySimpleComponent } from '../shared/phone-w-country-simple/phone-w-country-simple.component';
import { FrontendContentConfigService } from '../services/frontend-content-config.service';
import { LoadingIndcatorServiceService } from '../services/loading-indcator-service.service';

@Component({
  selector: 'cp-cp-common-success-refactor',
  templateUrl: './cp-common-success-refactor.component.html',
  styleUrls: ['./cp-common-success-refactor.component.scss']
})
export class CpCommonSuccessRefactorComponent implements OnInit {
  
  @HostListener('window:beforeunload', ['$event'])
  closePage($event: any) {
    this.uiFlowState.clearSessionStorage();
  }

  @ViewChild(PhoneWCountrySimpleComponent) phoneWCountry: PhoneWCountrySimpleComponent;
  countryId: string;
  phoneNumber: string;
  serverErrorMessage: string;
  driverAccountURL: string;
  constructor(
    private uiFlowState: UiFlowStateService,
    private commSvc: CommunicationService,
    private frontEndConfig: FrontendContentConfigService,
    private loadingIndicatorService: LoadingIndcatorServiceService
    ) { }

  ngOnInit(): void {
    this.frontEndConfig.setBodyTitle('successTitle');
    this.uiFlowState.setSignUpFinished();
    this.phoneNumber = this.commSvc.getPhoneNumber();
    this.countryId = this.commSvc.getDriverCountry().id.toString();
    this.driverAccountURL = this.commSvc.getViewAccountLink();
  }

  isFormInvalid() {
    return this.phoneWCountry.phoneWithCountryForm.invalid;
  }

  getFormData() {
    return {
      phone: {
        countryId: this.phoneWCountry.phoneWithCountryForm.controls.countryId.value,
        phone: this.phoneWCountry.phoneWithCountryForm.controls.phoneNumber.value
      }
    };
  }

  onSubmitForm() {
    this.serverErrorMessage = null;
    if (this.isFormInvalid()) {
      return;
    }
    const phoneFormData = this.getFormData();
    this.loadingIndicatorService.loadingRequestStarted('common-success-refactor');
    // this.uiFlowState.$isLoading.next(true);
    this.commSvc.pushToBackend(phoneFormData, 'thankyou').pipe(
      catchError(err => {
        this.handleError(err);
        return of(null);
      }),
      finalize(() => this.loadingIndicatorService.loadingRequestFinished())
    ).subscribe(); 
  }

  handleError(err) {
    this.serverErrorMessage = err.error?.errorMessage || 'Server Error';
  }
}

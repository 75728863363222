import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LangPipe } from '../pipes/lang.pipe';
import { LanguageService } from '../services/language.service';
import { getLocaleConfig } from './locale-config';
import { setUserLocale } from 'src/localstorage';
import { KitLocaleConfig } from './interfaces';
import { DiscoveryStoreService } from '../services/discovery.service';

export interface SupportedLocale {
  id: number;
  name: string;
  locale: string;
}

@Component({
  selector: 'cp-locale-picker-modal',
  templateUrl: './locale-picker-modal.component.html',
  styleUrls: ['./locale-picker-modal.component.scss']
})

export class LocalePickerModalComponent implements OnInit {
  supportedLocales: SupportedLocale[] = [];
  localeConfig: KitLocaleConfig;
  langpipe: LangPipe;
  isModalOpen: boolean = false;
  languageHasChanged: boolean = false;

  @Input()
  set openModal(show: boolean) {
    this.isModalOpen = show;
  }

  @Output() onToggle: EventEmitter<boolean> = new EventEmitter();

  constructor(private languageSvc: LanguageService, private discovery: DiscoveryStoreService) { }

  ngOnInit() { 
    this.discovery.getLanguageList().subscribe(list => {
      this.supportedLocales = list;
    })

    this.langpipe = new LangPipe(this.languageSvc);
    const localeConfig = getLocaleConfig(this.langpipe);
    localeConfig.regions.forEach(region => {
      const sortedLocales  = region.locales.sort((a, b) => a.localizedLanguageName.localeCompare(b.localizedLanguageName));
      region.locales = sortedLocales;
    });
    this.localeConfig = localeConfig;
  }

  changeLanguage(locale: string) {
    setUserLocale(locale);
    this.languageHasChanged = true;
    this.onToggle.emit(false);
    this.languageSvc.updateLanguage(locale);
  }

  isSupportedLocale(localeCode: string) {
    if (!this.supportedLocales?.length) return;

    return this.supportedLocales.some((l) => {
      return l.locale === localeCode;
    });
  }
      
  modalToggle(e?) {
    if(this.languageHasChanged) return;
    if (e) {
      const val = e.target.dataset.close ? false : true;
      this.onToggle.emit(val);
    } else {
      this.onToggle.emit(true);
    }
  }

  trackByFn(index:number, region:any): number {
    return region.regionCode;
  }
}

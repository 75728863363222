import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { GetHomeChargerService } from '../get-home-charger/get-home-charger.service';
import { CommunicationService } from '../services/communication.service';
import { HomeCharger } from '../services/models/backend-save-classes';
import { SessionService } from '../services/session.service';
import { PhoneWCountryComponent } from '../shared/phone-w-country/phone-w-country.component';
import { AddressComponent } from '../shared/address/address.component';
import { CPMixPanel } from '../services/mixpanel.service';
import { AddressWithZip } from '../services/models/address-with-zip';
import { FrontendContentConfigService } from '../services/frontend-content-config.service';
import { NEXT, CONTINUE } from '../cp-common/signup-footer-controls/translate';
import { LangPipe, NumberPipe } from '../pipes/lang.pipe';
import { LanguageService } from '../services/language.service';


@Component({
  selector: 'cp-home-charger-address',
  templateUrl: './home-charger-address.component.html',
  styleUrls: ['./home-charger-address.component.scss']
})
export class HomeChargerAddressComponent implements OnInit {
  numberPipe: NumberPipe;
  cost = '0';
  parentForm = new FormGroup({ 
    whereIsCarParked : new FormControl('', Validators.required), 
    installHomeCharger : new FormControl(false)
  });
  pressedSubmit = false;
  isLoggedIn = false;
  connectionName: string;
  currencySymbol = '$';
  isEdit = false;
  isLoading = false;
  phoneValue = '';
  addressValue: AddressWithZip;
  nextButtonText = '';
  @ViewChild('homeChargerPhone') homeChargerPhone: PhoneWCountryComponent;
  @ViewChild('homeChargerAddress') homeChargerAddress: AddressComponent;
  constructor(
    protected homeChargerSvc: GetHomeChargerService,
    protected comSvc: CommunicationService,
    protected session: SessionService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected mixPanel: CPMixPanel,
    protected frontendConfigSvc: FrontendContentConfigService,
    protected langSvc: LanguageService
  ) {
    this.init();
  }

  ngOnInit() {
    this.numberPipe = new NumberPipe(this.langSvc);
    this.parentForm = this.homeChargerSvc.homeChargerFormGroup;

    if (!this.parentForm.get('whereIsCarParked').value) {
      this.parentForm.patchValue({ whereIsCarParked: 'garage'});
    }

    this.homeChargerSvc.getHomeChargerStatus().subscribe(statusObj => {
      this.connectionName = statusObj.connectionName;
      this.currencySymbol = statusObj.currencySymbol;
      this.session.getUser(true).subscribe((user) => {
        let address = null;
        if (user) {
          this.isLoggedIn = true;
        }
        if (this.isLoggedIn) {
          if (statusObj.address && statusObj.address.address1) {
            this.patchAddressValues(statusObj.address);
          } else {
            this.patchAddressValues(user.address);
          }

          if (statusObj.phoneNumber) {
            this.phoneValue = statusObj.phoneNumber;
          }
          if (statusObj.dialingCode) {
            this.homeChargerPhone.setCountryByCode(statusObj.dialingCode);
          } else if (statusObj.address && statusObj.address.countryId) {
            this.homeChargerPhone.setCountry(statusObj.address.countryId);
          } else if (user.address && user.address.countryId) {
            this.homeChargerPhone.setCountry(user.address.countryId);
          }
        } else {
          address = this.comSvc.getMainAddress();
          this.patchAddressValues(address);
          if (address.countryId) {
            this.homeChargerPhone.setCountry(address.countryId);
          }
        }
      });
    });
  }

  init() {
    const langpipe = new LangPipe(this.langSvc);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd ) {
        if (event.url.indexOf('signup') === -1) {
          this.isEdit = true;
          this.frontendConfigSvc.setBodyTitle('splashTitle');
          this.nextButtonText = langpipe.transform(CONTINUE);
        } else {
          this.nextButtonText = langpipe.transform(NEXT);
          this.frontendConfigSvc.setBodyTitle('homeChargerTitle');
        }
      }
    });
  }

  patchAddressValues(address) {
    /* mobile fix */
    setTimeout(() => {
        this.addressValue = address;
      }, 500);
  }

  submitAddressDetails() {
    this.pressedSubmit = true;
    this.isLoading = true;
    if (this.parentForm.valid) {
      const backendSave = new HomeCharger(this.parentForm.value);
      this.mixPanel.homeChargerAddressContinue();
      if (this.isLoggedIn && this.isEdit) {
        this.homeChargerSvc.updateHomeChargerStatus(backendSave).subscribe((resp) => {
          if (resp) {
            this.mixPanel.submitChangeRequest();
            this.router.navigate(['../home-charger-done'], {relativeTo: this.activatedRoute});
          } else {
            // there was an http error.
            this.isLoading = false;
          }
        });
      } else {
        this.parentForm.patchValue({ installHomeCharger: true });
        this.comSvc.submitFormFields(this.parentForm.value, backendSave);
      }
    } else {
      if (this.parentForm.status === 'PENDING') {
        setTimeout(() => {
          this.submitAddressDetails();
        }, 500);
      } else {
        this.isLoading = false;
      }
    }
  }

  // this function helps clean up the HTML
  updateError(type) {
    if (type === 'firstname' || type === 'lastname') {
    }
    return (this.parentForm.get(type).invalid && this.parentForm.get(type).touched)
      || (this.parentForm.get(type).invalid && this.pressedSubmit);
  }

  navigateToGetHomeCharger() {
    this.router.navigate(['/webview/get-home-charger', { allowRedirect: false }]);
  }
}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import packageJson from '../package.json';
import * as Sentry from "@sentry/angular-ivy";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: "https://b2dee385b6d14f05a594c0f794cd3cd2@o1261169.ingest.sentry.io/4504285087072256",
  debug: false,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.25,
  release: 'cp-signup@' + packageJson.version,
  environment: environment.envType,
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

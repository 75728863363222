<div [ngClass]="{'webview-content': isEdit}">
  <p>
    {{"Sorry, ChargePoint Home is for independent homes. It cannot be installed at your home." |T}}
  </p>

  <a *ngIf="isEdit" [routerLink]="['/webview/get-home-charger', {allowRedirect:false}]" class="change-link">
    {{"Change Request" | T }}
  </a>

  <div class="button-container text-center mt-5" *ngIf="!isEdit">
    <cp-signup-footer-controls (nextClicked)="submitHomeChargerSorry()"></cp-signup-footer-controls>
  </div>
</div>

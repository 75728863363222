<p>
  {{"ChargePoint Home can be installed at your home! An installer will be in touch with you to check your home, and install the charger%asterisk%." |T:{asterisk: '*'} }}
</p>
<p>{{"Please provide your contact details:" | T}}</p>
<p>{{"Home Address" | T }}</p>
<form [formGroup]="parentForm">
  <cp-address-form [setParentForm]="parentForm" controlnamePrefix="homeCharger" [pressedSubmit]="pressedSubmit" #homeChargerAddress [needValidate]="true" [setAddress]="addressValue"></cp-address-form>

  <div class="row">
    <div class="form-group col-md-6">
        <cp-phone-w-country [parentForm]="parentForm" controlnamePrefix="homecharger" [pressedSubmit]="pressedSubmit" [phoneValue]="phoneValue" #homeChargerPhone></cp-phone-w-country>
    </div>
  </div>

  <p class="subtext">{{"* Installation complexity can vary from home to home. Your employer will cover the cost of installation up to a limit. The installer will determine if your installation is covered." | T }} </p>

  <div class="button-container text-center">
    <cp-signup-footer-controls (nextClicked)="submitAddressDetails()" (backClicked)="navigateToGetHomeCharger()" [nextButtonText]="nextButtonText"></cp-signup-footer-controls>
  </div>
</form>
<cp-loader *ngIf="isLoading"></cp-loader>

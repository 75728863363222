import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { LanguageService } from '../services/language.service';

@Pipe({
  name: 'T'
})
export class LangPipe implements PipeTransform {
  constructor(private langSvc: LanguageService){}
  transform(source: any, ...otherArgs: any[]): any {
    // TODO
    //  if (langSvc.lang != 'en-us') source = langSvc.currentLangTable[source]

    // For aguments, you have 2 choices:
    //    "%1%  %2%" | T:"hi":"you"    (replaces in-order)
    // OR  "%firstName% %lastName%" |T {firstName: "Bob", lastName: "Hope"}

    source = this.langSvc.getTranslatedValue(source);

    const len = arguments.length;
    if (len === 2 && typeof arguments[1] === 'object' && ! (arguments[1] instanceof Array)) {
      const m = arguments[1];
      Object.keys(arguments[1]).forEach(k => {
        source = source.replace(new RegExp('%' + k + '%', 'g'), m[k]);
      })
    } else {
      /**
       * if you have multiple arguments, that needs to happen in the ts file,
       * see solution in roaming-stations-overview.component.ts --> placeHolderTxtMap
       */
      for (let a = 1; a < arguments.length; a++) {
        source = source.replace(new RegExp('%' + a + '%', 'g'), arguments[a]);
      }
    }

    return source;
  }
}

@Pipe({
  name: 'N'
})
export class NumberPipe implements PipeTransform {
  constructor(private langSvc: LanguageService){}

  transform(value: any, selectedCulture: any): any {
    if (!value) {
      return;
    }

    return formatNumber(Number(value), this.langSvc.localeMain, '1.0-3');
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DiscoveryStoreService } from '../services/discovery.service';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient, private discoveryService: DiscoveryStoreService) { }

  login(user_name, user_password) {
    const httpOptions = {
      withCredentials: true,
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    const paramObj = {
      user_name: user_name,
      user_password: user_password
    };

    const params = new HttpParams({ fromObject: paramObj });

    return this.http.post(`${this.discoveryService.getNOSApiEndpoint()}index.php/users/validate`, params, httpOptions);
  }

  getForgotPasswordUrl() {
    return `${this.discoveryService.getSSOUrl()}/forgot-password`;
  }
}

<form [formGroup]="profileForm" *ngIf="fieldsConfig" (keyup.enter)='submitProfile()'>

  <div *ngIf="errorMessages.length" class="text-danger">
    <div *ngFor="let message of errorMessages" class="alert-danger error_msg" role="alert">{{message}}</div>
  </div>
  <div class="row" *ngIf="fieldsConfig.givenName">
    <div class="col-lg">
      <cp-input [setParentForm]="profileForm" [setConfig]="fieldsConfig.givenName" [pressedSubmit]="pressedSubmit" [addValidation]="true" [setFocus]="true" required="true" ></cp-input>
    </div>
    <div class="col-lg">
      <cp-input [setParentForm]="profileForm" [setConfig]="fieldsConfig.familyName" [pressedSubmit]="pressedSubmit" [addValidation]="true" required="true"></cp-input>
    </div>
  </div>
  <div *ngIf="fieldsConfig.fullName">
    <cp-input [setParentForm]="profileForm" [setConfig]="fieldsConfig.fullName" [pressedSubmit]="pressedSubmit" [addValidation]="true" required="true"></cp-input>
  </div>

  <cp-user-email [setParentForm]="profileForm" [setConfig]="fieldsConfig.email" [showLoginPrompt]="showLoginPrompt"  [pressedSubmit]="pressedSubmit" [addValidation]="true" [setFocus]="!fieldsConfig.givenName" required="true"></cp-user-email>

  <div *ngIf="fieldsConfig.username">
    <cp-input [setParentForm]="profileForm" [setConfig]="fieldsConfig.username" [pressedSubmit]="pressedSubmit" [addValidation]="true" required="true"></cp-input>
    <cp-user-password [setParentForm]="profileForm" [setConfig]="fieldsConfig.password" [pressedSubmit]="pressedSubmit" [addValidation]="true" required="true"></cp-user-password>
  </div>

  <cp-address-form  [showAllNosletCountries]=showAllNosletCountries [setParentForm]="profileForm" [setAddress]="countryValue" (onLoadConfiguration)="loadConfiguration($event)" (countryChange)="setCountryId($event)" [setConfig]="fieldsConfig.address.fields" [pressedSubmit]="pressedSubmit" [needValidate]="true"></cp-address-form>

  <!--- privacy policy -->
  <p class="termsconditions">
    <input 
      formControlName="privacyPolicyAccept"
      type="checkbox" 
      aria-describedby="agreementsError"
      required
      id="termsconditions"
      />
    <label for="termsconditions" [innerHtml]="'I agree to the %right% and %left%.'  | T : {
      right: remainingAgreementLinks,
      left: firstAgreementLink
    }" ></label>
    
  </p>
  <span *ngIf="shouldDisplayTandCError()" class="has-error" id="agreementsError">
    {{"errorMsgRequiredTermsAndConditions" | T }}
  </span>

  <cp-signup-footer-controls [showBackButton] = 'showBackButton'  (nextClicked)="submitProfile()" focusOnError="true" ></cp-signup-footer-controls>
  <p *ngIf="!showLoginPrompt" id="already-have-account-container">
    {{'Already have an account?' | T}} <a href="{{ssoUrl}}">{{'Log In' | T }}</a>
  </p>
</form>
<cp-loader *ngIf="isLoading"></cp-loader>

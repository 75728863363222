import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AriaAnnouncementService } from '../services/aria-announcement.service';


@Injectable({
  providedIn: 'root'
})
export class AriaAnnouncementGuard implements CanActivate {

  constructor(private announcementService: AriaAnnouncementService) { }
  /**
   * 
   * guard that reads the announcement content from the route and sets it in the service, then continues to the route
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const announcementContent = this.getAnnouncementContent(route);
      this.announcementService.queueAnnouncement(announcementContent); // put the announcement in the queue, so it can be read after the route is loaded and focus is set. 
      if (this.shouldTriggerImmediately(route)) {
        this.announcementService.anounceQueuedAnnouncement();
      }
      return true;
  }
  
  /**
   * method that gets the announcement content from the route or return an empty string
   */
  private getAnnouncementContent(route: ActivatedRouteSnapshot): string {
    if (route.data.ariaAnnouncementKey) {
      return route.data.ariaAnnouncementKey;
    }
    return '';
  }

  private shouldTriggerImmediately(route: ActivatedRouteSnapshot): boolean {
    return !!route.data.ariaTriggerImmediately;
  }
}

export const defaultBodyTitle = 'Sign Up';

export const daimlerProfileTitle = 'Create a profile';

export const daimlerAccount = 'Activate your Mercedes me Charge account';

export const daimlerPayment = 'Add a payment method';

export const daimlerSplashTitle = 'Getting Started';

export const THANK_YOU = 'Thank you';

export const successTitle = 'All Done!';

export const cardTitle = 'How do you want to charge?';

export const daimlerCardTitle = 'Activate your Mercedes me Charge card';

export const ADD_ANOTHER_CARD = 'Add another card';

export const daimlerAddAnotherCard = 'Add another card. You can always add a card to your account later.';

export const notificationsTitle =  'Notifications';

export const whatAreYouDrivingTitle = 'What are you driving?';

export const addPaymentTitle = 'Add Payment';

export const homeChargerTitle = 'Home Charger';

export const daimlerDontHaveCard = {
  // tslint:disable-next-line:quotemark
  label: "Don’t have a card?",
  // content based on country id or default
  content: {
    "40" : {
      text : "If you need a card you may order it through the Driver Portal once your account is created. For additional assistance please call Mercedes-Benz 24/7 (%phoneNumber%) or contact your local dealer.",
      phoneCallLetters: null,
      phoneNumber: '1-800-387-0100'
    },
    default: {
      text : "If you need a card you may order it through the Driver Portal once your account is created. For additional assistance please call Mercedes-Benz 24/7 %phoneCallLetters% (%phoneNumber%) or contact your local dealer.",
      phoneCallLetters: '1-800-FOR-MERCEDES',
      phoneNumber: '1-800-367-6372'
    }
  }
};

export const VERIFY_ACCOUNT = 'Verify Account';

import {findLocaleFromLocalStorage} from '../../localstorage';
import {Injectable} from '@angular/core';
import {catchError, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, of, Subject, Subscription} from 'rxjs';
import {registerLocaleData} from '@angular/common';
import localeDE from '@angular/common/locales/de';
import localeES from '@angular/common/locales/es';
import localeES_LA from '@angular/common/locales/es-419';
import localeFR from '@angular/common/locales/fr';
import localeIT from '@angular/common/locales/it';
import localeNL from '@angular/common/locales/nl';
import localeSV from '@angular/common/locales/sv';
import localeNB from '@angular/common/locales/nb';
import localeDA from '@angular/common/locales/da';
import localePT from '@angular/common/locales/pt';
import localePL from '@angular/common/locales/pl';
import localeCS from '@angular/common/locales/cs';
import * as Sentry from '@sentry/browser';


registerLocaleData(localeDE, 'de');
registerLocaleData(localeES, 'es');
registerLocaleData(localeES_LA, 'es-LA');
registerLocaleData(localeFR, 'fr');
registerLocaleData(localeIT, 'it');
registerLocaleData(localeNL, 'nl');
registerLocaleData(localeSV, 'sv');
registerLocaleData(localeNB, 'nb');
registerLocaleData(localeDA, 'da');
registerLocaleData(localePT, 'pt');
registerLocaleData(localePL, 'pl');
registerLocaleData(localeCS, 'cs');

@Injectable({
  providedIn: 'root'
})

export class LanguageService {

  private langMap: Map<string, any> = new Map();
  private locale: string;

  private loadSubscription = new Subscription();
  private configSubscription = new Subscription();
  private loadSubject = new Subject();
  public langChanged = new BehaviorSubject(null);
  public localeEmmiter =  new BehaviorSubject(null);

  private supportedLocales: Map<string, string> = new Map([
    ['en-GB', 'en-GB'],
    ['en-AU', 'en-GB'],
    ['en-NZ', 'en-GB'],
    ['fr-CA', 'fr-CA'],
    ['en-CA', 'en-CA'], // language won't be loaded, but locale can be used for other Intl
    ['es-LA', 'es-LA'],
    ['en', 'en-US'],
    ['fr', 'fr-FR'],
    ['es', 'es-ES'],
    ['de', 'de-DE'],
    ['it', 'it-IT'],
    ['nl', 'nl-NL'],
    ['sv', 'sv-SE'],
    ['nb', 'nb-NO'],
    ['da', 'da-DK'],
    ['pt', 'pt-PT'],
    ['pl', 'pl-PL'],
    ['cs', 'cs-CZ']
  ]);

  localeMain = 'en';
  private readonly FALLBACK_LOCALE = 'en-US'; // if all else fails, use this locale

  constructor(private http: HttpClient) {
    this.initLanguage();
  }

  private initLanguage(): void {
    this.updateLanguage(this.determineUserLocale());
  }

  /**
 * Finds the user's preferred locale based on the following order of priority:
 * 1. Locale specified in the URL query string (e.g. ?locale=fr-FR)
 * 2. Locale saved in local storage
 * 3. Browser's default language setting
 * 4. Fallback locale (en-US)
 *
 * @returns The user's preferred locale as a string.
 */
  determineUserLocale(): string {
     // fallback en-US
    return this.findLocaleFromUrl(window.location.search)
        || findLocaleFromLocalStorage()
        || this.findLocaleFromBrowser()
        || this.FALLBACK_LOCALE;
  }

  findLocaleFromUrl(url: string): string | null {
    const urlSearchParams = new URLSearchParams(url);
    if (urlSearchParams.has('locale')) {
      return urlSearchParams.get('locale');
    }
    return null;
  }

  findLocaleFromBrowser(): string | null {
    const locale = navigator.language;
    return this.getSupportedLocale(locale);
  }

  getLocale() {
    return this.locale;
  }

  setLocale(locale: string) {
    this.locale = this.getSupportedLocale(locale);
    this.localeMain = this.locale.split('-')[0];
    this.localeEmmiter.next(locale);
  }

  getTranslationsMap(): Promise<any> {
    if (!this.locale) {
      this.configSubscription.unsubscribe();
      this.configSubscription = this.localeEmmiter
        .subscribe((lang: any) => {
          if (!lang) {
            return;
          }
          this.setLocale(lang);
          this.loadLang(this.locale);
        });
    } else {
      this.loadLang(this.locale);
    }

    return this.loadSubject.asObservable().toPromise();
  }

  loadLang(locale: string) {
    this.loadSubscription.unsubscribe();
    this.loadSubscription = this.http.get('/assets/lang/' + locale + '.json').pipe(
      catchError(() => {
        // Handle error case by switching to the en-US lang file
        return this.http.get('/assets/lang/en-US.json');
      }),
      switchMap(translationObj => {
        // Process the translation object
        Object.keys(translationObj).forEach(key => {
          // remove line breaks and spaces
          this.langMap.set(key.replace(/(\r\n|\n|\r|\s)/gm, ''), translationObj[key]);
        });
        this.loadSubject.complete();
        this.langChanged.next(true);
        return of(translationObj);
      })
    ).subscribe();
  }

  getTranslatedValue(key: string) {
    if (this.langMap) {
      const translated = this.langMap.get(key.replace(/(\r\n|\n|\r|\s)/gm, ''));
      return (translated) ? translated : key;
    } else {
      return key;
    }
  }

  updateLanguage(locale: string) {
    if (locale === this.locale) {
      return; // do nothing. This shouldn't happen.
    }
    this.setLocale(locale);
    Sentry.setTag('locale', this.locale);
    this.loadLang(this.locale);
  }

  getSupportedLocale(locale: string) {
    if (this.supportedLocales.has(locale)) {
      return this.supportedLocales.get(locale);
    }

    // iOS can set only 'de' in webview
    const mainLocale = locale.substring(0, 2).toLowerCase();
    if (this.supportedLocales.has(mainLocale)) {
      return this.supportedLocales.get(mainLocale);
    }

    return 'en-US';
  }
}

export function translationLoader(loader: LanguageService) {
  return () => loader.getTranslationsMap();
}

import { Component, OnInit, OnChanges, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { GetHomeChargerService } from './get-home-charger.service';
import { GetHMChrgStatus } from './models';
import { CPMixPanel } from '../services/mixpanel.service';
import { FrontendContentConfigService } from '../services/frontend-content-config.service';
import { CONTINUE, NEXT } from '../cp-common/signup-footer-controls/translate';
import { LangPipe } from '../pipes/lang.pipe';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'cp-get-home-charger',
  templateUrl: './get-home-charger.component.html',
  styleUrls: ['./get-home-charger.component.scss']
})
export class GetHomeChargerComponent implements OnInit, OnChanges {
  parkedCarForm;
  wpark;
  componentId = 'home-charger';
  isLoggedIn = false;
  isEdit = true;
  companyName = '';
  connectionName = '';
  isLoading = false;
  isMobile = false;
  showBackButton = false;
  nextButtonText = '';

  constructor(
    protected getHomeChargerSvc: GetHomeChargerService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected ref: ChangeDetectorRef,
    protected mixPanel: CPMixPanel,
    protected frontendConfigSvc: FrontendContentConfigService,
    protected languageSvc: LanguageService

  ) {
    this.init();
  }

  ngOnChanges() {
    this.ref.detectChanges();
  }

  ngOnInit() {
    let allowRedirect = true;
    this.isLoading = true;
    this.activatedRoute.params.subscribe(params => {
      if (params['allowRedirect']) {
        allowRedirect = params['allowRedirect'] === 'true';
      }
    });

    this.parkedCarForm = this.getHomeChargerSvc.homeChargerFormGroup;
    this.wpark = this.parkedCarForm.get('whereIsCarParked');
    this.getHomeChargerSvc.getHomeChargerStatus().subscribe(statusObj => {
      if (statusObj.companyName) {
        this.companyName = statusObj.companyName;
        this.connectionName = statusObj.connectionName;
      }
      if (allowRedirect && statusObj.status) {
        let navigateLink = '../home-charger-done';
        if (statusObj.address && !statusObj.address.address1) {
          navigateLink = '../get-home-charger-sorry';
        }

        switch (statusObj.status) {
          case GetHMChrgStatus.INSTALLED:
            this.router.navigate([navigateLink], {
              relativeTo: this.activatedRoute
            });
            break;
          case GetHMChrgStatus.PENDING:
            this.router.navigate([navigateLink], {
              relativeTo: this.activatedRoute
            });
            break;
          case GetHMChrgStatus.NOT_SCHEDULED:
            this.router.navigate([navigateLink], {
              relativeTo: this.activatedRoute
            });
            break;
          case GetHMChrgStatus.DECLINED:
            break;
        }
      }
      this.isLoading = false;
    });
  }

  init() {
    const langpipe = new LangPipe(this.languageSvc);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.indexOf('webview') !== -1) {
          this.isMobile = true;
          this.showBackButton = false;
          this.nextButtonText = langpipe.transform(CONTINUE);
        }
        if (event.url.indexOf('signup') !== -1) {
          this.isEdit = false;
          this.showBackButton = true;
          this.frontendConfigSvc.setBodyTitle('homeChargerTitle');
          this.nextButtonText = langpipe.transform(NEXT);
        } else {
          this.frontendConfigSvc.setBodyTitle('splashTitle');
        }
      }
    });
  }

  submitWhereParked() {
    if (this.parkedCarForm.controls.whereIsCarParked.valid) {
      this.mixPanel.homeChargerContinue({choice: this.parkedCarForm.get('whereIsCarParked').value});
      if (this.parkedCarForm.get('whereIsCarParked').value === 'garage') {
        // show address
        this.router.navigate(['../home-charger-address'], {
          relativeTo: this.activatedRoute
        });
      } else {
        this.router.navigate(['../get-home-charger-sorry'], {
          relativeTo: this.activatedRoute
        });
      }
    } else {
      this.parkedCarForm.get('whereIsCarParked').markAsTouched();
    }
  }

  submitNoChargerNeeded(e) {
    this.mixPanel.dontNeedHC();
    this.getHomeChargerSvc.iDontNeedHomeCharger();
    e.preventDefault();
    e.stopPropagation();
  }
}

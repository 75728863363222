import { Component } from '@angular/core';
import { HomeChargerAddressComponent } from '../../home-charger-address/home-charger-address.component';
import { LangPipe } from '../../pipes/lang.pipe';
import { DONE } from '../../cp-common/signup-footer-controls/translate';


@Component({
  selector: 'cp-connection-home-charger-address',
  templateUrl: '../../home-charger-address/home-charger-address.component.html',
  styleUrls: ['../../home-charger-address/home-charger-address.component.scss']
})
export class ConnectionHomeChargerAddressComponent extends HomeChargerAddressComponent {
  init() {
    const langPipe = new LangPipe(this.langSvc);
    this.nextButtonText = langPipe.transform(DONE);
    this.frontendConfigSvc.setBodyTitle('homeChargerTitle');
  }
}

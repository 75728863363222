<cp-loader *ngIf="isLoading"></cp-loader>
<div *ngIf="!isLoading">
  <div>
    <p class="head-blurb">
      {{"%connectionName% provides you a ChargePoint Home to charge at home. Let’s check if it can be installed at your home." | T:{connectionName:connectionName} }}
    </p>
    <div class="text-center">
      <img src="/assets/images/ic_alfen_homestation.svg" class="home-charger-img"/>
    </div>
  </div>
  <form [formGroup]="parkedCarForm" (ngSubmit)="submitWhereParked()">
    <div class="where-parked-container">
      <div>
        <p>{{"Where will you park your Electric Vehicle?" |T}}</p>
        <div>
          <div class="custom-control custom-radio">
            <input type="radio" id="driveway" class="custom-control-input" formControlName="whereIsCarParked" value="garage"/>
            <label class="custom-control-label" for="driveway">{{"In a personal garage or driveway" |T}}</label>
          </div>
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" id="parking-spot" formControlName="whereIsCarParked" value="parking-spot"/>
            <label class="custom-control-label" for="parking-spot">{{"In a parking spot in an apartment building" |T}}</label>
          </div>
          <div class="custom-control custom-radio">
            <input type="radio" id="on-street" class="custom-control-input" formControlName="whereIsCarParked" value="onstreet"/>
            <label class="custom-control-label" for="on-street">{{"On the street" |T }}</label>
          </div>
        </div>

        <div *ngIf="parkedCarForm.get('whereIsCarParked').invalid && parkedCarForm.get('whereIsCarParked').touched" class="alert alert-danger">
          <div *ngIf="parkedCarForm.get('whereIsCarParked').errors.required">
            {{"Field is required." |T}}
          </div>
        </div>

        <div class="text-center isloggedInControl" *ngIf="!isEdit && !isMobile">
          <a href="#" (click)="submitNoChargerNeeded($event)" class="text-center">{{"I don’t need a home charger" |T }}</a>
        </div>
      </div>
    </div>
    <cp-signup-footer-controls (nextClicked)="submitWhereParked()" [showBackButton]='showBackButton' [nextButtonText]="nextButtonText"></cp-signup-footer-controls>
  </form>
</div>

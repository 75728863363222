import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignupFooterControlsComponent } from './signup-footer-controls/signup-footer-controls.component';
import { LanguageModule } from '../language/language.module';
import { SharedDirectiveModule } from '../shared-module/shared-module.module';


@NgModule({
  imports: [
    CommonModule,
    LanguageModule,
    SharedDirectiveModule
  ],
  exports: [
      SignupFooterControlsComponent
  ],
  declarations: [SignupFooterControlsComponent]
})
export class CpCommonModule { }

import { Component, OnInit } from '@angular/core';
import { FrontendContentConfigService } from 'src/app/services/frontend-content-config.service';
import { CommunicationService } from 'src/app/services/communication.service';

@Component({
  selector: 'cp-daimler-splash',
  templateUrl: './daimler-splash.component.html',
  styleUrls: ['./daimler-splash.component.scss']
})
export class DaimlerSplashComponent implements OnInit {
  initialBalance: string;
  dcPorts: number;

  constructor(private frontendConfigSvc: FrontendContentConfigService, private commSvc: CommunicationService) {}

  ngOnInit() {
    this.frontendConfigSvc.setBodyTitle('splashTitle');
    this.commSvc.getComponentDefinition('splash').subscribe(comp => {
      if (comp) {
        comp.fields.forEach(field => {
          this[field.id] = field.value;
        });
      }
    });
  }
}

import { Component } from '@angular/core';
import { routeSlide } from '../animations/animations';
import { BodyComponent } from '../body/body.component';
import { INVALID_CONNECTION } from './translate';
import { redirectToDriverPortal } from '../utils';
import { catchError, finalize } from 'rxjs/operators';
import { GENERAL_SERVER_ERROR } from '../services/translation-files/translate';
import { of } from 'rxjs';


@Component({
  selector: 'cp-connection-body',
  templateUrl: '../body/body.component.html',
  styleUrls: ['../body/body.component.scss'],
  animations: [routeSlide]
})
/**
 * Loads the driver connection configuration for Leasco type connections.
 * If the partner token is missing, redirects to the driver portal.
 * If the configuration is invalid, displays an error message and redirects to the driver portal.
 */
export class ConnectionBodyComponent extends BodyComponent {

  loadConfiguration() {

    const { partnerToken, urlSearchParams } = this.comSvc.getMasterObject();

    if (!partnerToken) {
      redirectToDriverPortal();
      return;
    }

    // If the partner token is present, load the driver connection configuration.
    this.stateSvc.setDriverConnectionStarted();
    this.uxConfigService.getDriverConnectionConfiguration(
      partnerToken,
      urlSearchParams
    ).pipe(
        catchError(() => {
          this.serverErrorMessage = this.l10n.transform(GENERAL_SERVER_ERROR);
          return of(false);
        }),
        finalize(() => this.loading = false)
    ).subscribe(data => {
      if (!data.components || data.components.length === 0
        || data.components[0].componentId === 'profile') {
        // If the configuration is invalid, display an error message and redirect to the driver portal.
        this.serverErrorMessage = this.l10n.transform(INVALID_CONNECTION);
        redirectToDriverPortal();
        return;
      }
      // If the configuration is valid, set the UX configuration.
      this.stateSvc.setUXConfig(data);
    });
  }
}

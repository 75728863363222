import { Injectable } from '@angular/core';
import { UiFlowStateService } from './ui-flow-state.service';
import { Vehicle } from './models/backend-save-classes';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Country } from './models/address-with-zip';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  private sessionToken: string;

  constructor(private uiFlowState: UiFlowStateService) {
    this.getComponentDefinition('session').subscribe((sessionComponent) => {
      if (sessionComponent && sessionComponent.fields) {
        sessionComponent.fields.forEach((field) => {
          if (field.id === 'sessionId' && field.value) {
            this.sessionToken = field.value;
          }
        });
      }
    });
  }

  submitFormFields(objValue, backendSaveObj?) {
    this.pushToMaster(objValue, backendSaveObj);
    this.uiFlowState.nextRoute();
  }

  pushToMaster(objValue, backendSaveObj?) {
    this.uiFlowState.updateSignUpData(objValue);
    this.uiFlowState.saveBackendData(backendSaveObj);
    this.uiFlowState.saveToSessionStorage(backendSaveObj);
  }

  skipSubmitNextRoute() {
    this.uiFlowState.nextRoute();
  }

  getMasterObject() {
    return this.uiFlowState.getMasterObject();
  }

  getMainAddress() {
    return this.uiFlowState.getMainAddress();
  }
  setMainAddress(address) {
    this.uiFlowState.setMainAddress(address);
  }

  getPartnerToken() {
    return this.uiFlowState.getMasterObject().partnerToken;
  }

  getFirstName() {
    if (this.uiFlowState.getMasterObject().firstname) {
      return this.uiFlowState.getMasterObject().firstname;
    } else {
      const fullName = this.uiFlowState.getMasterObject().fullName.split(' ');
      return fullName[0];
    }
  }

  getLastComponent() {
    return this.uiFlowState.getMasterObject().lastComponent;
  }

  getPhoneNumber() {
    return this.uiFlowState.getMasterObject()._phone;
  }

  isInstallHomeCharger() {
    return ('installHomeCharger' in this.uiFlowState.getMasterObject()) ? this.uiFlowState.getMasterObject().installHomeCharger : false;
  }

  getEV() {
    if (this.uiFlowState.getMasterObject().makeId) {
      return new Vehicle(this.uiFlowState.getMasterObject());
    } else {
      return new Vehicle({colorId: '', makeId: '', modelId: '', year: ''});
    }
  }

  isHaveNotEV() {
    if (this.uiFlowState.getMasterObject().hasOwnProperty('I-dont-own-ev')) {
      return this.uiFlowState.getMasterObject()['I-dont-own-ev'];
    } else {
      return null;
    }
  }

  navToLastComponent() {
    this.uiFlowState.navigateToLast();
  }

  getComponentDefinition(componentId: string) {
    return this.uiFlowState.$configObs.pipe(
      map( (config) => {
        if (config) {
          return config.components.find(
            (component) => {
              return component.componentId === componentId;
            }
          );
        } else {
          return null;
        }

      }
    ));
  }

  getOrgConfigName() {
    return this.uiFlowState.$configObs.pipe(
      map(
        (config) => {
          return config.orgConfig.name;
        }
      )
    );
  }

  pushToBackend(backendSaveObj: any, component = null) {
    this.uiFlowState.saveBackendData(backendSaveObj);
    return this.uiFlowState.submitAllData(component);
  }

  getViewAccountLink(): string {
    if (this.uiFlowState.getMasterObject().urlSearchParams.redirect) {
      return this.uiFlowState.getMasterObject().urlSearchParams.redirect;
    } else {
      return environment.DRIVER_PORTAL_URL;
    }
  }

  getDriverCountry(): Country {
    return this.uiFlowState.getMasterObject().selectedCountryObj;
  }
  getDenPartnerSubdomain() {
    return this.uiFlowState.getMasterObject().urlSearchParams.subdomain;
  }

  getSessionToken() {
    return this.sessionToken;
  }
}

<form [formGroup]="promoForm" *ngIf="isModalOpen" cpFocustrap>
  <!-- Promo Modal -->
  <div class="modal modal-open fade" 
    [class.show]="isModalOpen"
    id="promoModal" 
    tabindex="-1" 
    role="dialog" 
    aria-labelledby="promoModalLabel" 
    aria-modal="true" 
    [class.modal-block]="isModalOpen" 
    data-close="true" 
    (click)="modalToggle($event)"
    cpFocustrap
    >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="promoModalLabel"> {{ "Promo Code" | T}}</h5>
          <button type="button" class="btn-close" data-dismiss="modal" [attr.aria-label]="'Close' | T" data-close="true">
          </button>
        </div>
        <div class="modal-body" *ngIf="!showProgramPopup">
          <div class="has-error text-danger needpadding" role="alert" *ngIf="responsePromo.errorMessage">
            <p class="">{{responsePromo.errorMessage}}</p>
          </div>
          <!-- Input Field Modal -->
          <div class="form-group">
            <input type="text" data-private="lipsum" class="form-control" aria-labelledby="promoModalLabel" aria-describedby="promo_error" formControlName="promo" #promoCode [class.invalid]="updateErrorPromo()" required="true">
            <div *ngIf="updateErrorPromo()" class="has-error">
              <div *ngIf="promoForm.get('promo').errors.required" id="promo_error">
                {{"errorMsgRequiredField" | T: {fieldName: "Promo Code" | T} }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" *ngIf="!showProgramPopup">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" data-close="true">{{"Close" | T }}</button>
          <!-- Apply -->
          <button  cpAppFocusOnError="true" type="button" class="btn btn-primary" (click)="checkPromo()" >{{"Apply" | T }}</button>
        </div>
        <div class="modal-body modal-iframe" *ngIf="showProgramPopup">
          <iframe id="program_view" [src]="programLink" style="height: 500px;width: 100%;border: none;"></iframe>
        </div>
      </div>
    </div>
  </div>
</form>

<b class="info-label" *ngIf="showSubHeader"> {{"Get ready to charge" | T}}</b>

<p class="head-blurb" >
  {{getHeaderParagraph()}}
</p>


<form [formGroup]="payForm">
  <div *ngIf="ccLast4" class='show-payment-link-container' >
    <p>{{"Credit Card(...%1%)" | T : ccLast4}}</p>
    <a href="#" (click)="showPaymentForm(); $event.preventDefault()">{{"Change payment" | T}}</a>
  </div>
<div *ngIf="!ccLast4">
  <div *ngIf="invalidCreditCardCode" class="text-danger">
    <div *ngIf="invalidCreditCardCode == 18" class="text-danger error_msg">{{"Credit card number is invalid." | T }}</div>
    <div *ngIf="invalidCreditCardCode == 41" class="text-danger error_msg">{{"Card type is invalid." | T }}</div>
    <div *ngIf="invalidCreditCardCode == 429" class="text-danger error_msg">{{"Too many attempts." | T }}</div>
    <div *ngIf="invalidCreditCardCode == 504" class="text-danger error_msg">{{"Credit card verification canceled. Please try again." | T }}</div>
    <div *ngIf="invalidCreditCardCode == 401" class="text-danger error_msg">{{"Credit card verification failed. To resolve this issue, please contact the credit card issuer." | T }}</div>
    <div *ngIf="[18,41,429,504,401].indexOf(invalidCreditCardCode) === -1" class="alert-danger error_msg">{{"Credit card verification failed. Please try again later." | T }}</div>
  </div>
  <div class="form-group">
    <div class="numberRow">
      <input type="text" 
      class="form-control"
      data-private="lipsum"
      formControlName="creditcard"
      #creditCard 
      placeholder="{{'Credit Card' | T }}" 
      (keypress)="isNumber($event)"
      (keydown.enter)="submitProfile()"
      maxlength="19"  
      [class.invalid]="updateError('creditcard')"
      [attr.aria-label]="payForm.get('creditcard')['translatedLabel']"
      aria-describedby="credit-card-error"
      required="true"
      (input)="changeCreditCardNumber($event)"
      />
      <img [src]="ccTypeUrl" [attr.alt]="ccTypeAltText" />
    </div>
    <div *ngIf="updateError('creditcard')" class="has-error">
      <div *ngIf="payForm.get('creditcard').errors.required" id="credit-card-error">
        {{"errorMsgRequiredField" | T: {fieldName: payForm.get('creditcard')['translatedLabel']} }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg">
      <div class="form-group">
        <input 
          type="text" 
          class="form-control" 
          data-private="lipsum" 
          formControlName="expirationdate" 
          placeholder="{{'MM/YY' | T}}" 
          (keypress)="isNumber($event)" 
          (keyup)="expirationSlash($event)"
          (keydown.enter)="submitProfile()"
          maxlength="5"  
          [class.invalid]="updateError('expirationdate')"
          [attr.aria-label]="payForm.get('expirationdate')['translatedLabel']"
          aria-describedby="expiration-date-error"
          required="true"
          />
        <div *ngIf="updateError('expirationdate')" class="has-error">
          <div *ngIf="payForm.get('expirationdate').errors.required" id="expiration-date-error">
            {{"errorMsgRequiredField" | T: {fieldName: payForm.get('expirationdate')['translatedLabel']} }}
          </div>
          <div *ngIf="payForm.get('expirationdate').errors.invalid" id="expiration-date-error">
            {{"Invalid expiration date." | T }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg">
      <div class="form-group">
        <input type="text" 
          class="form-control" 
          data-private="lipsum" 
          formControlName="cvv" 
          placeholder="CVV" 
          (keypress)="isNumber($event)"
          (keydown.enter)="submitProfile()"
          maxlength="4"  
          [class.invalid]="updateError('cvv')"
          [attr.aria-label]="payForm.get('cvv')['translatedLabel']"
          aria-describedby="cvv-error"
          required="true"
          />
        <div *ngIf="updateError('cvv')" class="has-error">
          <div *ngIf="payForm.get('cvv').errors.required" id="cvv-error">
            {{"errorMsgRequiredField" | T: {fieldName: payForm.get('cvv')['translatedLabel']} }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg">
      <div class="form-group">
        <input type="text" 
          class="form-control" 
          data-private="lipsum" 
          formControlName="postalcode" 
          placeholder="{{'Postal Code' | T }}"
          (keydown.enter)="handleEnterKey($event)"
          [class.invalid]="updateError('postalcode')"
          [attr.aria-label]="payForm.get('postalcode')['translatedLabel']"
          aria-describedby="postal-code-error"
          required="true"
          />
        <div *ngIf="updateError('postalcode')" class="has-error">
          <div *ngIf="payForm.get('postalcode').errors.required" id="postal-code-error">
            {{"errorMsgRequiredField" | T: {fieldName: payForm.get('postalcode')['translatedErrorLabel']} }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- or -->
  <div class="row justify-content-center payment-or">
    <p class="payment-or-separator text-center">{{"OR" | T }}</p>
  </div>
  <!-- Paypal -->
  <div class="form-group">
    <div class="row">
      <div class="col-lg">
          <button type="button" class=" btn-lg btn-paypal form-control" (click)="submitPaypal()" [attr.aria-label]="'paypalButtonLabel' | T" ></button>
      </div>
    </div>
  </div>
</div>
<!-- end input form-->
  <!-- Promo -->
  <div *ngIf='showPromoCode'>
    <div class="form-group row justify-content-center" *ngIf="!acceptedPromo">
      <button class="btn btn-link" type="button" (click)="openModal($event)" data-toggle="modal" data-target="#promoModal" >{{"I have a Promo Code" | T }}</button>
    </div>
    <div class="row flex-col" *ngIf="acceptedPromo">
        <p>{{"Promo Code:" | T }} {{responsePromo.code}}</p>
        <p>{{responsePromo.message}}</p>
    </div>
  </div>
  <div class='no-promo-space' *ngIf='!showPromoCode'></div>
  <cp-signup-footer-controls 
    [backButtonPreventDefault]='backButtonPreventDefault' 
    (nextClicked)="submitProfile()"
    (backClicked)="cancelClicked()" 
    [nextButtonText]="nextButtonText"
    [backButtonText]="backButtonText" [showBackButton]="showBackButton || showCancelButton" 
    focusOnError="true"></cp-signup-footer-controls>

  <!-- "skip fo now" Option -->
<div *ngIf="optional">
    <div class="form-group row justify-content-center">
      <button class="btn btn-link" (click)="skipSubmit()" (enter)="skipSubmit()">
          {{"Skip for now" | T }}
      </button>
    </div>
  </div>
</form>
<div *ngIf="isModalOpen" class="modal-backdrop fade show"></div>
<cp-loader *ngIf="isLoading"></cp-loader>

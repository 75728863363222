<p> {{ "Now that you are all set, get a text to download the app." | T}}</p>
<form>
  <cp-phone-w-country-simple #phoneWCountry [countryId]="countryId"
    [phoneNumber]="phoneNumber"></cp-phone-w-country-simple>
  <div *ngIf="serverErrorMessage" class="text-danger" role="alert">
    {{serverErrorMessage}}
  </div>
  <cp-signup-footer-controls [showBackButton]="false" (nextClicked)="onSubmitForm()" [nextButtonText]="'Send Text' | T"
    focusOnError="true"></cp-signup-footer-controls>
</form>
<a [href]="driverAccountURL">{{"View Account" | T}}</a>
<div *ngIf="this.fieldsLoaded">

  <p class="head-blurb">
    {{"An administrator from %orgName% will verify your account to provide you with the charging benefits from %connectionName%."|T: {orgName:this.connectionFields.orgName,connectionName:this.connectionFields.connectionName} }}
  </p>

  <form [formGroup]="connectionForm">
    <div class="form-group" *ngFor="let fields of this.connectionFields.connectionFields">
        <input type="text" class="form-control" data-private="lipsum" formControlName={{fields.key}} placeholder="{{fields.name}}" [class.invalid]="updateError(fields.key)" required="true">
        <div *ngIf="updateError(fields.key)" class="has-error">
          <div *ngIf="connectionForm.get(fields.key).errors.required" class="has-error">
                {{"This field is required" | T}}
          </div>
        </div>

    </div>

    <p>

      <input type="checkbox" formControlName="chargepointPolicyAccept">
      <!---using inner html instead of string concatenation-->
      <span (click)='openTermsDialog($event, this.connectionFields.serviceTcLink)' (contextmenu)="openTermsDialog($event, this.connectionFields.serviceTcLink)" [innerHtml] = "' I agree to the %companyName% <a href=\'#\'>Terms & Conditions</a>' | T : {
        companyName: this.connectionFields.serviceTcTitle
      }  "></span>


    </p>

    <p *ngIf="this.connectionFields.companyTcLink">

      <input type="checkbox" formControlName="companyPolicyAccept">
      <!---using inner html instead of string concatenation-->
      <span (click)='openTermsDialog($event, this.connectionFields.companyTcLink)' (contextmenu)="openTermsDialog($event, this.connectionFields.companyTcLink)" [innerHtml] = "' I agree to the %companyName% <a href=\'#\'>Terms & Conditions</a>' | T : {
        companyName: this.connectionFields.orgName
      }  "></span>


    </p>

      <!--- Submit form -->
      <div class="button-container">
          <button type="submit" class="btn btn-lg btn-primary" (click)="submitConnection()" [disabled]="continueError()" >{{"Continue" | T}}</button>
      </div>


  </form>
</div>

<div *ngIf="errorToken" class="has-error">
  {{"An error has occurred, please contact your company’s administrator" | T}}
</div>
<cp-terms-dialog [content]="termsText" *ngIf="showTermsModal" [openModal]="showTermsModal"></cp-terms-dialog>
<cp-loader *ngIf="isLoading"></cp-loader>

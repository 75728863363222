import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { COMPONENT_CONFIG } from './models/default-config';
import { FIELD_PLACEHOLDER } from './models/translate';
import { FormGroup, FormArray, Validators } from '@angular/forms';
import { LanguageService } from './language.service';
import { findLocaleFromLocalStorage } from 'src/localstorage';
import { DiscoveryStoreService } from './discovery.service';


@Injectable({
  providedIn: 'root'
})
export class DriverSignupUXService {
  componentConfig = [];
  private signUpConfiguration;
  private observable: Observable<any>;

  constructor(
    private httpClient: HttpClient,
    private discoveryService: DiscoveryStoreService,
    private langSvc: LanguageService
  ) {
  }

  getDriverConnectionConfiguration(token: string, getParams: {} = {}) {
    let paramString = `token=${token}`;
    for (const [name, value] of Object.entries(getParams)) {
      paramString = paramString + `&${name}=${value}`;
    }

    if (this.signUpConfiguration) {
      return of(this.signUpConfiguration);
    } else if (this.observable) {
      return this.observable;
    } else {
      this.observable =  this.httpClient.get(
        `${this.discoveryService.getAccountV2ApiEndpoint()}/driver/profile/connection/configuration?${paramString}`,
        {
          observe: 'response',
          withCredentials: true
        }
      ).pipe(
        retry(3),
        map(response => {
          this.observable = null;
          if (response.status !== 200) {
            const configObj = window.sessionStorage.getItem('connectionConfiguration');
            if (!configObj) {
              throw throwError('No Config');
            }
            this.signUpConfiguration = configObj ? JSON.parse(configObj) : {};
          } else if (response.status === 200) {
            this.signUpConfiguration = response.body;
            if (this.signUpConfiguration.errorId || this.signUpConfiguration.errorMessage) {
              this.signUpConfiguration = null;
              throw throwError('No Config');
            }
            window.sessionStorage.setItem('connectionConfiguration', JSON.stringify(this.signUpConfiguration));
          }
          this.initConfiguration(this.signUpConfiguration);
          return this.signUpConfiguration;
        }),
        catchError(() => {
          this.observable = null;
          const configObj = window.sessionStorage.getItem('connectionConfiguration');
          if (!configObj) {
            throw throwError('No Config');
          }
          this.signUpConfiguration = configObj ? JSON.parse(configObj) : {};
          this.initConfiguration(this.signUpConfiguration);
          return of(this.signUpConfiguration);
        })
      );

      return this.observable;
    }
  }

  getDriverSignupConfiguration(token?: string, getParams: {} = {}) {
    if (this.signUpConfiguration) {
      return of(this.signUpConfiguration);
    } else if (this.observable) {
      return this.observable;
    } else {
      return this.loadDriverSignUpConfiguration(token, getParams);
    }
  }

  loadDriverSignUpConfiguration(token?: string, getParams: {} = {}) {
    let paramString = `token=${token}`;
    for (const [name, value] of Object.entries(getParams)) {
      paramString = paramString + `&${name}=${value}`;
    }

    this.observable = this.httpClient.get(
      `${this.discoveryService.getAccountV2ApiEndpoint()}/driver/profile/configuration?${paramString}`,
      {
        observe: 'response',
        withCredentials: true
      }
    ).pipe(
      retry(3),
      map(response => {
        this.observable = null;
        if (response.status !== 200) {
          const configObj = window.sessionStorage.getItem('signUpConfiguration');
          if (!configObj) {
            throw throwError('No Config');
          }
          this.signUpConfiguration = configObj ? JSON.parse(configObj) : {};
        } else {
          this.signUpConfiguration = response.body;
          if (this.signUpConfiguration.errorId || this.signUpConfiguration.errorMessage) {
            return this.signUpConfiguration;
          }
          window.sessionStorage.setItem('signUpConfiguration', JSON.stringify(this.signUpConfiguration));
        }
        this.initConfiguration(this.signUpConfiguration);
        return this.signUpConfiguration;
      }),
      catchError(err => {
        // DEN flow error on PayPal
        if (!window.location.href.includes('/signup/paypal-success') && (err.error.errorId || err.error.errorMessage)) {
          return of(err.error);
        }
        this.observable = null;
        const configObj = window.sessionStorage.getItem('signUpConfiguration');
        if (!configObj) {
          throw throwError('No Config');
        }
        this.signUpConfiguration = configObj ? JSON.parse(configObj) : {};
        this.initConfiguration(this.signUpConfiguration);
        return of(this.signUpConfiguration);
      })
    );

    return this.observable;
  }

  initConfiguration(config) {
    if (config.components && config.components.length > 0) {
      config.components.forEach((item) => {
        this.componentConfig[item.componentId] = [];
        this.initComponentFields(item.componentId, item.fields);
      });
    }
    if (config.locale) {
      const userLocale = findLocaleFromLocalStorage();
      this.langSvc.updateLanguage(userLocale || config.locale);
    }
  }

  initComponentFields(componentId, fields) {
    fields.forEach((field) => {
      if ((typeof field.hidden !== 'undefined' && field.hidden)
        || !COMPONENT_CONFIG[componentId] || !COMPONENT_CONFIG[componentId][field.id]
      ) {
        return;
      }
      const config = {
        component: COMPONENT_CONFIG[componentId][field.id].component,
        validations: [],
        asyncValidations: [],
        name: field.id,
        placeholder: field.id,
        value: '',
        fields: []
      };

      if (typeof COMPONENT_CONFIG[componentId][field.id].required !== 'undefined'
        && COMPONENT_CONFIG[componentId][field.id].required) {
        config.validations.push(Validators.required);
      }

      if (typeof FIELD_PLACEHOLDER[field.id] !== 'undefined' && FIELD_PLACEHOLDER[field.id]) {
        config.placeholder = FIELD_PLACEHOLDER[field.id];
      }

      if (typeof field.value !== 'undefined' && field.value) {
        config.value = field.value;
      }

      if (typeof COMPONENT_CONFIG[componentId][field.id].fields !== 'undefined') {
        const fieldType = (typeof field.memberType !== 'undefined') ? field.memberType : field.type;
        config.fields = this.initTypeFields(componentId, fieldType, COMPONENT_CONFIG[componentId][field.id].fields);
      }

      if (config) {
        this.componentConfig[componentId][field.id] = config;
      }
    });
  }

  initTypeFields(componentId, type, typeFields) {
    const fields = [];
    for (const fieldId in this.signUpConfiguration['types'][type]) {
      if (this.signUpConfiguration['types'][type].hasOwnProperty(fieldId) && typeFields[fieldId]) {

        const configItem = {
          component: typeFields[fieldId].component,
          validations: [],
          asyncValidations: [],
          name: componentId ? componentId + '_' + fieldId : fieldId,
          placeholder: componentId ? componentId + '_' + fieldId : fieldId,
          value: '',
          fields: []
        };

        if (typeof typeFields[fieldId].required !== 'undefined' && typeFields[fieldId].required) {
          configItem.validations.push(Validators.required);
        }

        if (typeof FIELD_PLACEHOLDER[fieldId] !== 'undefined' && FIELD_PLACEHOLDER[fieldId]) {
          configItem.placeholder = FIELD_PLACEHOLDER[fieldId];
        }

        const subType = this.signUpConfiguration['types'][type][fieldId];
        if (typeof this.signUpConfiguration['types'][subType] !== 'undefined'
            && typeof typeFields[fieldId].fields !== 'undefined') {
          configItem.fields = this.initTypeFields(componentId, subType, typeFields[fieldId].fields);
        }

        fields[fieldId] = configItem;
      }
    }

    return fields;
  }

  getTypeFields(componentId, type) {
    const typeFields = COMPONENT_CONFIG.getTypeConfig(type);
    return this.initTypeFields(componentId, type, typeFields.fields);
  }

  getTypeFieldList(type, componentId = null) {
    const typeData = COMPONENT_CONFIG.getTypeConfig(type);
    const typeFields = (typeData && typeData.fields) ? typeData.fields : {};
    const fields = [];
    for (const fieldId in typeFields) {
      if (typeFields.hasOwnProperty(fieldId)) {
        const configItem = {
          component: typeFields[fieldId].component,
          validations: [],
          asyncValidations: [],
          name: componentId ? componentId + '_' + fieldId : fieldId,
          placeholder: componentId ? componentId + '_' + fieldId : fieldId,
          value: '',
          fields: []
        };

        if (typeof typeFields[fieldId].required !== 'undefined' && typeFields[fieldId].required) {
          configItem.validations.push(Validators.required);
        }

        if (typeof FIELD_PLACEHOLDER[fieldId] !== 'undefined' && FIELD_PLACEHOLDER[fieldId]) {
          configItem.placeholder = FIELD_PLACEHOLDER[fieldId];
        }

        fields[fieldId] = configItem;
      }
    }

    return fields;
  }

  getComponentFieldsConfig(componentId) {
    if (typeof this.componentConfig[componentId] !== 'undefined') {
      return this.componentConfig[componentId];
    }
    return null;
  }

  getSaveData(formData, component = null) {
    const data = {};
    let result = null;
    for (const componentId in this.signUpConfiguration['schema']) {
      if (component && componentId !== component) {
        continue;
      }
      if (this.signUpConfiguration['schema'].hasOwnProperty(componentId)) {
        data[componentId] = {};
        for (const fieldId in this.signUpConfiguration['schema'][componentId]) {
          if (this.signUpConfiguration['schema'][componentId].hasOwnProperty(fieldId)) {
            if (formData[componentId] && formData[componentId][fieldId]) {
              let type = this.signUpConfiguration['schema'][componentId][fieldId];
              let memberType = '';
              if (type.includes('Array::')) {
                memberType = type.replace('Array::', '');
                type = 'Array';
              }

              switch (type) {
                case 'String':
                  data[componentId][fieldId] = formData[componentId][fieldId];
                  break;
                case 'Long':
                case 'Integer':
                  data[componentId][fieldId] = parseInt(formData[componentId][fieldId], 10);
                  break;
                case 'Boolean':
                  data[componentId][fieldId] = Boolean(formData[componentId][fieldId]);
                  break;
                case 'Array':
                  result = this.getArrayData(formData[componentId][fieldId], memberType);
                  if (result.length > 0) {
                    data[componentId][fieldId] = result;
                  }
                  break;
                default:
                  result = this.getTypeData(formData[componentId][fieldId], type);
                  if (Object.keys(result).length > 0) {
                    data[componentId][fieldId] = result;
                  }
                  break;
              }
            }
          }
        }
      }
    }
    return data;
  }

  getArrayData(formArrayData, memberType) {
    const data = [];
    let result = null;
    if (formArrayData && formArrayData.length) {
      formArrayData.forEach(childData => {
        result = this.getTypeData(childData, memberType);
        if (Object.keys(result).length > 0) {
          data.push(result);
        }
      });
    }
    return data;
  }

  getTypeData(formTypeData, type) {
    const data = {};
    let result = null;
    for (const fieldId in this.signUpConfiguration['types'][type]) {
      if (this.signUpConfiguration['types'][type].hasOwnProperty(fieldId)) {
        if (formTypeData[fieldId]) {
          const typeVal = this.signUpConfiguration['types'][type][fieldId];

          switch (typeVal) {
            case 'String':
              data[fieldId] = formTypeData[fieldId];
              break;
            case 'Long':
            case 'Integer':
              data[fieldId] = parseInt(formTypeData[fieldId], 10);
              break;
            case 'Boolean':
              data[fieldId] = Boolean(formTypeData[fieldId]);
              break;
            default:
              result = this.getTypeData(formTypeData[fieldId], typeVal);
              if (Object.keys(result).length > 0) {
                data[fieldId] = result;
              }
              break;
          }
        }
      }
    }
    return data;
  }

  getComponentFormData(formGroup: FormGroup, componentId: string): any {
    const data = {};
    if (this.signUpConfiguration['schema'][componentId]) {
      for (const field in this.signUpConfiguration['schema'][componentId]) {
        if (this.signUpConfiguration['schema'][componentId].hasOwnProperty(field)) {
          data[field] = this.getComponentFieldFormData(formGroup, componentId, field);
        }
      }
    }
    return data;
  }

  getComponentFieldFormData(formGroup: FormGroup, componentId: string, fieldId: string) {
    if (this.signUpConfiguration['schema'][componentId]
      && this.signUpConfiguration['schema'][componentId][fieldId]
    ) {
      let type = this.signUpConfiguration['schema'][componentId][fieldId];
      let memberType = '';
      if (type.includes('Array::')) {
        memberType = type.replace('Array::', '');
        type = 'Array';
      }

      switch (type) {
        case 'String':
          return formGroup.get(fieldId) ? formGroup.get(fieldId).value.toString() : null;
        case 'Long':
        case 'Integer':
          return formGroup.get(fieldId) ? parseInt(formGroup.get(fieldId).value, 10) : null;
        case 'Boolean':
          return formGroup.get(fieldId) ? Boolean(formGroup.get(fieldId).value) : null;
        case 'Array':
          return this.getArrayTypeFormData(formGroup, componentId, fieldId, memberType);
        default:
          return this.getTypeFormData(formGroup, componentId, type);
      }
    }
  }

  getTypeFormData(formGroup: FormGroup, componentId: string, type: string) {
    const data = {};

    for (const fieldId in this.signUpConfiguration['types'][type]) {
      if (this.signUpConfiguration['types'][type].hasOwnProperty(fieldId)) {
        let fieldName = componentId ? componentId + '_' + fieldId : fieldId;

        if (!formGroup.get(fieldName)) {
          fieldName = fieldId;
        }
        const typeVal = this.signUpConfiguration['types'][type][fieldId];
        switch (typeVal) {
          case 'String':
            data[fieldId] = formGroup.get(fieldName) ? formGroup.get(fieldName).value.toString() : null;
            break;
          case 'Long':
          case 'Integer':
            data[fieldId] = formGroup.get(fieldName) ? parseInt(formGroup.get(fieldName).value, 10) : null;
            break;
          case 'Boolean':
            data[fieldId] = formGroup.get(fieldName) ? Boolean(formGroup.get(fieldName).value) : null;
            break;
          default:
            data[fieldId] = this.getTypeFormData(formGroup, componentId, typeVal);
            break;
        }
      }
    }
    return data;
  }

  getArrayTypeFormData(formGroup: FormGroup, componentId: string, fieldId: string, memberType: string) {
    const data = [];
    const items: FormArray = formGroup.get(fieldId) as FormArray;
    if (items.controls && items.controls.length) {
      items.controls.forEach(childGroup => {
        data.push(this.getTypeFormData(childGroup as FormGroup, componentId, memberType));
      });
    }
    return data;
  }
}

import { LanguageService } from 'src/app/services/language.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LangPipe } from 'src/app/pipes/lang.pipe';
import { getLocaleConfig } from 'src/app/locale-picker-modal/locale-config';
import { KitLocaleConfig } from 'src/app/locale-picker-modal/interfaces';
import { Subscription } from 'rxjs';
import { FrocusTrapService } from 'src/app/services/frocus-trap.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'cp-change-lang-link',
  templateUrl: './change-lang-link.component.html',
  styleUrls: ['./change-lang-link.component.scss']
})
export class ChangeLangLinkComponent implements OnInit, OnDestroy {
  countryName = '';
  showLocalePicker = false;
  localeToCountryNameMap = new Map<string, string>();
  langSubscripton: Subscription;

  constructor(private languageSvc: LanguageService, private focusTrapService: FrocusTrapService) {
    const langPipe = new LangPipe(languageSvc);
    const localeConfig: KitLocaleConfig = getLocaleConfig(langPipe);
    localeConfig.regions.forEach(region => {
      region.locales.forEach(locale => {
        this.localeToCountryNameMap.set(locale.localeCode, locale.localizedLanguageName);
      });
    });
  }

  ngOnInit(): void {
    this.langSubscripton = this.languageSvc.localeEmmiter.subscribe(locale => {
      this.countryName = this.localeToCountryNameMap.get(locale);
    });
  }

   openLocalePicker(event: MouseEvent | boolean): void {
    if (typeof event === 'boolean') {
      this.showLocalePicker = event;

    } else {
      this.focusTrapService.setLastFocusedElement(event.target as HTMLElement);
      this.showLocalePicker = true;
    }
   }
 
   ngOnDestroy(): void {
    if (this.langSubscripton) {
      this.langSubscripton.unsubscribe();
    }
  }

}

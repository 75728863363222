import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, of } from 'rxjs';
import { DiscoveryStoreService } from './discovery.service';

interface Brand {
  brand: string;
}

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  constructor(private discoveryService: DiscoveryStoreService, private httpClient: HttpClient) {}

   getBrandFromApi(subdomain: string) {
      const brandUrl = `${this.discoveryService.getAccountV2ApiEndpoint()}/metadata/org/${subdomain}/brand`;
      return this.httpClient.get<Brand>(brandUrl).pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            // The error is an HTTP error
            console.error(`HTTP Error: ${error.status}`);
            console.error(error.message);
          } else {
            // A non-HTTP error occurred
            console.error('Error:', error.message);
          }
          return of(null);
        })
      );
   }
}

<div class="webview-content">
  <p>
    {{"Your request has been submitted." |T}}
  </p>
  <p>
    {{"%companyName% will verify your account." |T: {companyName:companyName} }}
  </p>
  <p>
    {{"Once you’re verified, an installer will contact you to install ChargePoint Home." |T }}
  </p>
  <a [routerLink]="['/webview/get-home-charger', {allowRedirect:false}]" class="change-link">
    {{"Change Request" | T }}
  </a>
</div>

import { Component, Input, EventEmitter, Output, ElementRef, OnInit } from '@angular/core';
import { LanguageService } from '../../services/language.service';
import { LangPipe } from '../../pipes/lang.pipe';
import { NEXT, BACK } from './translate';
import { UiFlowStateService } from '../../services/ui-flow-state.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'cp-signup-footer-controls',
  templateUrl: './signup-footer-controls.component.html',
  styleUrls: ['./signup-footer-controls.component.scss']
})

export class SignupFooterControlsComponent implements OnInit {
  @Input() disabledBackButton = false;
  @Input() disabledNextButton = false;
  @Input() nextButtonText: string;
  @Input() focusOnError = false;

  @Input() set backButtonText(buttonText) {
    if (buttonText) {
      this._backbuttonText = buttonText;
    } else {
      this._backbuttonText = this.langPipe.transform(BACK);
    }
  }

  @Input() showBackButton = true;
  @Input() backButtonPreventDefault = false;

  @Output() nextClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() backClicked: EventEmitter<any> = new EventEmitter<any>();
  _backbuttonText: string;
  langPipe: LangPipe;
  showPoweredByLogo = false;

  constructor(
    private langSvc: LanguageService,
    private uiFlowState: UiFlowStateService,
    private themeService: ThemeService,
    private elementRef: ElementRef
  ) {
    this.langPipe = new LangPipe(this.langSvc);
    this.nextButtonText = this.langPipe.transform(NEXT);
    this._backbuttonText = this.langPipe.transform(BACK);
  }
  ngOnInit(): void {
    this.themeService.showPoweredByLogo$.subscribe((shouldShowPoweredBy) => {
        this.showPoweredByLogo = shouldShowPoweredBy;
    });
  }

  historyBack() {
    if (this.backButtonPreventDefault) {
      return;
    }

    this.uiFlowState.prevRoute();
  }

  clickHandler() {
    this.nextClicked.emit();
  }
}

import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ElectricVehicleService } from './electric-vehicle.service';
import { Make, Model, VehicleYear, VehicleColor } from './interfaces';
import { CommunicationService } from '../../services/communication.service';
import { Vehicle } from '../../services/models/backend-save-classes';
import { DriverSignupUXService } from '../../services/driver-signup-ux.service';
import { FieldConfig } from '../../services/models/default-config';

@Component({
  selector: 'cp-electric-vehicle',
  templateUrl: './electric-vehicle.component.html',
  styleUrls: ['./electric-vehicle.component.scss']
})
export class ElectricVehicleComponent implements OnInit {
  @Input() pressedSubmit: boolean;

  @Input()
  set evFormGroup(grp: FormGroup) {
    this.evInfo = grp;
    this.initFormGroup(this.evInfo);
  }

  fillEV = false;
  evSelected: Vehicle;
  evInfo: FormGroup;
  makes: Array<Make>;
  models: Array<Model>;
  years: Array<any>;
  colors: Array<any>;
  carImage: string;
  colorImages: Array<string>;
  fieldsConfig: Array<FieldConfig>;
  needValidate: boolean;

  constructor(
    private evService: ElectricVehicleService,
    private ref: ChangeDetectorRef,
    private comsvc: CommunicationService,
    private configService: DriverSignupUXService
  ) {
    this.needValidate = false;
  }

  ngOnInit() {
    this.comsvc.getComponentDefinition('profile').subscribe((componentDefinition) => {
      if (componentDefinition) {
        this.fieldsConfig = this.configService.getTypeFields('', 'Vehicle');
      }
    });
  }


  initFormGroup(grp: FormGroup) {
    grp.addControl('I-dont-own-ev', new FormControl(false));
    if (this.needValidate) {
      this.setValidators();
    }
    this.evService.getMakes().subscribe(result => {
      this.makes = result;
      this.evSelected = this.comsvc.getEV();
      this.makes.forEach( make => {
        if (make.selected) {
          if (this.evInfo.get('makeId')) {
            this.evInfo.get('makeId').setValue(make.id);
            this.updateModels(make.id);
          }
        }
      });

      if (this.comsvc.isHaveNotEV() !== null) {
        if (grp.get('I-dont-own-ev').value !== this.comsvc.isHaveNotEV()) {
          grp.patchValue({'I-dont-own-ev': this.comsvc.isHaveNotEV()});
        }

        if (this.comsvc.isHaveNotEV()) {
          this.clearValidators();
        } else {
          if (this.evSelected.makeId && this.evSelected.modelId
            && this.evSelected.year && this.evSelected.colorId) {
            this.fillEV = true;
            if (this.evInfo.get('makeId')) {
              this.evInfo.get('makeId').setValue(this.evSelected.makeId);
              this.updateModels(this.evSelected.makeId);
            }
          }
        }
      }
    });
  }

  updateModels(makeId) {
    if (makeId > 0) {
      this.evService.getModels(makeId).subscribe(result => {
        this.models = result.map(item => {
          if (item.trim) {
            item.name = item.name + ' ' + item.trim;
          }
          return item;
        });
        let val = result.length === 1 ? result[0].id : '';
        if (this.fillEV && this.evSelected.modelId) {
          val = this.evSelected.modelId;
        }
        this.evInfo.get('modelId').setValue(val);
        this.updateYears(val);
      });
    } else {
      this.models = [];
      this.evInfo.get('modelId').setValue('');
      this.updateYears('');
    }
  }

  updateYears(modelId) {
    if (modelId > 0) {
      this.evService.getYears(modelId).subscribe(result => {
        this.years = result.map(item => {
          const newItem = Object.assign({id: null, name: null}, item);
          newItem.id = item.year;
          newItem.name = item.year;
          return newItem;
        });
        let val = result.length === 1 ? result[0].year : '';
        if (this.fillEV && this.evSelected.year) {
          val = this.evSelected.year;
        }
        this.evInfo.get('year').setValue(val);
        this.updateColors(val);
      });
    } else {
      this.years = [];
      this.evInfo.get('year').setValue('');
      this.updateColors('');
    }
  }

  updateColors(year) {
    this.carImage = '';
    this.colorImages = [];
    const modelId = this.evInfo.get('modelId').value;
    if (year > 0 && modelId > 0) {
      this.evService.getColors(modelId, year).subscribe(result => {
        this.colors = result.map(item => {
          const newItem = Object.assign({id: null, name: null}, item);
          newItem.id = item.colorId;
          newItem.name = item.colorName;
          return newItem;
        });
        let val = result.length === 1 ? result[0].colorId : '';
        if (this.fillEV && this.evSelected.colorId) {
          val = this.evSelected.colorId;
          this.fillEV = false;
        }
        for (let i = 0; i < result.length; i++) {
          this.colorImages[result[i].colorId] = result[i].imageUrl;
        }
        this.evInfo.get('colorId').setValue(val);
        this.updateCarImage(val);
      });
    } else {
      this.colors = [];
      this.evInfo.get('colorId').setValue('');
    }
  }

  updateCarImage(colorId) {
    if (colorId) {
      this.carImage = this.colorImages[colorId];
    }
  }

  onImageError() {
    const url = new URL(this.carImage);
    this.carImage = 'https://' + url.hostname + "/vehicle_models/no_car.jpg";
  }

  updateValidators(e) {
    if (e.target.checked) {
      this.clearValidators();
      this.evInfo.get('makeId').reset();
    } else {
      this.setValidators();
      // this.evInfo.get('make').updateValueAndValidity();
    }
  }

  setValidators() {
    this.needValidate = true;
  }

  clearValidators() {
    this.needValidate = false;
  }
}

export class AddressWithZip {
  address1?;
  address2?;
  city?;
  countryId?;
  stateId?;
  zipCode?;
  constructor({address1, address2, city, countryId, stateId, zipCode}) {
    if (arguments.length > 0) {
      Object.assign(this, arguments[0]);
    }
  }
}

export class Address extends AddressWithZip {
  constructor(initObj) {
    super(initObj);
  }
}

export class Country {
  callingCode: number;
  code: string;
  id: number;
  name: string;
  originalName: string;
  phoneVerificationSupported: boolean;
  phoneFormat: string;
  constructor({callingCode, code, id, name, originalName, phoneVerificationSupported, phoneFormat }) {
    if (arguments.length > 0) {
      Object.assign(this, arguments[0]);
    }
  }
}

export class CountryState {
  countryId: number;
  id: number;
  name: string;
  originalName: string;
  stateCode: string;
  constructor({countryId, id, name, originalName, stateCode}) {
    if (arguments.length > 0) {
      Object.assign(this, arguments[0]);
    }
  }
}

<div *ngIf="this.fieldsLoaded">
  <p class="head-blurb">
    {{"An administrator from %orgName% will verify your account to provide you with the charging benefits from %connectionName%."|T: {orgName:this.connectionFields.orgName,connectionName:this.connectionFields.connectionName} }}
  </p>
  <form [formGroup]="connectionForm">
    <div class="form-group" *ngFor="let fields of this.connectionFields.connectionFields">
      <input type="text" class="form-control" data-private="lipsum" formControlName={{fields.key}}
        placeholder="{{fields.name}}" [class.invalid]="updateError(fields.key)" aria-describedby="{{fields.key +'-connection-field'}}" required="true">
      <div *ngIf="updateError(fields.key)" class="has-error" id="{{fields.key +'-connection-field'}}">
        <div *ngIf="connectionForm.get(fields.key).errors.required" class="has-error">
          {{"This field is required" | T}}
        </div>
      </div>
    </div>
    <div class="fuel-card-container">
      <div class="fuel-card-input-container">
        <div class="">
          <div class="form-group">
              <input type="text" data-private="lipsum" class="form-control serialnumber" [formControl]="cardSerialNumber"
                placeholder="{{'Card Serial Number'|T}}" [attr.aria-label]="'Card Serial Number' | T" aria-describedby="serialNumberErrorContainer" required="true" />

            <div *ngIf="cardSerialNumber.invalid && (cardSerialNumber.dirty || cardSerialNumber.touched)"
              class="has-error" id="serialNumberErrorContainer">
              <div *ngIf="cardSerialNumber.errors?.required">
                {{"This field is required" | T}}
              </div>
              <div *ngIf="cardSerialNumber.errors?.pattern">
                {{ "Invalid serial number." | T}}
              </div>
              <div *ngIf="cardSerialNumber.errors?.server">
                {{ cardSerialNumber.errors?.message}}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <label class="collapse">{{'Card Nickname'|T}}</label>
              <input type="text" data-private="lipsum" class="form-control serialnumber" [formControl]="cardNickName"
                placeholder="{{'Card Nickname'|T}}" [attr.aria-label]="'Card Nickname' | T" aria-describedby="cardNicknamErrorContainer" required="true" />

            <div *ngIf="cardNickName.invalid && (cardNickName.dirty || cardNickName.touched)" class="has-error" id="cardNicknamErrorContainer">
              <div *ngIf="cardNickName.errors?.required">
                {{"This field is required" | T}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fuel-card-image-container">
        <div class="img-responsive driver-card-signup-image"></div>
      </div>
    </div>
    <div class="agree-terms-conditions-container">
      <p>
        <input type="checkbox" formControlName="chargepointPolicyAccept">
        <!---using inner html instead of string concatenation-->
        <span (click)='openTermsDialog($event, this.connectionFields.serviceTcLink)' [innerHtml]="' I agree to the %companyName% <a href=\'#\'>Terms & Conditions</a>' | T : {
          companyName: this.connectionFields.serviceTcTitle
        }  "></span>
      </p>
      <p *ngIf="this.connectionFields.companyTcLink">
        <input type="checkbox" formControlName="companyPolicyAccept">
        <!---using inner html instead of string concatenation-->
        <span (click)='openTermsDialog($event, this.connectionFields.companyTcLink)' [innerHtml]="' I agree to the %companyName% <a href=\'#\'>Terms & Conditions</a>' | T : {
          companyName: this.connectionFields.orgName
        }  "></span>
      </p>
    </div>
    <!--- Submit form -->
    <div class="button-container">
      <button type="submit" class="btn btn-lg btn-primary" (click)="submitValues()"
        [disabled]="connectionForm.invalid">{{"Continue" | T}}</button>
    </div>
  </form>
</div>

<div *ngIf="errorToken" class="has-error">
  {{"An error has occurred, please contact your company’s administrator" | T}}
</div>
<cp-terms-dialog [content]="termsText" *ngIf="showTermsModal" [openModal]="showTermsModal"></cp-terms-dialog>
<cp-loader *ngIf="isLoading"></cp-loader>

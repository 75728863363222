import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Make, Model, VehicleYear, VehicleColor } from './interfaces';
import { CommunicationService } from '../../services/communication.service';
import { LanguageService } from '../../services/language.service';
import { DiscoveryStoreService } from 'src/app/services/discovery.service';

@Injectable({
  providedIn: 'root'
})
export class ElectricVehicleService {
  constructor(
    private http: HttpClient,
    private discoveryService: DiscoveryStoreService,
    private langSvc: LanguageService,
    private commSvc: CommunicationService
  ) {}

  getMakes(): Observable<Array<Make>> {
    let token = '';
    if (this.commSvc.getPartnerToken()) {
      token = this.commSvc.getPartnerToken();
    }

    return this.http.get<Array<Make>>(
      `${this.discoveryService.getAccountV1ApiEndpoint()}/metadata/vehicle/make?token=${token}`,
      this.getHttpOptions()
    ).pipe(
      catchError((error) => {
        return of([]);
      })
    );
  }

  getModels(makeId: number): Observable<Array<Model>> {
    return this.http.get<Array<Model>>(
      `${this.discoveryService.getAccountV1ApiEndpoint()}/metadata/vehicle/model?makeId=${makeId}`,
      this.getHttpOptions()
    ).pipe(
      catchError((error) => {
        return of([]);
      })
    );
  }

  getYears(modelId: number): Observable<Array<VehicleYear>> {
    return this.http.get<Array<VehicleYear>>(
      `${this.discoveryService.getAccountV1ApiEndpoint()}/metadata/vehicle/year?modelId=${modelId}`,
      this.getHttpOptions()
    ).pipe(
      catchError((error) => {
        return of([]);
      })
    );
  }

  getColors(modelId: number, year: number): Observable<Array<VehicleColor>> {
    return this.http.get<Array<VehicleColor>>(
      `${this.discoveryService.getAccountV1ApiEndpoint()}/metadata/vehicle/color?modelId=${modelId}&year=${year}`,
      this.getHttpOptions()
    ).pipe(
      catchError((error) => {
        return of([]);
      })
    );
  }

  getHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Accept-Language': this.langSvc.getLocale()
      })
    };
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LangPipe } from '../pipes/lang.pipe';
import { NumberPipe } from '../pipes/lang.pipe';


@NgModule({
  imports: [
    CommonModule
  ],
  exports: [LangPipe, NumberPipe],
  declarations: [LangPipe, NumberPipe]
})
export class LanguageModule { }

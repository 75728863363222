import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CPMixPanel } from './services/mixpanel.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { BodyComponent } from './body/body.component';
import { BannerComponent } from './banner/banner.component';
import { ProgressTrackerComponent } from './progress-tracker/progress-tracker.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserPaymentComponent } from './user-payment/user-payment.component';
import { GetHomeChargerComponent } from './get-home-charger/get-home-charger.component';
import { HomeChargerDoneComponent } from './home-charger-done/home-charger-done.component';
import { WebviewBodyComponent } from './webview-body/webview-body.component';
import { GetHomeChargerSorryComponent } from './get-home-charger-sorry/get-home-charger-sorry.component';
import { HomeChargerAddressComponent } from './home-charger-address/home-charger-address.component';
import { AddressComponent } from './shared/address/address.component';
import { ChargepointCardsComponent } from './account/chargepoint-cards/chargepoint-cards.component';
import { ElectricVehicleComponent } from './account/electric-vehicle/electric-vehicle.component';
import { NotificationDeliveryComponent } from './account/notification-delivery/notification-delivery.component';
import { FooterComponent } from './footer/footer.component';
import { ConnectionComponent } from './connection/connection.component';
import { MobileHomeChargerDoneComponent } from './mobile-home-charger-done/mobile-home-charger-done.component';
import { TermsDialogComponent } from './terms-dialog/terms-dialog.component';
import { PhoneWCountryComponent } from './shared/phone-w-country/phone-w-country.component';
import { CpInputComponent } from './shared/cp-input/cp-input.component';
import { CpUserEmailComponent } from './shared/cp-user-email/cp-user-email.component';
import { CpUserPasswordComponent } from './shared/cp-user-password/cp-user-password.component';
import { CpSelectComponent } from './shared/cp-select/cp-select.component';
import { LanguageService, translationLoader } from './services/language.service';
import { LoginModule } from './login/login.module';
import { LanguageModule } from './language/language.module';
import { DenPartnerSuccessComponent } from './den-partner-success/den-partner-success.component';
import { GuestHeaderComponent } from './guest-header/guest-header.component';
import { CpCommonModule } from './cp-common/cp-common.module';
import { SplashComponent } from './splash/splash.component';
import { DaimlerSplashComponent } from './splash/cobrands/daimler-splash/daimler-splash.component';
import { ChargepointCardsV2Component } from './account/chargepoint-cards-v2/chargepoint-cards-v2.component';
import { VerifyAccountComponent } from './account/verify-account/verify-account.component';
import { ElectricVehicleV2Component } from './account/electric-vehicle-v2/electric-vehicle-v2.component';
import { NotificationDeliveryV2Component } from './account/notification-delivery-v2/notification-delivery-v2.component';
import { AndroidBannerComponent } from './mobile-web/android-banner/android-banner.component';
import { ChangeLangLinkComponent } from './footer/change-lang-link/change-lang-link.component';
import { HtmlEntityPipe } from './pipes/html-entity.pipe';
import { GenericPartnerSuccessComponent } from './generic-partner-success/generic-partner-success.component';
import { ConnectionBodyComponent } from './driver-connection/body.component';
import { ConnectionHomeChargerComponent } from './driver-connection/home-charger/get-home-charger.component';
import { ConnectionHomeChargerAddressComponent } from './driver-connection/home-charger/home-charger-address.component';
import { ConnectionHomeChargerDoneComponent } from './driver-connection/home-charger/home-charger-done.component';
import { DriverConnectionFuelCardComponent } from './driver-connection-fuelcard/driver-connection-fuelcard.component';
import { LocalePickerModalComponent } from './locale-picker-modal/locale-picker-modal.component';
import { applicationInitFactory, DiscoveryStoreService } from './services/discovery.service';
import { ThemeService } from './services/theme.service';
import { PhoneWCountrySimpleComponent } from './shared/phone-w-country-simple/phone-w-country-simple.component';
import { CpCommonSuccessRefactorComponent } from './cp-common-success-refactor/cp-common-success-refactor.component';
import { FocusTrapDirective } from './directives/focustrap.directive';
import { AriaAnnounceComponent } from './aria-announce/aria-announce.component';
import { SharedDirectiveModule } from './shared-module/shared-module.module';
import { TraceIdInterceptorService } from './interceptors/trace-id-interceptor.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BodyComponent,
    BannerComponent,
    ProgressTrackerComponent,
    UserProfileComponent,
    UserPaymentComponent,
    GetHomeChargerComponent,
    HomeChargerDoneComponent,
    WebviewBodyComponent,
    GetHomeChargerSorryComponent,
    HomeChargerAddressComponent,
    AddressComponent,
    ChargepointCardsComponent,
    ElectricVehicleComponent,
    NotificationDeliveryComponent,
    FooterComponent,
    ConnectionComponent,
    MobileHomeChargerDoneComponent,
    TermsDialogComponent,
    PhoneWCountryComponent,
    DenPartnerSuccessComponent,
    GuestHeaderComponent,
    CpInputComponent,
    CpUserEmailComponent,
    CpSelectComponent,
    CpUserPasswordComponent,
    SplashComponent,
    DaimlerSplashComponent,
    ChargepointCardsV2Component,
    VerifyAccountComponent,
    ElectricVehicleV2Component,
    NotificationDeliveryV2Component,
    AndroidBannerComponent,
    ChangeLangLinkComponent,
    HtmlEntityPipe,
    GenericPartnerSuccessComponent,
    ConnectionBodyComponent,
    ConnectionHomeChargerComponent,
    ConnectionHomeChargerAddressComponent,
    ConnectionHomeChargerDoneComponent,
    DriverConnectionFuelCardComponent,
    LocalePickerModalComponent,
    PhoneWCountrySimpleComponent,
    CpCommonSuccessRefactorComponent,
    FocusTrapDirective,
    AriaAnnounceComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    LanguageModule,
    LoginModule,
    CpCommonModule,
    FormsModule,
    SharedDirectiveModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: applicationInitFactory,
      deps: [DiscoveryStoreService, LanguageService, ThemeService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TraceIdInterceptorService,
      multi: true
    },
    CPMixPanel,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

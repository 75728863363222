import { Component } from '@angular/core';
import { GetHomeChargerComponent } from '../../get-home-charger/get-home-charger.component';
import { LangPipe } from '../../pipes/lang.pipe';
import { NEXT } from '../../cp-common/signup-footer-controls/translate';

@Component({
  selector: 'cp-connection-home-charger',
  templateUrl: './get-home-charger.component.html',
  styleUrls: ['../../get-home-charger/get-home-charger.component.scss']
})
export class ConnectionHomeChargerComponent extends GetHomeChargerComponent {
  init() {
    const langPipe = new LangPipe(this.languageSvc);
    this.isEdit = false;
    this.showBackButton = true;
    this.frontendConfigSvc.setBodyTitle('homeChargerTitle');
    this.nextButtonText = langPipe.transform(NEXT);
  }
}

import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { ValidationService } from '../../services/validation.service';
import { Country } from '../../services/models/address-with-zip';
import { AddressService } from '../address/address.service';
import { map } from 'rxjs/operators';
import { of, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'cp-phone-w-country',
  templateUrl: './phone-w-country.component.html',
  styleUrls: ['./phone-w-country.component.scss']
})
export class PhoneWCountryComponent implements OnInit {
  countries: Array<Country>;

  set selectedCountryObj(country: Country) {
    this._parentForm.get('selectedCountryObj').setValue(country);
  }
  @Input() controlnamePrefix = '';
  @Input() pressedSubmit = false;
  @Input() needValidate = true;
  @Input() onlyPhoneVerificationSupported = false;
  @Input() onlySupported = true;

  @Output() enterKeyPressed = new EventEmitter();

  @ViewChild('phoneInput') phoneInput: ElementRef<HTMLInputElement>;
  showDropdown = false;
  selectedId = null;
  selectedCountryId = null;
  _parentForm: FormGroup;
  $countries: Subject<Array<Country>> = new Subject();
  $phoneKeyStrokes = new Subject();
  enterSubscription: Subscription;

  @Input() set parentForm(frmGrp: FormGroup) {
    this._parentForm = frmGrp;
    if (!this._parentForm.get(this.controlnamePrefix + '_phone')) {
      this._parentForm.addControl(
        this.controlnamePrefix + '_phone',
        new FormControl('', {updateOn: 'blur'})
      );
    }

    this._parentForm.addControl(this.controlnamePrefix + '_country_dial_code', new FormControl(''));
    this._parentForm.addControl('selectedCountryObj', new FormControl());
  }

  @Input() set phoneValue(phoneValue) {
    if (this._parentForm.get(this.controlnamePrefix + '_phone')) {
      this._parentForm.get(this.controlnamePrefix + '_phone').setValue(phoneValue);
    }
  }

  @Input() set addValidation(isValidate: boolean) {
    if (this.needValidate !== isValidate) {
      this.needValidate = isValidate;
      if (this.needValidate) {
        this.setValidators();
      } else {
        this.cleanValidators();
      }
    }
  }

  constructor(
    private validationService: ValidationService,
    private addressSvc: AddressService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.addressSvc.getCountriesByParam(this.onlyPhoneVerificationSupported, this.onlySupported).subscribe(data => {
      this.countries = data;
      this.$countries.next(this.countries);
      if (this.selectedCountryId) {
        this.setCountry(this.selectedCountryId);
      } else if (this.selectedId) {
        this.setCountryByCode(this.selectedId);
      } else if (this.countries && this.countries.length > 0) {
        this.setCountry(this.countries[0].id);
      }
    });

    if (this.needValidate) {
      this.setValidators();
    }
    this.$phoneKeyStrokes.pipe(

    ).subscribe(event => {
      this.ref.detectChanges();
    });
  }
  // this function helps clean up the HTML
  updateError(type) {
    return (this._parentForm.get(type).invalid
      && (this.pressedSubmit || (this._parentForm.get(type).touched) && !this._parentForm.get(type).pristine)
    );
  }

  setCountry(countryId: number) {
    if (this.countries) {
      for (let i = 0; i < this.countries.length; i++) {
        if (countryId === this.countries[i].id) {
          this._parentForm.get(this.controlnamePrefix + '_country_dial_code').setValue(this.countries[i].callingCode);
          this.selectedId = this.countries[i].callingCode;
          this.selectedCountryId = countryId;
          this.selectedCountryObj = this.countries[i];
          break;
        }
      }
    } else {
      this.selectedCountryId = countryId;
    }
  }

  setCountryByCode(code: number) {
    if (this.countries) {
      for (let i = 0; i < this.countries.length; i++) {
        if (code === this.countries[i].callingCode) {
          this._parentForm.get(this.controlnamePrefix + '_country_dial_code').setValue(code);
          this.selectedId = code;
          this.selectedCountryId = this.countries[i].id;
          this.selectedCountryObj = this.countries[i];
          break;
        }
      }
    } else {
      this.selectedId = code;
    }
  }

  setValidators() {
    this._parentForm.get(this.controlnamePrefix + '_phone').setValidators([Validators.required]);
    this._parentForm.get(this.controlnamePrefix + '_phone').setAsyncValidators([this.isPhoneValid()]);
    this._parentForm.get(this.controlnamePrefix + '_phone').updateValueAndValidity();
  }

  cleanValidators() {
    this._parentForm.get(this.controlnamePrefix + '_phone').clearValidators();
    this._parentForm.get(this.controlnamePrefix + '_phone').clearAsyncValidators();
    this._parentForm.get(this.controlnamePrefix + '_phone').updateValueAndValidity();
  }

  selectCountry(country: Country) {
    this.setCountry(country.id);
    this.selectedCountryObj = country;
    this.showDropdown = false;
    if (this._parentForm.get(this.controlnamePrefix + '_phone').value) {
      this._parentForm.get(this.controlnamePrefix + '_phone').updateValueAndValidity();
    }
  }

  closeDropdown(e) {
    e.stopPropagation();
    e.preventDefault();
    this.showDropdown = false;
  }

  clickSelect(e) {
    e.stopPropagation();
    e.preventDefault();
    this.showDropdown = true;
  }

  isPhoneValid(): AsyncValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value || !this.selectedCountryId) {
        return of(null);
      } else {
        return this.validationService.isPhoneValid(control.value, this.selectedCountryId).pipe(
          map(resp => {
            if (resp.error) {
              return { pattern: true };
            } else {
              return null;
            }
          })
        );
      }
    };
  }

  enterHit($event) {
    this.pressedSubmit = true;
    this._parentForm.get(this.controlnamePrefix + '_phone').setValue($event.target.value);
    // this._parentForm.get(this.controlnamePrefix + '_phone').markAsDirty();
    // this._parentForm.get(this.controlnamePrefix + '_phone').markAsTouched();
    this._parentForm.updateValueAndValidity();

    setTimeout(() => {
      // adding this since angular doesn't give an update all dependencies function. It is all based on a single event.
      // blur in this case.
      this.phoneInput.nativeElement.blur();
      this.phoneInput.nativeElement.focus();
    });

    if (!this.enterSubscription) {
      this.enterSubscription = this._parentForm.statusChanges.subscribe(change => {
        // this needs to be in a subscription because the validations may be async.
        if (change === 'VALID') {
          // tslint:disable-next-line:no-unused-expression
          this.enterSubscription.unsubscribe();
          this.enterSubscription = null;
          this.enterKeyPressed.emit();
        }
      });
    }
  }

  /**
   * function focuses on the phone input field
   */
  focus() {
    this.phoneInput.nativeElement.focus();
  }
}

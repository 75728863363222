import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { CpInputComponent } from '../cp-input/cp-input.component';
import { LanguageService } from 'src/app/services/language.service';
import { AriaAnnouncementService } from 'src/app/services/aria-announcement.service';

@Component({
  selector: 'cp-select',
  templateUrl: './cp-select.component.html',
  styleUrls: ['./cp-select.component.scss']
})
export class CpSelectComponent extends CpInputComponent implements OnInit, AfterViewInit {
  @Output() valueChange: EventEmitter<Event> = new EventEmitter();
  @Input() options: Array<any>;

  constructor (private languageService: LanguageService, announcementService: AriaAnnouncementService) {
    super(languageService, announcementService);
  }

  emitChange(event) {
    this.valueChange.emit(this.getValue());
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { map, share, catchError, retry } from 'rxjs/operators';
import { DiscoveryStoreService } from './discovery.service';
import { AppConfig, CountryConfig } from '../app-interfaces/interfaces';
import { mergeMap } from 'rxjs/operators';
import { redirectToDriverPortal } from '../utils';
import * as Sentry from '@sentry/browser';


@Injectable({
  providedIn: 'root'
})

export class AppConfigService {
  private appConfig: AppConfig;
  private observable: Observable<AppConfig>;
  public selectedCountry = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private discoveryService: DiscoveryStoreService,
  ) {
    this.getConfig().subscribe();
    discoveryService.nosPortalDomainEndpoint$.subscribe((nosPortalDomainEndpoint) => {
      if (nosPortalDomainEndpoint) {
        this.reloadAppConfig(nosPortalDomainEndpoint);
      }
    });
  }

  getConfig(): Observable<AppConfig> {
    if (this.appConfig) {
      return of(this.appConfig);
    }

    if (this.observable) {
      return this.observable;
    }

    const getSessionUrl = `${this.discoveryService.getNOSApiEndpoint()}index.php/nghelper/getSession`;
    this.observable = this.http.get<AppConfig>(getSessionUrl, { observe: 'response', withCredentials: true }).pipe(
      map(response => {
        this.observable = null;
        if (response.status === 400) {
          Sentry.captureException(new Error('NOS GetSession Request failed.'));
        } else if (response.status === 200) {
          this.processAppConfig(response.body);
          return this.appConfig;
        }
      }),
      catchError((err) => {
        Sentry.captureException(err);
        return of(this.appConfig);
      }),
      share()
    );

    return this.observable;
  }

  private reloadAppConfig(nosEndpoint: string): void {
    const sessionUrl = `${nosEndpoint}index.php/nghelper/getSession`;
    // adding a sentry bread crumb to track the reload
    Sentry.addBreadcrumb({
      category: 'appConfigService',
      message: `reloadAppConfig has appConfig: ${this.isAppConfigLoaded()}`,
      level: 'info',
    });
    this.http.get<AppConfig>(sessionUrl, { observe: 'response', withCredentials: true }).pipe(
      map(response => {
        this.observable = null;
        if (response.status === 400) {
          Sentry.captureException(new Error('NOS GetSession Request failed.'));
          console.error('Request failed.');
        } else if (response.status === 200) {
          this.processAppConfig(response.body);
        }
      }),
      catchError((err) => {
        Sentry.captureException(err);
        return of(null);
      }),
    ).subscribe();
  }

  private processAppConfig(appConfig: AppConfig): void {
    this.appConfig = appConfig;
    if (this.shouldRedirectToDriverPortal()) {
      redirectToDriverPortal();
    } else {
      this.setAppConfigLocale();
      this.addAppBanner();
    }
  }

  /**
   * If the current path is /driver-connection and the user is not logged in,
   * or if the user is logged in and the sign up process is finished,
   * then the user should be redirected to the driver portal.
   * @returns true if the user should be redirected to the driver portal
   */
  private shouldRedirectToDriverPortal(): boolean {
    if (!this.appConfig) {
      return false;
    }

    if (document.location.pathname.search('/webview') !== -1) {
      return false;
    }

    if (document.location.pathname.search('/driver-connection') !== -1 && !this.appConfig.user) {
      this.appConfig = null;
      return true;
    }

    if (window.sessionStorage.getItem('driverConnectionFinished') === 'true') {
      return true;
    }

    if (this.appConfig.user && window.sessionStorage.getItem('driverConnectionStarted') === 'true') {
      return false;
    }

    if (this.appConfig.user
        && (window.sessionStorage.getItem('signUpStarted') !== 'true' || window.sessionStorage.getItem('signUpFinished') === 'true')
    ) {
      this.appConfig = null;
      return true;
    }

    return false;
  }

  private setAppConfigLocale(): void {
    if (document.location.pathname.search('/webview/') !== -1 || this.appConfig?.locale) {
      // if this is a webview, we need to use the browser’s language, since that is what the user will be using in the mobile app.
      this.appConfig.locale = navigator.language;
    } else {
      this.appConfig.locale = this.appConfig.locale.replace(/_/g, '-');
    }
  }

  private addAppBanner(): void {
    if (this.appConfig.appBanner) {
      document.getElementsByTagName('meta')[0].insertAdjacentHTML('afterend', this.appConfig.appBanner);
    }
  }

  getCountryConfig(code): Observable<CountryConfig> {
    return this.getConfig().pipe(
      mergeMap((config: AppConfig) => {
        return this.http.post<any>(
          config.discoveryURL + 'discovery/v3/globalconfig',
          {
            countryCode: code,
            deviceData: {
              'type': 'not-web'
            }
          },
          this.getHttpOptions(config)
        ).pipe(
          retry(3),
          map((resp) => {
            if (resp.supportedCountries) {
              for (const country of resp.supportedCountries) {
                if (code === country.code) {
                  return country;
                }
              }
            }

            return {};
          }),
          catchError(() => {
            return of({});
          })
        );
      })
    );
  }

  getHttpOptions(config: AppConfig) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Accept-Language': config.locale
      })
    };
  }

  setSelectedCountryId(countryId: number) {
    this.selectedCountry.next(countryId);
  }

  isAppConfigLoaded(): boolean {
    return this.appConfig !== undefined;
  }
}

<footer class=" footer-container">
  <div class="container">
    <div class="float-left">
      {{"Copyright © 2008-%year% ChargePoint, Inc. All rights reserved." | T : {year:year} }}
    </div>
    <div class="float-right">
      <ul>
        <li>
          <a href="https://www.chargepoint.com" target="_blank">{{"About Us" | T}}</a>
        </li>
        <li>
          <a href="https://www.chargepoint.com/support" target="_blank">{{"Contact Us" | T}}</a>
        </li>
        <li>
          <a [href]="getA11yUrl()" target="_blank">{{"Accessibility" | T}}</a>
        </li>
        <li>
          <cp-change-lang-link></cp-change-lang-link>
        </li>
      </ul>
    </div>
  </div>
</footer>

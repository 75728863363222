import { Component } from "@angular/core";
import packageJson from "../../../package.json";

@Component({
  selector: "version",
  templateUrl: "./version.component.html",
})
export class VersionComponent {
  version: string = packageJson.version;
  GITHUB_SHA: string = `...${window.runtimeVersion?.GITHUB_SHA?.substr(
    window.runtimeVersion?.GITHUB_SHA?.length - 8
  )}`;
}

<form>
<cp-loader *ngIf="isLoading"></cp-loader>
<div class="cards-container" [formGroup]="cpCardsGroup" *ngIf="cpCardsGroup">
  <div class="custom-control custom-checkbox" *ngIf="layoutConfig.showWithAppChckBx">
    <input 
      class="custom-control-input"
      value="with-app" 
      type="checkbox" 
      [formControl]="smsForApp"
      #withAppCheckbox 
      id="withAppCheckbox" 
      (change)="changeCardOptions('with-app')"
      aria-describedby="withAppSubLabel"
      />
    <label class="custom-control-label" for="withAppCheckbox" >
      {{"With the app" | T}}
    </label>
    <div class="form-group" *ngIf='withAppCheckbox.checked'>
      <p class="checkbox-sublabel" id="withAppSubLabel">
          {{"Use the ChargePoint app to start charging." | T}}
      </p>
    </div>
  </div>

  <div class="custom-control custom-checkbox" *ngIf="!layoutConfig.showIhaveACardOnly">
      <input 
        class="custom-control-input" 
        value="use-card" 
        type="checkbox" 
        [formControl]="useCPCardChckBx"
        #IuseCPCard 
        id="useCPCard" 
        (change)="changeCardOptions('use-card')"
        aria-describedby="withCardSubLabel"
        />
      <label class="custom-control-label" for="useCPCard" >
        {{"I will use a ChargePoint Card" | T}}
      </label>
      <div class="form-group" *ngIf='IuseCPCard.checked'>
        <p class="checkbox-sublabel" id="withCardSubLabel">
            {{"Tap your card to start charging." | T}}
        </p>
      </div>
  </div>

  <div class="custom-control custom-radio card-radio get-card-radio-container" *ngIf='canShowCPCardRadios()' aria-live="assertive" >
    <div id="use-my-chargepoint-card-description" class="visually-hidden">
      {{ "If you already have a ChargePoint card, you can use it to start charging. You can also get a free ChargePoint card if you’d prefer." | T }}
    </div>
    <input 
      type="radio"
      id="order-card" 
      value="send-me-card" 
      class="custom-control-input" 
      name="cardChckBx"
      #orderCard 
      [formControl]="cardOptions" 
      (change)="changeCardOptions('send-me-card')"
      tabindex="0"
      aria-describedby="order-card-help"
      />
    <label class="custom-control-label" for="order-card">{{"Get a ChargePoint Card"|T}}</label>
    <p id="order-card-help">{{"Delivered within 7-10 business days. First card is free." | T}}</p>
    <cp-address-form [setParentForm]="cpCardsGroup" [controlnamePrefix]="addressPrefix" *ngIf="orderCard.checked"
                     [needValidate]="validateAddress" [pressedSubmit]="pressedSubmit"></cp-address-form>
  </div>
  <div class="support-content" *ngIf="supportHelpContent && supportHelpContent.supportTranslated">
    <b>{{supportHelpContent.label}}</b>
    <p>{{supportHelpContent.supportTranslated}}</p>
  </div>

  <div class="custom-control custom-radio card-radio" *ngIf='canShowCPCardRadios() || shouldShowOnlySerialLayout() '>
    <input 
      type="radio" 
      id="I-have-card" 
      value="i-have-card"  
      class="custom-control-input" 
      name="cardChckBx"
      [formControl]="cardOptions" 
      #iHaveCard 
      (change)="changeCardOptions('i-have-card')"
      aria-describedby="use-my-chargepoint-card-description"
      tabindex="0"
      *ngIf="!layoutConfig.showIhaveACardOnly"
      />
    <label class="custom-control-label" for="I-have-card" *ngIf="!layoutConfig.showIhaveACardOnly">{{"I have a card"|T}}</label>
    <div class="add-cp-cards-container" formArrayName="cards" *ngIf="(cardOptions.value === 'i-have-card') || layoutConfig.showIhaveACardOnly">
      <div class="row">
        <div class="col-md-8">
          <div class="img-responsive driver-card-signup-image  d-lg-none" role="img" [attr.title]="'rfidCardAriaLabel' | T"></div>
          <div *ngFor="let card of cpCards.controls; let i=index" [formGroupName]="i">
            <div>
              <div class="form-group minus-container">
                <div aria-live="assertive" class="visually-hidden" >
                  {{"aNewSetOfInputsHaveBeenAdded" | T}}
                </div>
                <input type="text" 
                  class="form-control serialnumber" 
                  data-private="lipsum" 
                  formControlName="serialNumber" 
                  placeholder="{{'Card Serial Number'|T}}"
                  [attr.aria-label]="'Card Serial Number' | T"
                  [class.invalid]="updateError(i, 'serialNumber')" 
                  [id]="'serialNumber' + i"
                  [attr.aria-describedby]="'serialNumberError' + i"
                  aria-describedby="numberLocatedHelpText"
                  #cardSerialNumber
                  [attr.aria-required]="requiredCardInputs ? 'true' : null"
                  [required]="requiredCardInputs"
                  />
                  <button *ngIf="i !== 0"
                    type="button"
                    class="material-icons add-remove-icon minus"
                    (click)="removeCard(i)"
                    [attr.aria-label]="'removeCardAriaLabel' | T">
                      <span class="visually-hidden">{{'removeCardAriaLabel' | T}}</span>
                      remove_circle
                  </button>
              </div>
              <div *ngIf="updateError(i, 'serialNumber')" class="has-error">
                <div *ngIf="getCardError(i) && getCardError(i).required" [id]="'serialNumberError' + i">
                  <span *ngIf="'Card Serial Number' | T as translatedSerialNumberLabel">
                    {{ "errorMsgRequiredField" | T: {fieldName: translatedSerialNumberLabel} }}
                  </span> 
                </div>
                <div *ngIf="getCardError(i) && getCardError(i).pattern" [id]="'serialNumberError' + i">
                  {{ "Invalid serial number." | T}}
                </div>
                <div *ngIf="getCardError(i) && getCardError(i).server" [id]="'serialNumberError' + i">
                  {{ getCardError(i).message }}
                </div>
              </div>
            </div>
            <div>
              <div class="form-group">
                <input 
                  type="text" 
                  class="form-control nickname" 
                  data-private="lipsum" 
                  formControlName="nickname"
                  placeholder="{{'Card Nickname'|T}}" 
                  [attr.aria-label]="'Card Nickname' | T" 
                  [class.invalid]="updateError(i, 'nickname')"
                  [id]="'nickname' + i"
                  [attr.aria-describedby]="'nicknameError' + i"
                  [attr.aria-required]="requiredCardInputs ? 'true' : null"
                  [required]="requiredCardInputs" 
                  />
              </div>
              <div *ngIf="updateError(i, 'nickname')" class="has-error" [id]="'nicknameError' + i">
                <span *ngIf="'Card Nickname' | T as translatedNickNameLabel">
                  {{ "errorMsgRequiredField" | T: {fieldName: translatedNickNameLabel} }}
                </span> 
              </div>
            </div>
          </div>
          <p *ngIf="layoutConfig.showNumberIsOnBack" id="numberLocatedHelpText">{{"Number is located on the back of your ChargePoint card"|T}}</p>
          <button type="button" (click)="addCard($event)" class="add-cp-card-link btn btn-link">+ {{addAnotherCardTxt|T}}</button>
        </div>

        <div class="col-md-4">
          <div class="img-responsive driver-card-signup-image d-none d-lg-block" role="img" [attr.title]="'rfidCardAriaLabel' | T" ></div>
        </div>
      </div>
    </div>
  </div>

  
</div>
<cp-signup-footer-controls (nextClicked)="submitForm()" [showBackButton]="showBackButton" focusOnError="true"></cp-signup-footer-controls>
</form>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppFocusOnErrorDirective } from '../directives/app-focus-on-error.directive';



@NgModule({
  declarations: [AppFocusOnErrorDirective],
  imports: [
    CommonModule
  ],
  exports: [AppFocusOnErrorDirective]
})
export class SharedDirectiveModule { }

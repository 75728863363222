import { Address, AddressWithZip } from './address-with-zip';

export class Profile {
  fullName: string;
  givenName: string;
  familyName: string;
  username: string;
  email: string;
  password: string;
  address: Address;
  constructor(formObj: any) {

    if (formObj.fullName) {
      this.fullName = formObj.fullName;
    }
    if (formObj.givenName) {
      this.givenName = formObj.givenName;
    }
    if (formObj.familyName) {
      this.familyName = formObj.familyName;
    }
    if (formObj.username) {
      this.username = formObj.username;
    }
    if (formObj.password) {
      this.password = formObj.password;
    }

    this.email =  formObj.email;
    this.address = new Address({
      address1: formObj.profile_address1,
      address2: formObj.profile_address2,
      city: formObj.profile_city,
      zipCode: formObj['profile_zipCode'],
      countryId: formObj.profile_countryId,
      stateId: formObj.profile_stateId
    });
  }
}

export class Account {
  sendRfid: boolean;
  rfidAddress: Address;
  vehicle: Vehicle;
  notifications: Notification;
  cards: Array<Card>;
  constructor(formObj: any) {
    if (!formObj['I-dont-own-ev']) {
      this.vehicle = new Vehicle(formObj);
    }

    if (formObj.notificationOptIn) {
      this.notifications = new Notification(formObj);
    }

    if (formObj['cp-card-options'] === 'send-me-card') {
      this.sendRfid = true;
      if (formObj['cp-card-address_address1']) {
        this.rfidAddress = {
          address1: formObj['cp-card-address_address1'],
          address2: formObj['cp-card-address_address2'],
          city: formObj['cp-card-address_city'],
          zipCode: formObj['cp-card-address_zipCode'],
          countryId: formObj['cp-card-address_countryId'],
          stateId: formObj['cp-card-address_stateId']
         };
      }

    } else if (formObj['cp-card-options'] === 'i-have-card') {
      this.cards = formObj.cards.map((card) => {
        return new Card(card);
      });
    }
  }
}


export class Charge {
  phone: string;
  cards: Array<Card>[];
  sendRfid: boolean;
  rfidAddress: AddressWithZip;

  constructor(formObj: any) {
    if (formObj['cp-card-options'] === 'send-me-card') {
      this.sendRfid = true;
      if (formObj['cp-card-address_address1']) {
        this.rfidAddress = {
          address1: formObj['cp-card-address_address1'],
          address2: formObj['cp-card-address_address2'],
          city: formObj['cp-card-address_city'],
          zipCode: formObj['cp-card-address_zipCode'],
          countryId: formObj['cp-card-address_countryId'],
          stateId: formObj['cp-card-address_stateId']
         };
      }

    } else if (formObj['cp-card-options'] === 'i-have-card' || formObj['cp-card-options'] === null) {
      this.cards = formObj.cards.map((card) => {
        return new Card(card);
      });
    }
  }

}

export class Payment {
  paymentId: number;
  constructor(formObj: any) {
    this.paymentId = formObj.paymentId;
  }
}

export class DeviceData {
  manufacturer: string;
  model: string;
  type = 'web';
  notificationId: string;
  udid: string;
  notificationIdType: string;
  version: string;
}

export class DriverConnection {
  token: string;
  connectionFields: Array<ConnectionField>;
  constructor(formObj: any) {
    this.token = formObj.token;
    this.connectionFields = formObj.connectionFields.map(x => {
      return new ConnectionField(x);
    });
  }
}

export class DriverConnectionFuelCard extends DriverConnection {
  card: Card;
  constructor(formObj: any) {
    super(formObj);
    this.card = new Card(formObj.card);
  }
}

/** starting types */

export class Vehicle {
  colorId: number;
  makeId: number;
  modelId: number;
  primaryVehicle = true;
  year: number;
  constructor(formObj: any) {
    this.colorId = formObj.colorId;
    this.makeId = formObj.makeId;
    this.modelId = formObj.modelId;
    this.year = formObj.year;
  }
}


export class Card {
  nickname: string;
  serialNumber: string;
  constructor(formCard: any) {
    this.nickname = formCard.nickname;
    this.serialNumber = formCard.serialNumber;
  }
}

export class ConnectionField {
  id: number;
  value: string;
  constructor(formObj: any) {
    this.id = formObj.id;
    this.value = formObj.value;
  }
}


export class Notification {
  homeCharger: ChargingStation;
  notificationMethod: NotificationMethod;
  publicCharging: PublicChargingStation;
  userInfo: UserInfo;
  constructor(formObj) {
    this.notificationMethod = new NotificationMethod(formObj);
    this.publicCharging = new PublicChargingStation(formObj);
    this.homeCharger = new ChargingStation(formObj);
    this.userInfo = new UserInfo(formObj);
  }
}

export class ChargingStation {
  chargingInterrupted: boolean;
  fullyCharged: boolean;
  constructor(formObj) {
    this.chargingInterrupted = formObj.notifySessionInterrupt;
    this.fullyCharged = formObj.notifyFullCharge;
  }
}

export class PublicChargingStation {
  receipt: boolean;
  constructor(formObj) {
    this.receipt = formObj.notifyReceipt;
  }
}

export class NotificationMethod {
  email: boolean;
  push: boolean;
  text: boolean;
  constructor(formObj) {
    this.email = formObj.notificationEmailChkBox ? true : false;
    this.text = formObj.notificationSMSChkBox ? true : false;
    this.push = false;
  }
}

export class UserInfo {
  countryId: number;
  email: string;
  phone: string;
  constructor(formObj) {
    if (formObj.notificationEmailChkBox) {
      this.email = formObj.notificationEmail;
    }
    if (formObj.notificationSMSChkBox) {
      this.phone = formObj.notifications_phone;
      this.countryId = formObj.profile_countryId;
    }
  }
}

export class HomeCharger {
  installerLocation: string;
  address: Address;
  phoneNumber: string;
  dialingCode: number;
  constructor(formObj) {
    this.installerLocation = formObj.whereIsCarParked;
    this.dialingCode = formObj.homecharger_country_dial_code;
    this.phoneNumber = formObj.homecharger_phone;
    if (formObj.homeCharger_address1) {
      this.address = new Address({
        address1: formObj.homeCharger_address1,
        address2: formObj.homeCharger_address2,
        city: formObj.homeCharger_city,
        zipCode: formObj['homeCharger_zipCode'],
        countryId: formObj.homeCharger_countryId,
        stateId: formObj.homeCharger_stateId
      });
    }
  }
}

export class HomeChargerStatus {
  companyName: string;
  phoneNumber: string;
  dialingCode: number;
  connectionName: string;
  currencySymbol: string;
  status: string;
  address: AddressWithZip;
  constructor(obj) {
    this.companyName = obj.companyName;
    this.status = obj.status;
    if (obj.address) {
      this.address = obj.address;
    }
    if (obj.phoneNumber) {
      this.phoneNumber = obj.phoneNumber;
    }
    if (obj.dialingCode) {
      this.dialingCode = obj.dialingCode;
    }
    if (obj.connectionName) {
      this.connectionName = obj.connectionName;
    }
    if (obj.currencySymbol) {
      this.currencySymbol = obj.currencySymbol;
    }
  }
}

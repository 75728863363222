import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../app-interfaces/interfaces';
import { catchError, map } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import { DiscoveryStoreService } from '../services/discovery.service';
import { LanguageService } from '../services/language.service';

const PROFILE_VALIDATE_ACTION = 'driver/profile/user/validateuserdata';
const META_VALIDATE_ACTION = 'metadata/';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  private appConfig: AppConfig;
  private httpOptions: Object;

  constructor(private http: HttpClient, private discoveryService: DiscoveryStoreService, private languageService: LanguageService) {
    this.httpOptions = {
      withCredentials: true,
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': this.languageService.getLocale(),
      })
    };
  }

  isEmailTaken(email) {
    return this.validateProfileData(JSON.stringify({email: email}));
  }

  isUserNameTaken(name) {
    return this.validateProfileData(JSON.stringify({username: name}));
  }

  isZipValid(zip, country) {
    return this.validateProfileData(JSON.stringify({address: {zipCode: zip, countryId: country}}));
  }

  isDataValid(formObj: any) {
    const data = {
      fullName: '',
      username: formObj.username,
      email:  formObj.email,
      password: formObj.password,
      address: {
        address1: formObj.profile_address1,
        address2: formObj.profile_address2,
        city: formObj.profile_city,
        zipCode: formObj['profile_zipCode'],
        countryId: formObj.profile_countryId,
        stateId: formObj.profile_stateId
      }
    };

    if (formObj.fullName !== undefined) {
      data.fullName = formObj.fullName;
    } else if (formObj.firstname !== undefined) {
      data.fullName = formObj.firstname + ' ' + formObj.lastname;
    } else if (formObj.givenName !== undefined) {
      data.fullName = formObj.givenName + ' ' + formObj.familyName;
    }

    const paramStr = JSON.stringify(data);
    return this.validateProfileData(paramStr)
  }

  private validateProfileData(paramStr: string) {
   
    return this.http.post<any>(
      `${this.discoveryService.getAccountV2ApiEndpoint}${PROFILE_VALIDATE_ACTION}`,
      paramStr,
      this.httpOptions
    ).pipe(
      map(response =>  {
        return {error: false, message: 'ok', errorId: 0};
      }),
      catchError((err) => {
        const result = {error: true, message: 'error', errorId: 0};
        if (err.error && err.error.errorMessage) {
          result.message = err.error.errorMessage;
          if (err.error.errorId) {
            result.errorId = err.error.errorId;
          }
        }
        return of(result);
      })
    );
  }

  isSerialValid(number: string) {
    const data = {serialNumber: [number]};
    return this.validateMetaData('serialnumber/validate', JSON.stringify(data));
  }

  isPhoneValid(phoneNumber: string, country: number) {
    const data = {
      phone: phoneNumber,
      countryId: country
    };
    return this.validateMetaData('phone/validate', JSON.stringify(data));
  }

  private validateMetaData(action: string, params: any) {
    return this.http.post<any>(
      `${this.discoveryService.getAccountV1ApiEndpoint()}/${META_VALIDATE_ACTION}${action}`,
      params,
      this.httpOptions
    ).pipe(
      map(response =>  {
        return {error: false, message: 'ok', errorId: 0};
      }),
      catchError((err) => {
        const result = {error: true, message: 'error', errorId: 0};
        if (err.error && err.error.errorMessage) {
          result.message = err.error.errorMessage;
          if (err.error.errorId) {
            result.errorId = err.error.errorId;
          }
        }
        return of(result);
      })
    );
  }

  getAgreements(countryCode: string) {
    if (!countryCode) {
      return of([]);
    }
    return this.http.get(`${this.discoveryService.getNOSApiEndpoint()}index.php/discovery/getAgreements/${countryCode}`).pipe(
      catchError((err) => {
        return [];
      })
    );
  }

  getAgreementsHmtl(urls: Array<string>) {
    const aggreementObservables = [];
    urls.forEach(url => {
      aggreementObservables.push(this.http.get(url, {responseType: 'text'}).pipe(
        catchError((err) => {
          return '';
        })
      ));
    });
    return forkJoin(aggreementObservables);
  }
}

import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ChangeDetectorRef,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommunicationService } from '../../services/communication.service';
import { PhoneWCountryComponent } from '../../shared/phone-w-country/phone-w-country.component';
import { LanguageService } from 'src/app/services/language.service';
import { LangPipe } from 'src/app/pipes/lang.pipe';

@Component({
  selector: 'cp-notification-delivery',
  templateUrl: './notification-delivery.component.html',
  styleUrls: ['./notification-delivery.component.scss']
})
export class NotificationDeliveryComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() pressedSubmit: boolean;
  @Input()
  set notificationFormGroup(grp) {
    this.notifications = grp;
    this.initFormGroup(this.notifications);
  }
  notifications: FormGroup;
  isValidatePhone = false;
  userCountryId: number;
  langPipe: LangPipe;
  translatedEmailLabel: string;
  @ViewChild('notificationPhone') notificationPhone: PhoneWCountryComponent;
  constructor(
    private ref: ChangeDetectorRef,
    private comsvc: CommunicationService,
    private langSvc: LanguageService
    ) {
    this.langPipe = new LangPipe(this.langSvc);
    }

  ngOnInit() {
    this.translatedEmailLabel = this.langPipe.transform('Email');
  }

  ngOnChanges() {
    this.ref.detectChanges();
  }

  ngAfterViewInit() {
    const address = this.comsvc.getMainAddress();
    if (this.notificationPhone) {
      this.notificationPhone.$countries.subscribe(countries => {
        if (address && address.countryId) {
          this.userCountryId = address.countryId;
          const country = countries.find(c => {
            return c.id === address.countryId;
          });
          this.notificationPhone.selectCountry(country);
        } else {
          this.userCountryId = countries[0].id;
          this.notificationPhone.selectCountry(countries[0]);
        }
      });
      this.notificationPhone.focus();
    }
  }

  initFormGroup(grp: FormGroup) {
    grp.addControl('notificationOptIn', new FormControl(true));
    grp.addControl('notifyFullCharge', new FormControl(true));
    grp.addControl('notifySessionInterrupt', new FormControl(true));
    grp.addControl('notifyReceipt', new FormControl(true));
    grp.addControl('notifyReceipt', new FormControl(true));
    grp.addControl('notificationEmailChkBox', new FormControl(false));
    grp.addControl('notifications_phone', new FormControl(''));
    grp.addControl('notifications_country_dial_code', new FormControl(''));
    grp.addControl('notificationEmail', new FormControl('', {
      validators: [Validators.required, Validators.email],
      updateOn: 'blur'
    }));
    grp.addControl('notificationSMSChkBox', new FormControl(true));

    grp.patchValue(this.comsvc.getMasterObject());

    if (!grp.get('notificationEmail').value || !grp.get('notificationEmail').valid) {
      grp.get('notificationEmail').setValue(this.comsvc.getMasterObject()['email']);
    }
    if (!grp.get('notifications_phone').value) {
      grp.get('notifications_phone').setValue(this.comsvc.getPhoneNumber());
    }
    if (grp.get('notificationSMSChkBox').value) {
      this.isValidatePhone = true;
    }
  }

  checkedEmail(e) {
    if (e.target.checked) {
      this.notifications.get('notificationEmail').setValidators([Validators.required, Validators.email]);
    } else {
      this.notifications.get('notificationEmail').clearValidators();
      this.notifications.get('notificationEmail').updateValueAndValidity();
    }
  }

  checkedSMS(e) {
    if (!this.notifications.get('notifications_country_dial_code').value) {
      this.notificationPhone.setCountry(this.comsvc.getMasterObject()['profile_countryId']);
    }
    if (e.target.checked) {
      this.isValidatePhone = true;
    } else {
      this.isValidatePhone = false;
    }
    this.ref.detectChanges();
  }

  // this function helps clean up the HTML
  updateError(type) {
    return (this.notifications.get(type).invalid && this.notifications.get(type).touched)
      || (this.notifications.get(type).invalid && this.pressedSubmit);
  }
}

<div [formGroup]="evInfo" *ngIf="evInfo">
  <h1>{{"What are you driving?" | T}}</h1>
  <p>{{"Tell us what you drive. We’ll estimate range and cost of charging."|T}}</p>
  <div class="row" [ngStyle]="{display: (noEV.checked ? 'none': 'grid')}">
    <div class="col-md-10 input-container" *ngIf="fieldsConfig">
      <cp-select [setParentForm]="evInfo" [setConfig]="fieldsConfig.makeId" [options]="makes" (valueChange)="updateModels($event)" [addValidation]="needValidate" [pressedSubmit]="pressedSubmit" [setFocus]="true"></cp-select>
      <cp-select aria-live="polite" [ngStyle]="{display: (models && models.length ? 'block': 'none')}" [setParentForm]="evInfo" [setConfig]="fieldsConfig.modelId" [options]="models" (valueChange)="updateYears($event)" [addValidation]="needValidate" [pressedSubmit]="pressedSubmit"></cp-select>
      <cp-select aria-live="polite" [ngStyle]="{display: (years && years.length ? 'block': 'none')}"[setParentForm]="evInfo" [setConfig]="fieldsConfig.year" [options]="years" (valueChange)="updateColors($event)" [addValidation]="needValidate" [pressedSubmit]="pressedSubmit"></cp-select>
      <cp-select aria-live="polite" [ngStyle]="{display: (colors && colors.length? 'block': 'none')}"[setParentForm]="evInfo" [setConfig]="fieldsConfig.colorId" [options]="colors" (valueChange)="updateCarImage($event)" [addValidation]="needValidate" [pressedSubmit]="pressedSubmit"></cp-select>

    </div>
    <div class="col-md-6 car-image-container" *ngIf="carImage">
      <img class="vehicle-color" src="{{carImage}}" alt="" (error)="onImageError()">
    </div>
  </div>

  <div class="custom-control custom-checkbox own-ev-chckbox">
    <input type="checkbox" class="custom-control-input" id="dont-have-ev" formControlName="I-dont-own-ev" #noEV (change)="updateValidators($event)"/>
    <label class="custom-control-label" for="dont-have-ev">{{"I don’t own an EV."|T}}</label>
  </div>
</div>

export const FIELD_PLACEHOLDER = {
  email: 'Email',
  username: 'Username',
  password: 'Password',
  givenName: 'Given name',
  familyName: 'Family name',
  fullName: 'First and last name',

  nickname: 'Card Nickname',
  serialNumber: 'Card Serial Number',

  address1: 'Address 1',
  address2: 'Address 2',
  city: 'City',
  countryId: 'Select Country',
  stateId: 'Select State',
  zipCode: 'Postal Code',

  makeId: 'Select Make',
  modelId: 'Select Model',
  year: 'Select year',
  colorId: 'Select Color'
};

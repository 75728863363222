export enum Environment  {
    Production = 'prod',
    QA = 'qa',
    LocalDev = 'localdev',
};

export default interface EnvironmentInterface {
    production: boolean;
    DISCOVERY_API: string;
    DRIVER_PORTAL_URL: string;
    envType: Environment;
    MIXPANEL_PROJECT_TOKENS: {
        'NA-CA': string,
        'NA-US': string,
        'ANZ': string,
        'EU': string,
    }
}
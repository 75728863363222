import { Injectable } from '@angular/core';
import { AppConfigService } from '../services/app-config.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ReCaptchaService {
  siteKey: string;

  constructor(private appConfig: AppConfigService) {
    this.loadReCaptcha();
  }

  loadReCaptcha() {
    this.appConfig.getConfig().subscribe((config) => {
      this.siteKey = config.reCaptchaApiKey;
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${this.siteKey}`;
      script.type = 'text/javascript';
      script.async = true;
      script.defer = true;

      if (!document.body.contains(script)) {
        document.head.appendChild(script);
      }
    });
  }

  public getToken(userAction: string): Observable<string> {
    const subject = new Subject<string>();
    window['grecaptcha'].enterprise.execute(
      this.siteKey,
      {action: userAction}
    ).then((token: string) => {
      subject.next(token);
      subject.complete();
    }, (error: any) => {
      subject.error(error);
    });

    return subject.asObservable();
  }
}

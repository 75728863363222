import {
  trigger,
  animate,
  transition,
  style,
  query,
  group,
  state
} from '@angular/animations';


    export const routeSlide =  trigger('routeSlide', [
      transition('* <=> *', [
        group([
          query(':enter', [
            style({transform: 'translateX(100%)'}),
            animate('0.4s ease-in-out', style({transform: 'translateX(100%)'}))
          ], {optional: true}),
          query(':leave', [
            style({transform: 'translateX(0%)'}),
            animate('0.4s ease-in-out', style({transform: 'translateX(100%)'}))
          ], {optional: true}),
        ])
      ]),
    ]);

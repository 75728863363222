import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LanguageService } from './services/language.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { HelmetService } from './services/helmet.service';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'cp-signup';

  constructor(
    public sanitizer: DomSanitizer, 
    private langSvc: LanguageService, 
    private router: Router, 
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private helmetService: HelmetService,) {
    // change language without reloading page
    this.langSvc.langChanged.subscribe(lang => {
      if (lang) {
        this.router.navigateByUrl('/dc-lang-change', { skipLocationChange: true }).then(() => {
          this.router.navigateByUrl(this.location.path());
        });
        this.helmetService.updateHTMLLang(this.langSvc.getLocale());
      }
    });
  }

  ngOnInit() {
    this.router.events.pipe(
      filter((evt) => evt instanceof NavigationEnd)
    ).subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.helmetService.updateTitle(this.activatedRoute);
        if (environment.production) {
          console.log(`%cActivated Route: ${this.activatedRoute}`, 'background: #222; color: #bada55' );
          console.log(`%cNavigation event: ${JSON.stringify(evt)}`, 'background: #222; color: #bada55' );
        }
        
      }
    });
  }

  loadCSS(fileSrc: string) {
    window['CP'].loadCSS(fileSrc);
  }
}

import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { CpInputComponent } from '../cp-input/cp-input.component';
import { LanguageService } from 'src/app/services/language.service';
import { AriaAnnouncementService } from 'src/app/services/aria-announcement.service';

@Component({
  selector: 'cp-user-email',
  templateUrl: './cp-user-email.component.html',
  styleUrls: ['./cp-user-email.component.scss']
})
export class CpUserEmailComponent extends CpInputComponent implements OnInit, AfterViewInit  {

  @Input() showLoginPrompt = true;
  @Input() ariaLabelOveride: string; // optional aria-label overide; othewise, will use the translated placeholder.

  constructor(private languageService: LanguageService, announcementService: AriaAnnouncementService) {
    super(languageService, announcementService);
  }

  updateError() {
    // fix for DEN flow on pre-fill existing email
    if (this.showLoginPrompt && this.control.hasError('emailExists')) {
      return true;
    }

    return (((this.control.touched && !this.control.pristine) || this.pressedSubmit) && this.control.invalid);
  }
}

import { Component, OnInit } from '@angular/core';
import { ConnectionComponent } from '../connection/connection.component';
import { FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ValidationService } from '../services/validation.service';
import { ConnectionService } from '../connection/connection.service';
import { CommunicationService } from '../services/communication.service';
import { CPMixPanel } from '../services/mixpanel.service';
import { FrontendContentConfigService } from '../services/frontend-content-config.service';
import { DriverConnectionFuelCard } from '../services/models/backend-save-classes';
import { UiFlowStateService } from '../services/ui-flow-state.service';

@Component({
  selector: 'cp-driver-connection-fuelcard',
  templateUrl: './driver-connection-fuelcard.component.html',
  styleUrls: ['./driver-connection-fuelcard.component.scss'],
})
export class DriverConnectionFuelCardComponent extends ConnectionComponent implements OnInit {
  cardSerialNumber: FormControl<string>;
  cardNickName: FormControl<string>;
  constructor(
    connectionService: ConnectionService,
    public commSvc: CommunicationService,
    mixPanel: CPMixPanel,
    frontEndConfigSvc: FrontendContentConfigService,
    stateSvc: UiFlowStateService,
    private validationService: ValidationService
  ) {
    super(connectionService, commSvc, mixPanel, frontEndConfigSvc, stateSvc);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.cardSerialNumber = new FormControl('', Validators.required, this.validationService.validateSerialNumber());
    this.cardNickName = new FormControl('', Validators.required);
    this.connectionForm.addControl('cardSerialNumer', this.cardSerialNumber);
    this.connectionForm.addControl('cardNickName', this.cardNickName);
  }

  submitValues() {
    this.pressedSubmit = true;
    if (this.connectionForm.valid) {
      const finalObj = {
        token: this.token,
        connectionFields: [],
        card: {
          serialNumber: this.cardSerialNumber.value,
          nickname: this.cardNickName.value
        }
      };
      if (this.connectionFields.connectionFields) {
        finalObj.connectionFields = this.connectionFields.connectionFields.map((field) => {
          return {
            id: field.id,
            value: this.connectionForm.get(field.key).value
          };
        });
      }
      const backendSaveObj = new DriverConnectionFuelCard(finalObj);
      this.commSvc.submitFormFields(finalObj, backendSaveObj);
      // CPSRV-47280 Corner case with error from account
      this.stateSvc.$serverErrorMessage.subscribe(error => {
        if (error) {
          this.isLoading = false;
        }
      });
    } else {
      if (this.connectionForm.status === 'PENDING') {
        setTimeout(() => {
          this.submitValues();
        }, 500);
      }
    }
  }
}

<cp-loader *ngIf="showLoader"></cp-loader>
<div>
  <div class="login-container">
    <h1 class="title">{{"Log In" | T}}</h1>
    <div class="col-sm">
      <div class="form-group">
        <input type="text" class="form-control" data-private="lipsum" [formControl]='username' placeholder="{{'Username or Email' | T }}" required="true">
        <div *ngIf="showErrors" class="has-error">
          <div *ngIf="username.errors && username.errors.required">
            {{"Username is required." | T}}
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm">
      <div class="form-group">
        <input type="password" class="form-control" data-private="lipsum" [formControl]="password" (keyup.enter)="login()" placeholder="{{'Password' | T }}" required="true">
        <div *ngIf="showErrors" class="has-error">
            <div *ngIf="password.errors && password.errors.required">
              {{"Password is required." | T}}
            </div>
          </div>
      </div>
    </div>
    <h6 *ngIf="serverError" class="has-error text-center">
      {{serverError}}
    </h6>

    <!--- privacy policy -->
    <p class="termsconditions" *ngIf="partnerTermsOfServiceLink !== null">
      <input [formControl]="privacyPolicyAccept" type="checkbox"/>
      <span [innerHtml]="'I agree to the %link%.'  | T : {link: partnerTermsOfServiceLink}"></span>
    </p>

    <div class="button-container">
      <button type="submit" class="btn btn-lg btn-primary" [disabled]="partnerTermsOfServiceLink !== null && !privacyPolicyAccept.value" (click)="login()">{{"Log In" | T}}</button>
    </div>

    <h3>
      <a [href]="getForgotPasswordUrl()" target="_blank">
        {{"Forgot password?" | T}}
      </a>
    </h3>

  </div>
</div>

import { Component, HostListener, OnInit } from '@angular/core';
import { GetHomeChargerService } from '../get-home-charger/get-home-charger.service';
import { CommunicationService } from '../services/communication.service';
import { CPMixPanel } from '../services/mixpanel.service';
import { FrontendContentConfigService } from '../services/frontend-content-config.service';
import { UiFlowStateService } from '../services/ui-flow-state.service';

@Component({
  selector: 'cp-home-charger-done',
  templateUrl: './home-charger-done.component.html',
  styleUrls: ['./home-charger-done.component.scss']
})
export class HomeChargerDoneComponent implements OnInit {
  chargePointInstall = false;
  companyName = '';
  @HostListener('window:beforeunload', ['$event'])
  closePage($event: any) {
    this.uiFlowState.clearSessionStorage();
  }

  constructor(
    protected homeChargerSvc: GetHomeChargerService,
    protected comSvc: CommunicationService,
    protected mixPanel: CPMixPanel,
    protected frontendConfigSvc: FrontendContentConfigService,
    protected uiFlowState: UiFlowStateService
  ) { }

  ngOnInit() {
    this.uiFlowState.setSignUpFinished();
    this.homeChargerSvc.getHomeChargerStatus().subscribe(statusObj => {
      if (statusObj.companyName) {
        this.companyName = statusObj.companyName;
      }
    });
    this.chargePointInstall = this.comSvc.isInstallHomeCharger();
    this.frontendConfigSvc.setBodyTitle('successTitle');
  }

  clickDownload(market: string) {
    if (market === 'apple') {
      this.mixPanel.successAppleApp();
    } else if (market === 'google') {
      this.mixPanel.successGoogleApp();
    }
  }
}

<div class="form-group" [formGroup]="parentForm" *ngIf="fieldConfig">
  <select 
    class="form-select"
    data-private="lipsum"
    #textInput 
    [formControlName]="fieldConfig.name"
    [class.invalid]="updateError()"
    (change)="emitChange($event)"
    [attr.aria-label]="translatedPlaceholder"
    [attr.aria-describedby]="inputId + 'error'"
    [required]="required"
    >
      <option *ngIf="fieldConfig.placeholder && options && options.length" [ngValue]="" value="" selected>-- {{fieldConfig.placeholder |T}} --</option>
      <option *ngFor="let option of options" [ngValue]="option.id">{{option.name}}</option>
  </select>
  <div *ngIf="updateError()" class="has-error" [id]="inputId + 'error'">
    {{"errorMsgRequiredField" | T: {fieldName: translatedPlaceholder} }}
  </div>
</div>

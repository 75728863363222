import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CommunicationService } from '../services/communication.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { map, catchError, share } from 'rxjs/operators';
import { HomeCharger, HomeChargerStatus } from '../services/models/backend-save-classes';
import { DiscoveryStoreService } from '../services/discovery.service';
import * as Sentry from '@sentry/browser';


@Injectable({
  providedIn: 'root'
})
export class GetHomeChargerService {
  homeChargerFormGroup = new FormGroup({
    whereIsCarParked : new FormControl('garage', Validators.required),
    installHomeCharger : new FormControl(false)
  });

  partnerToken = null;
  private homeChargerStatus: HomeChargerStatus;
  private observable: Observable<HomeChargerStatus>;

  constructor(private http: HttpClient, private comSvc: CommunicationService, private discoveryService: DiscoveryStoreService) {
    this.partnerToken = this.comSvc.getPartnerToken();
  }

  getCookies() {
    return this.http.get('/UI/cookies.php', {withCredentials: true});
  }

  iDontNeedHomeCharger() {
    this.comSvc.skipSubmitNextRoute();
  }

  getHomeChargerStatus() {
    if (this.homeChargerStatus) {
      return of(this.homeChargerStatus);
    } else if (this.observable) {
      return this.observable;
    } else {

      let apiEndpoint = `${this.discoveryService.getAccountV1ApiEndpoint()}/driver/station/request`;
      if (this.partnerToken) {
        apiEndpoint += '?token=' + this.partnerToken;
      }
      this.observable = this.http.get<any>(
        apiEndpoint,
        {withCredentials: true}
      ).pipe(
        map((resp: HomeChargerStatus) => {
          this.homeChargerStatus = new HomeChargerStatus(resp);
          return this.homeChargerStatus;
        }),
        share()
      );
      return this.observable;
    }
  }

  updateHomeChargerStatus(payload: HomeCharger) {
    return this.http.post<any>(
      `${this.discoveryService.getAccountV1ApiEndpoint()}/driver/station/request`,
      payload,
      { withCredentials: true,
        observe: 'response'
      }
    ).pipe(
      catchError((error) => {
        console.log(error);
        Sentry.captureException(error);
        return of(0);
        // TODO add code for error case. Needs discussion.
      }
      ),
      map((resp) => {
        return resp;
      })
    )
  }
}

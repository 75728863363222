import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FrontendContentConfigService } from 'src/app/services/frontend-content-config.service';
import { VerifyAccountService } from './verify-account.service';
import { LangPipe } from 'src/app/pipes/lang.pipe';
import { buttonText } from './translate';
import { LanguageService } from 'src/app/services/language.service';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { PhoneWCountryComponent } from 'src/app/shared/phone-w-country/phone-w-country.component';
import { CommunicationService } from 'src/app/services/communication.service';
import { Validators } from '@angular/forms';
import { CpInputComponent } from 'src/app/shared/cp-input/cp-input.component';
import { CPMixPanel } from 'src/app/services/mixpanel.service';
import { UiFlowStateService } from '../../services/ui-flow-state.service';
import { AriaAnnouncementService } from 'src/app/services/aria-announcement.service';


@Component({
  selector: 'cp-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss']
})
export class VerifyAccountComponent implements OnInit, AfterViewInit {
  formGroup: FormGroup;
  codeFormGroup: FormGroup;
  private langPipe: LangPipe;
  continueButtonText: string;
  serverErrorMessage: string = '';
  codeSent = false;
  pressedSubmit = false;
  codePressedSubmit = false;
  phoneNumber = '';
  submitFunction = this.verifyAccount;
  smsCodeInputConfig = {
    name: 'smscode',
    validations: [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(4)
    ],
    asyncValidations: [],
    placeholder: 'XXXX',
    inputMaxLength: 4
  };
  enteredNumber: string;
  isLoading = false;

  @ViewChild('phone')
  phone: PhoneWCountryComponent;

  @ViewChild('codeInput')
  codeInput: CpInputComponent;

  constructor(
    private frontEndConfig: FrontendContentConfigService,
    private verifyAccountSvc: VerifyAccountService,
    private langsvc: LanguageService,
    private commSvc: CommunicationService,
    private ref: ChangeDetectorRef,
    private mixpanel: CPMixPanel,
    private uiFlowState: UiFlowStateService,
    private ariaAnnouncementService: AriaAnnouncementService,
  ) {
    this.langPipe = new LangPipe(this.langsvc);
    this.continueButtonText = this.langPipe.transform(buttonText.send);
  }

  ngOnInit() {
    this.formGroup = new FormGroup({});
    this.codeFormGroup = new FormGroup({});
    this.frontEndConfig.setBodyTitle('verifyAccountTitle');
    if (this.commSvc.getMasterObject()['_phone']) {
      this.enteredNumber = this.commSvc.getMasterObject()['_phone'];
    }
  }

  ngAfterViewInit() {
    if (this.commSvc.getMasterObject()['_country_dial_code']) {
      this.phone.setCountryByCode(this.commSvc.getMasterObject()['_country_dial_code']);
    } else {
      this.phone.$countries.subscribe(countries => {
        const address = this.commSvc.getMainAddress();
        if (address && address.countryId) {
          const country = countries.find(c => {
            return c.id === address.countryId;
          });
          this.phone.selectCountry(country);
        } else {
          this.phone.selectCountry(countries[0]);
        }
      });
    }
   this.phone.focus();
  }

  backClick() {
    if (this.codeSent) {
      this.serverErrorMessage = '';
      this.codeSent = false;
      this.continueButtonText = this.langPipe.transform(buttonText.send);
      this.pressedSubmit = false;
      this.submitFunction = this.verifyAccount;
      setTimeout(() => {
        this.phone.focus();
      }, 200);
    } else {
      this.uiFlowState.prevRoute();
      if (this.commSvc.getMasterObject()['_country_dial_code']) {
        this.phone.setCountryByCode(this.commSvc.getMasterObject()['_country_dial_code']);
      }
    }
  }

  submitCode() {
    this.codePressedSubmit = true;
    this.ref.detectChanges();
    this.formGroup.updateValueAndValidity();
    if (this.codeFormGroup.invalid) {
      return;
    }
    const backendSaveObj = {
      code: this.codeFormGroup.value.smscode,
      countryId: this.formGroup.value.selectedCountryObj.id,
      number: this.formGroup.value._phone
    };
    this.commSvc.submitFormFields(this.formGroup.value, backendSaveObj);
    this.mixpanel.validatePhoneValidationCode();
  }

  verifyAccount(resend?: boolean) {
    if (resend) {
      this.mixpanel.resendPhoneValidationCode();
    }
    this.pressedSubmit = true;

    if (this.formGroup.status === 'PENDING') {
     const verifySubscription =  this.formGroup.statusChanges.subscribe(status => {
        if (status === 'VALID') {
          this.sendAccount();
          verifySubscription.unsubscribe();
        } else if (status === 'INVALID') {
          verifySubscription.unsubscribe();
        }
      });
    } else if (this.formGroup.status === 'VALID') {
      this.sendAccount();
    }
  }

  sendAccount() {
    this.isLoading = true;
    this.serverErrorMessage = '';
    const formValue = this.formGroup.value;
    const payload = {
      code: formValue.selectedCountryObj.code,
      countryId: formValue.selectedCountryObj.id,
      number: formValue._phone
    };
    this.commSvc.pushToMaster(this.formGroup.value);
    this.phoneNumber = '+' + formValue._country_dial_code + ' ' + formValue._phone;
    this.verifyAccountSvc.sendverifycode(payload).pipe(
      catchError(err => {
        if (err.error && err.error.errorMessage) {
          this.serverErrorMessage = err.error.errorMessage;
        }
        return of(undefined);
      })
    ).subscribe(resp => {
      this.isLoading = false;
      if (typeof resp !== 'undefined') {
        this.serverErrorMessage = '';
        this.codeSent = true;
        this.continueButtonText = this.langPipe.transform(buttonText.verify);
        this.pressedSubmit = false;
        this.submitFunction = this.submitCode;
        this.mixpanel.sendPhoneVerificationCode({
          'Country Calling Code': formValue.selectedCountryObj.callingCode,
          'ISO Country Code': formValue.selectedCountryObj.code,
          'Phone Number': formValue._phone
        });
        this.ariaAnnouncementService.setAnnouncement('ariaAnnouncementEnterCode');
        setTimeout(() => {
         this.codeInput.focus();
        }, 200);
      }
    });
  }
}

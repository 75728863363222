import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'cp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  year: number;
  locale: string;
  baseA11yUrl = 'https://driver.chargepoint.com/a11y?locale=';
  constructor(
    private langSvc: LanguageService,
  ) { }

  ngOnInit() {
    this.year = new Date().getFullYear();
    this.langSvc.localeEmmiter.subscribe(locale => {
      this.locale = locale;
    });
  }
  getA11yUrl() {
    return this.baseA11yUrl + this.locale;
  }
}

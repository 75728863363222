import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { LanguageService } from '../services/language.service';
import { DiscoveryStoreService } from '../services/discovery.service';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {
  companyName = '';
  httpsOptions = {
    headers: null,
    responseType: 'text' as 'text',
    withCredentials: true
  };

  constructor(private http: HttpClient, private langSvc: LanguageService, private discoveryService: DiscoveryStoreService) {
    this.httpsOptions.headers = new HttpHeaders({ 'Accept-Language': langSvc.getLocale() });
  }

  getFields(token) {
    return this.http.get(`${this.discoveryService.getAccountV2ApiEndpoint()}/driver/connection/configuration?token=${token}`, this.httpsOptions).pipe(
      map((resp: any) => {
        resp = JSON.parse(resp);
        this.companyName = resp.orgName;
        return resp;
      }),
      catchError(() => {
        return of({});
      })
    );
  }

  getTermsContent(link) {
    return this.http.get(link, this.httpsOptions).pipe(
      map((resp) => {
        return resp.replace(/<script[^>]*?>.*?<\/script>/gi, '');
      })
    );
  }
}

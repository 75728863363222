import { Component, OnInit, Input, OnChanges, ChangeDetectorRef, QueryList, ElementRef, AfterViewInit, ViewChildren } from '@angular/core';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CommunicationService } from '../../services/communication.service';
import { AppConfig } from '../../app-interfaces/interfaces';
import { AppConfigService } from '../../services/app-config.service';
import { ValidationService } from '../../services/validation.service';
import { FrontendContentConfigService } from 'src/app/services/frontend-content-config.service';

@Component({
  selector: 'cp-chargepoint-cards',
  templateUrl: './chargepoint-cards.component.html',
  styleUrls: ['./chargepoint-cards.component.scss']
})
export class ChargepointCardsComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChildren('cardSerialNumber') cardSerialNumbers: QueryList<ElementRef>;
  @Input() pressedSubmit: boolean;

  @Input()
  set cardFormGroup(inpFormGroup: FormGroup) {
    this.cpCardsGroup = inpFormGroup;
    this.initFormGroup(this.cpCardsGroup);
    this.cpCards = this.cpCardsGroup.get('cards') as FormArray;
    if (this.comsvc.getMasterObject()['cp-card-options'] && this.comsvc.getMasterObject()['cp-card-options'] === 'i-have-card') {
      this.setCardsValidation();
    } else {
      if (this.frontEndConfig.getLayoutConfig('cards') && this.frontEndConfig.getLayoutConfig('cards')['validateOnlySerial']) {
        this.requiredCardInputs = false;
        this.setOnlySerialNumberValidator();
      }
    }
  }
  cpCardsGroup: FormGroup;
  cpCards: FormArray;
  addressPrefix = 'cp-card-address';
  address1 = '';
  showTapToCharge = false;
  validateAddress = false;
  requiredCardInputs = true;

  constructor(
    private fb: FormBuilder,
    private ref: ChangeDetectorRef,
    private comsvc: CommunicationService,
    protected appConfigSvc: AppConfigService,
    private validationService: ValidationService,
    private frontEndConfig: FrontendContentConfigService
  ) {}

  ngOnInit() {
    const masterObj = this.comsvc.getMasterObject();
    if (!this.cpCardsGroup) {
      this.cardFormGroup = new FormGroup({});
    }
    if (masterObj) {
      this.address1 = masterObj['profile_address1'];
    }
    this.appConfigSvc.getConfig().subscribe((config: AppConfig) => {
      if (config.guest_tasks.includes('signup_tap_to_charge')) {
        this.showTapToCharge = true;
        this.cpCardsGroup.patchValue({'cp-card-options': 'usePhoneLater'});
      } else {
        this.cpCardsGroup.patchValue({'cp-card-options': 'send-me-card'});
      }
    });
  }
  ngAfterViewInit() {
    this.cardSerialNumbers.changes.subscribe(() => {
      this.setFocusToLastInput();
    });
  }

  ngOnChanges() {
    this.ref.detectChanges();
  }

  setFocusToLastInput() {
    const cardSerialNumbers = this.cardSerialNumbers.toArray();
    if (cardSerialNumbers.length > 0) {
      cardSerialNumbers[cardSerialNumbers.length - 1].nativeElement.focus();
    }
  }

  initFormGroup(grp: FormGroup) {
    grp.addControl('cp-card-options', new FormControl(''));
    grp.addControl('send-card-to-diff-addy', new FormControl(''));
    grp.addControl('cards', this.fb.array([this.createCpCardItem()]));
    grp.patchValue(this.comsvc.getMasterObject());
  }

  createCpCardItem() {
    return this.fb.group({
      nickname: '',
      serialNumber: ''
    }, {
      updateOn: 'change'
    });
  }

  addCard(e) {
    this.cpCards = this.cpCardsGroup.get('cards') as FormArray;
    this.cpCards.push(this.createCpCardItem());

    if ((this.frontEndConfig.getLayoutConfig('cards') && this.frontEndConfig.getLayoutConfig('cards')['validateOnlySerial'])) {
      this.setOnlySerialNumberValidator();
    } else  {
      this.setCardsValidation();
    }
    e.preventDefault();
  }

  removeCard(index) {
    this.cpCards.controls[index].clearValidators();
    this.cpCards.controls[index].updateValueAndValidity();
    this.cpCards.removeAt(index);
  }

  getCardError(i:number) {
    return this.cpCards.get(i.toString()).get('serialNumber').errors? this.cpCards.get(i.toString()).get('serialNumber').errors : false;
  }
  // this function helps clean up the HTML
  updateError(index, type) {
    return ((this.cpCards.get(index.toString()).get(type).touched || this.pressedSubmit)
      && this.cpCards.get(index.toString()).get(type).invalid);
  }

  changeCardOptions(e) {
    if (e.target.value === 'i-have-card') {
      this.setCardsValidation();
    } else {
      this.cleanValidation();
    }

    if (e.target.value !== 'send-me-card' && this.cpCardsGroup.get('send-card-to-diff-addy').value) {
      this.cpCardsGroup.get('send-card-to-diff-addy').reset(false);
      this.checkAddressValidation(true);
    }
  }

  setCardsValidation() {
    for (let i = 0; i < this.cpCards.controls.length; i++) {
      this.cpCards.get(i.toString()).get('nickname').setValidators([Validators.required]);
      this.cpCards.get(i.toString()).get('serialNumber').setValidators([Validators.required]);
      this.cpCards.get(i.toString()).get('serialNumber').setAsyncValidators([this.validationService.validateSerialNumber()]);
    }
  }

  setOnlySerialNumberValidator() {
    for (let i = 0; i < this.cpCards.controls.length; i++) {
      this.cpCards.get(i.toString()).get('serialNumber').setAsyncValidators([this.validationService.validateSerialNumber()]);
      // binding the validation of serial number to the input of nickname
      // if there is any input in nickname make the sibling serial number required
      this.cpCards.get(i.toString()).get('nickname').valueChanges.subscribe(value => {
        const serialNumber = this.cpCards.get(i.toString()).get('serialNumber') as any;
        if (!serialNumber.hasValidator) {
          serialNumber.hasValidator = true;
          serialNumber.setValidators([Validators.required]);
          serialNumber.markAsDirty();
          serialNumber.updateValueAndValidity();
        }
        if (value === '') {
          serialNumber.hasValidator = false;
          serialNumber.clearValidators();
          serialNumber.updateValueAndValidity();
        }
      })
    }
  }

  cleanValidation() {
    for (let i = 0; i < this.cpCards.controls.length; i++) {
      this.cpCards.get(i.toString()).get('nickname').clearValidators();
      this.cpCards.get(i.toString()).get('nickname').clearAsyncValidators();
      this.cpCards.get(i.toString()).get('nickname').updateValueAndValidity();
      this.cpCards.get(i.toString()).get('serialNumber').clearValidators();
      this.cpCards.get(i.toString()).get('serialNumber').clearAsyncValidators();
      this.cpCards.get(i.toString()).get('serialNumber').updateValueAndValidity();
    }
  }

  checkAddressValidation(isClear: boolean) {
    if (this.cpCardsGroup.get(this.addressPrefix + '_address1')) {
      if (isClear) {
        this.validateAddress = false;
      } else {
        this.validateAddress = true;
      }
    }
  }
}

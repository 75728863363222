import { Component, OnInit, HostListener } from "@angular/core";
import { UiFlowStateService } from "../services/ui-flow-state.service";
import { FrontendContentConfigService } from "../services/frontend-content-config.service";
import { LangPipe } from "../pipes/lang.pipe";
import { LanguageService } from "../services/language.service";
import { CommunicationService } from "../services/communication.service";
import { VIEW_ACCOUNT } from "../den-partner-success/translate";

@Component({
  selector: 'cp-generic-partner-success',
  templateUrl: './generic-partner-success.component.html',
  styleUrls: ['./generic-partner-success.component.scss'],
})
export class GenericPartnerSuccessComponent implements OnInit {
  @HostListener('window:beforeunload', ['$event'])
  closePage($event: any) {
    this.uiFlowState.clearSessionStorage();
  }
  redirectUrl = '';
  langPipe: LangPipe;
  nextButton = '';

  constructor(
    private frontEndConfig: FrontendContentConfigService,
    private communicationSvc: CommunicationService,
    private uiFlowState: UiFlowStateService,
    private langSvc: LanguageService
  ) {
    this.langPipe = new LangPipe(langSvc);
  }

  ngOnInit() {
    const REDIRECT_TIMEOUT = 5000; // ms
    this.uiFlowState.setSignUpFinished();
    this.frontEndConfig.setBodyTitle('successTitle');
    this.nextButton = this.langPipe.transform(VIEW_ACCOUNT);
    this.communicationSvc
      .getComponentDefinition('densignupsuccess')
      .subscribe((componentDefinition) => {
        if (componentDefinition && componentDefinition.fields) {
          componentDefinition.fields.forEach((field) => {
            if (field.id === 'cpRedirectUrl') {
              if (field.value) {
                this.redirectUrl = field.value;
                setTimeout(()=>{
                  this.navigateToRedirect();
                }, REDIRECT_TIMEOUT);
              }
            }
          });
        }
      });
  }

  navigateToRedirect() {
    window['location'].href = this.redirectUrl ;
  }
}

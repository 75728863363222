import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { CommunicationService } from '../services/communication.service';
import { AddressWithZip, Country } from '../services/models/address-with-zip';
import { Profile } from '../services/models/backend-save-classes';
import { CPMixPanel } from '../services/mixpanel.service';
import { FrontendContentConfigService } from '../services/frontend-content-config.service';
import { DriverSignupUXService } from '../services/driver-signup-ux.service';
import { ValidationService } from '../services/validation.service';
import { FieldConfig } from '../services/models/default-config';
import { LangPipe } from '../pipes/lang.pipe';
import { LanguageService } from '../services/language.service';
import { DiscoveryStoreService } from '../services/discovery.service';
import { UiFlowStateService } from '../services/ui-flow-state.service';
import { environment } from 'src/environments/environment';
import { UserAgreement } from '../services/interfaces/DiscoveryGlobalConfig';
import { Subscription } from 'rxjs';


export interface Agreement {
  name: string;
  gatedFunctionality: Array<string>;
  localizedName: string;
  optInByDefault: boolean;
  promptOnLaunch: boolean;
  version: number;
  url: string;
  webUrl: string;
}

@Component({
  selector: 'cp-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})

export class UserProfileComponent implements OnInit, OnDestroy {
  private langPipe: LangPipe;
  pressedSubmit = false;
  isLoading = true;
  masterObject: any;
  states = [{ name: 'loading', id: null }];
  countries = [{ name: 'loading', id: null }];
  errorMessages = [];
  showLoginPrompt = false;
  profileForm: FormGroup = new FormGroup({});
  fieldsConfig: Array<FieldConfig>;
  showBackButton = true;
  selectedCountry: Country;
  firstAgreementLink: string;
  remainingAgreementLinks: string;
  countryValue: AddressWithZip;
  ssoUrl: string;
  showAllNosletCountries = true;
  private agreementSubscription: Subscription;
  @Input() selectedCountryId: number;

  constructor(
    private configService: DriverSignupUXService,
    private validationService: ValidationService,
    private communicationSvc: CommunicationService,
    private mixPanel: CPMixPanel,
    private frontendConfigSvc: FrontendContentConfigService,
    private langSvc: LanguageService,
    private stateSvc: UiFlowStateService,
    private discoveryService: DiscoveryStoreService,
  ) {
    this.langPipe = new LangPipe(this.langSvc);
    this.updateSSOUrl();
  }


  ngOnInit() {

    this.agreementSubscription = this.discoveryService.userAgreements$.subscribe((userAgreements: UserAgreement[]) => {
      this.setAndupdateAgreementsUI([...userAgreements]);
    });

    const search = window.location.search;
    if (search) {
      const params = new URLSearchParams(search);
      if (params.has('requiredInstance') || params.has('requiredinstance')) {
        this.showAllNosletCountries = false;
      }
    }

    this.masterObject = this.communicationSvc.getMasterObject();
    this.frontendConfigSvc.setBodyTitle('profileTitle');

    this.countryValue = {countryId: this.discoveryService.getDefaultRegionCountry().id};

    this.communicationSvc.getComponentDefinition('session').subscribe((sessionComponent) => {
      if (sessionComponent && sessionComponent.fields) {
        sessionComponent.fields.forEach((field) => {
          if (field.id === 'sessionId' && field.value) {
            this.showLoginPrompt = true;
          }
        });
      }
    });

    this.communicationSvc.getComponentDefinition('profile').subscribe((componentDefinition) => {
      if (componentDefinition) {
        this.profileForm.addControl(
          'privacyPolicyAccept',
          new FormControl(false, {
            validators: [this.validationService.checkboxRequiredValidator()],
            updateOn: 'change'
          })
        );
        if (this.masterObject['privacyPolicyAccept']) {
          this.profileForm.get('privacyPolicyAccept').patchValue(this.masterObject['privacyPolicyAccept']);
        }
        this.fieldsConfig = this.processFields(this.configService.getComponentFieldsConfig('profile'));
        this.showBackButton = componentDefinition.showBackButton;
        setTimeout(() => {
            this.preFillData();
        }, 500);
      }
      this.isLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.agreementSubscription.unsubscribe();
  }

  submitProfile() {
    this.pressedSubmit = true;
    this.isLoading = true;
    if (this.profileForm.valid) {
      const backendSaveObj = new Profile(this.profileForm.value);
      this.validationService.isDataValid(this.profileForm.value).subscribe(resp => {
        if (resp.error) {
          this.errorMessages = [resp.message];
          this.isLoading = false;
        } else {
          this.mixPanel.profileContinue();
          this.errorMessages = [];
          const formData = this.profileForm.value;
          if (!this.masterObject.selectedCountryObj) {
            formData.selectedCountryObj = this.selectedCountry;
          }
          formData.profile_countryCode = this.selectedCountry.code;
          this.communicationSvc.submitFormFields(formData, backendSaveObj);
          this.communicationSvc.setMainAddress(backendSaveObj.address);
        }
      });
    } else {
      if (this.profileForm.status === 'PENDING') {
        setTimeout(() => {
          this.submitProfile();
        }, 500);
      } else {
        this.isLoading = false;
      }
    }
  }

  processFields(fields) {
    if (fields.username) {
      fields.username.validations.push(Validators.minLength(6));
      fields.username.validations.push(Validators.maxLength(24));
      fields.username.asyncValidations.push(this.validationService.validateUserName());
    }

    if (fields.password) {
      fields.password.validations.push(this.validationService.validatePassword());
    }

    if (fields.email) {
      fields.email.validations.push(Validators.email);
      fields.email.asyncValidations.push(this.validationService.validateUserEmail());
    }

    return fields;
  }

  preFillData() {
    if (!this.masterObject) {
      return;
    }

    const fieldNames = [
      'username', 'password', 'email', 'givenName', 'familyName',
      'profile_zipCode', 'profile_countryId', 'firstname', 'lastname', 'fullName'
    ];

    for (const fieldName of fieldNames) {
      const control = this.profileForm.get(fieldName);
      const value = this.masterObject[fieldName];

      if (control && value != null) {
        control.setValue(value);
      }
    }

    const fullName = this.masterObject['fullName'];
    if (fullName) {
      const names = fullName.split(' ');
      this.profileForm.get('givenName')?.setValue(names[0]);
      this.profileForm.get('familyName')?.setValue(names[1]);

      if (!this.profileForm.get('givenName')?.value) {
        this.profileForm.get('givenName')?.setValue(this.masterObject['firstname']);
      }

      if (!this.profileForm.get('familyName')?.value) {
        this.profileForm.get('familyName')?.setValue(this.masterObject['lastname']);
      }

      this.profileForm.get('fullName')?.setValue(fullName);
    }
  }

  loadConfiguration(country: Country) {
    this.isLoading = true;
    const formData = this.profileForm.value;
    this.stateSvc.updateSignUpData(formData);

    let token = '';
    if (this.communicationSvc.getMasterObject().partnerToken) {
      token = this.communicationSvc.getMasterObject().partnerToken;
    }
    const signupGetParams = this.communicationSvc.getMasterObject().urlSearchParams;

    const oldDiscoveryData = this.discoveryService.getDiscoveryData();
    this.discoveryService.reloadDiscoveryData(country.code).then(newDiscoveryData => {
      if (oldDiscoveryData?.endPoints?.accounts_endpoint.value !== newDiscoveryData?.endPoints?.accounts_endpoint.value) {
        this.updateSSOUrl();
        this.configService.loadDriverSignUpConfiguration(token, signupGetParams).subscribe(response => {
          if (response.components?.length) {
            this.stateSvc.setUXConfig(response, true);
            setTimeout(() => {
              this.masterObject = this.communicationSvc.getMasterObject();
              this.countryValue = { countryId: this.masterObject.selectedCountryObj.id, zipCode: this.masterObject.profile_zipCode };
            });
          } else if (response.error) {
            this.errorMessages.push(response.message);
          }
        });
      }
    })
    .finally(() => {
      this.isLoading = false;
    });
  }

  setCountryId(country: Country) {
    if (!country) {
      return;
    }
    this.communicationSvc.pushToMaster({
      selectedCountryObj: country
    });
    this.selectedCountry  = country;
    this.selectedCountryId = country.id;
  }

  setAndupdateAgreementsUI(agreements: UserAgreement[]) {
    const lastLink = agreements.pop();
    this.firstAgreementLink = '<a target="_blank" href="' + lastLink.url + '">' + lastLink.localizedName + '</a>';
    this.remainingAgreementLinks = agreements.map(
        agreement => '<a target="_blank" href="' + agreement.url + '">' + agreement.localizedName + '</a>'
    ).join(', ');
  }

  updateSSOUrl() {
    this.ssoUrl = `${this.discoveryService.getSSOUrl()}?locale=${this.langSvc.getLocale()}&redirect=${environment.DRIVER_PORTAL_URL}${this.getRedirectParam()}`;
  }

  getRedirectParam() {
    if (this.masterObject && this.masterObject['urlSearchParams'] && this.masterObject['urlSearchParams'].redirect) {
      return '?redirect=' + this.masterObject['urlSearchParams'].redirect;
    }
    return '';
  }

  shouldDisplayTandCError() {
    const control = this.profileForm.controls.privacyPolicyAccept;
    if (!control) {
      return false;
    }
    return (((control.touched && !control.pristine) || this.pressedSubmit) && (control.value === false || control.value === null));
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cp-guest-header',
  templateUrl: './guest-header.component.html',
  styleUrls: ['./guest-header.component.scss']
})
export class GuestHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}

import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { LanguageService } from './language.service';
import { LangPipe } from '../pipes/lang.pipe';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class HelmetService {
  private langPipe: LangPipe;
  private renderer: Renderer2;

  constructor(private langSvc: LanguageService, private titleService: Title, private renderFactory: RendererFactory2,) {
    this.langPipe = new LangPipe(this.langSvc);
    this.renderer = this.renderFactory.createRenderer(null, null);
   }

   updateTitle(route: ActivatedRoute) {
    const componentTitleKey = this.getCurrentComponentTitleKey(route);
    let title = '';
    if (componentTitleKey !== '') {
      title = this.langPipe.transform(componentTitleKey);
    }
    this.updateDomTitle(title);
   }

   private getCurrentComponentTitleKey(activatedRoute: ActivatedRoute): string {
    let route = activatedRoute.firstChild;
    while (route?.firstChild) {
      route = route.firstChild;
    }
    if (route && route.component) {
      return route.snapshot.data.title || '';
    }
    return '';
  }

  private updateDomTitle(title: string) {
    this.titleService.setTitle(title);
  }

  updateHTMLLang(lang: string) {
    this.renderer.setAttribute(document.documentElement, 'lang', lang);
  }
}

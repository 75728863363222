<div id="overlay" *ngIf="showCountriesDropdown" (click)="showCountriesDropdown = false"></div>
<fieldset>
  <legend class="visually-hidden">"{{'Phone Number' | T}}"</legend>

<div [formGroup]="phoneWithCountryForm" class="row">
    <div class="col-4 country-select-container">
        <select class="form-select" data-private="lipsum" [(ngModel)]="selectedCountry"
            [ngModelOptions]="{standalone: true}" [attr.aria-label]="'ariaLabelSelectCountryDialCode' | T">
            <option *ngFor="let country of countries" [ngValue]="country">+{{country.callingCode}}</option>
        </select>
        <div class="country-phone-selector form-control" (click)="showCountriesDropdown = true">
            <div class="country-phone-selected-code"></div>
            <div class="country-phone-arrow"></div>
        </div>
        <div class="country-phone-code-menu" [ngStyle]="{'display': (showCountriesDropdown ? 'block' : 'none')}">
            <div *ngFor="let country of countries"
                [ngClass]="{'phone-pick-list-selected': (selectedCountryId == country.id)}"
                class="country-pick-list-item" (click)="selectCountry(country); showCountriesDropdown=false">
                <div class="country-flag-container">
                    <div class="country-phone-flag"></div>
                </div>
                <span class="country-phone-name">{{country.name}} (+{{country.callingCode}})</span>
            </div>
        </div>
    </div>
    <div class="col-8">
        <input type="text" 
            #phoneInput 
            class="form-control" 
            data-private="lipsum" 
            (keydown.enter)="submitForm()"
            [formControl]="phoneWithCountryForm.get('phoneNumber')" 
            placeholder="{{'Phone Number' | T}}"
            [attr.aria-label]="'Phone Number' | T"
            [class.invalid]="phoneWithCountryForm.get('phoneNumber').invalid" 
            aria-describedby="phone-error"
            />
        <div *ngIf="phoneWithCountryForm.controls.phoneNumber.invalid && (phoneWithCountryForm.controls.phoneNumber.dirty || phoneWithCountryForm.controls.phoneNumber.touched)"
            class="has-error" aria-live="polite">
            <div *ngIf="phoneWithCountryForm.get('phoneNumber').errors.required" id="phone-error">
                {{"errorMsgRequiredPhone" | T}}
            </div>
            <div *ngIf="phoneWithCountryForm.get('phoneNumber').errors.phoneIsBadFormat" id="phone-error">
                {{ "Invalid phone number." | T}}
            </div>
        </div>
    </div>
</div>
</fieldset>
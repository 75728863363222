<div id="overlay" *ngIf="showDropdown" (click)="closeDropdown($event)"></div>
<fieldset aria-describedby="phone-error">
  <legend class="visually-hidden">"{{'Phone Number' | T}}"</legend>
  <div [formGroup]="_parentForm" class="row">
    <div class="col-4 country-select-container">
      <select class="form-select" data-private="lipsum" [attr.aria-label]="'ariaLabelSelectCountryDialCode' | T"
        [formControlName]="controlnamePrefix + '_country_dial_code'" required="true">
        <option *ngFor="let country of countries" value="{{country.callingCode}}">+{{country.callingCode}}</option>
      </select>
      <div class="country-phone-selector form-control" (click)="clickSelect($event)">
        <div class="country-phone-selected-code"></div>
        <div class="country-phone-arrow"></div>
      </div>
      <div class="country-phone-code-menu" [ngStyle]="{'display': (showDropdown ? 'block' : 'none')}">
        <div *ngFor="let country of countries"
          [ngClass]="{'phone-pick-list-selected': (selectedCountryId == country.id)}" class="country-pick-list-item"
          (click)="selectCountry(country)">
          <div class="country-flag-container">
            <div class="country-phone-flag"></div>
          </div>
          <span class="country-phone-name">{{country.name}} (+{{country.callingCode}})</span>
        </div>
      </div>
    </div>

    <div class="col-8">
      <input type="text" 
        #phoneInput 
        class="form-control" 
        data-private="lipsum" 
        (keydown.enter)="enterHit($event)"
        [formControlName]="controlnamePrefix + '_phone'" 
        placeholder="{{'Phone Number' | T}}"
        [class.invalid]="updateError(controlnamePrefix + '_phone')"
        [attr.aria-label]="'Phone Number' | T"
        aria-describedby="phone-error"
        required="true"
        />
      <div *ngIf="updateError(controlnamePrefix + '_phone')" class="has-error">
        <div *ngIf="_parentForm.get(controlnamePrefix + '_phone').errors.required" id="phone-error">
          {{"errorMsgRequiredPhone" | T}}
        </div>
        <div *ngIf="_parentForm.get(controlnamePrefix + '_phone').errors.pattern" id="phone-error">
          {{ "Invalid phone number." | T}}
        </div>
      </div>
    </div>
  </div>
</fieldset>
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { AddressService } from './address.service';
import { AddressWithZip, Country, CountryState } from '../../services/models/address-with-zip';
import { CommunicationService } from '../../services/communication.service';
import { ValidationService } from '../../services/validation.service';
import { DriverSignupUXService } from '../../services/driver-signup-ux.service';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { FieldConfig } from '../../services/models/default-config';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'cp-address-form',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  states: Array<CountryState>;
  countries: Array<Country>;
  fieldsConfig: Array<FieldConfig>;
  controls: Array<AbstractControl>;
  addressValue: AddressWithZip;
  @Output() countryChange: EventEmitter<Country> = new EventEmitter();
  @Output() onLoadConfiguration: EventEmitter<Country> = new EventEmitter();
  countryId: number;
  @Input() needValidate = false;
  @Input() pressedSubmit: boolean;
  @Input() showAllNosletCountries = false;

  parentForm: FormGroup = new FormGroup({});
  @Input() controlnamePrefix = '';
  // prefix to add to the naming of the controls, since there can be many address instances within a single parent.

  @Input() set setParentForm(frmGrp: FormGroup) {
    this.parentForm = frmGrp;
  }

  @Input() set setConfig(config) {
    this.fieldsConfig = this.processFields(config);
  }

  @Input() set setAddress(data: AddressWithZip) {
    if (data) {
      this.addressValue = data;
      if (this.controls) {
        this.preFillAddress();
      }
    }
  }

  constructor (
    private addressClass: AddressService,
    private communicationSvc: CommunicationService,
    private validationService: ValidationService,
    private configService: DriverSignupUXService,
    private languageService: LanguageService,
    ) {
    this.controls = [];
    this.countries = [];
    this.states = [];
  }

  ngOnInit() {
    if (!this.fieldsConfig) {
      this.communicationSvc.getComponentDefinition('profile').subscribe((componentDefinition) => {
        if (componentDefinition) {
          this.fieldsConfig = this.processFields(this.configService.getTypeFields(this.controlnamePrefix, 'Address'));
        } else {
          /* mobile webview */
          this.fieldsConfig = this.processFields(this.configService.getTypeFieldList('Address', this.controlnamePrefix));
        }
        this.initData();
      });
    } else {
      this.initData();
    }
    this.languageService.localeEmmiter.subscribe(() => {
      this.initData();
    });
  }

  initData() {
    if (this.showAllNosletCountries) {
      this.initAllNosletCountires();
    } else  {
      this.initSupportedCountries();
    }
  }

  initAllNosletCountires() {
    this.addressClass.getAllSupportedCountriesAccrossNoslets().pipe(
      map((data: Array<Country>) => {
        this.countries = data;
        this.getControls();
        this.initStateData();
       }
    )).subscribe();
  }

  initSupportedCountries() {
    this.addressClass.getCountries().then(data => {
      this.countries = data;
      this.getControls();
      this.initStateData();
    });
  }

  initStateData() {
    if (this.controls['countryId']) {
      if (!this.controls['countryId'].value) {
        // if data comes from the session storage
        const masterObj = this.communicationSvc.getMasterObject();
        if (masterObj && masterObj[this.fieldsConfig['countryId'].name]) {
          this.controls['countryId'].setValue(masterObj[this.fieldsConfig['countryId'].name]);
        } else {
          // backend always sends the instance country first. load states after.
          this.controls['countryId'].setValue(this.countries[0].id);
        }
        this.countryId = this.controls['countryId'].value;
        this.emitCountryChange();
        if (this.controls['stateId']) {
          this.addressClass.getStates(this.controls['countryId'].value).then(result => {
            this.states = result;
            if (masterObj && this.fieldsConfig['stateId'] && masterObj[this.fieldsConfig['stateId'].name]) {
              this.controls['stateId'].setValue(masterObj[this.fieldsConfig['stateId'].name]);
            }
          });
        }
      } else {
        if (this.controls['stateId']) {
          this.addressClass.getStates(this.controls['countryId'].value).then(result => {
            this.states = result;
          });
        }
      }
    }
  }

  getControls() {
    for (const fieldId in this.fieldsConfig) {
      if (this.fieldsConfig.hasOwnProperty(fieldId)) {
        this.controls[fieldId] = this.parentForm.get(this.fieldsConfig[fieldId].name);
      }
    }
    this.preFillAddress();
  }

  onCountryChange(countryKey) {
    if (countryKey > 0) {
      this.countryId = countryKey;
      const selectedCountry = this.getSelectedCountry();
      this.emitCountryChange();
      this.onLoadConfiguration.emit(selectedCountry);
      if (this.controls['stateId']) {
        this.addressClass.getStates(countryKey).then(data => {
          this.states = data;
        });
      }
    } else {
      this.states = null;
    }
    // resets the states form
    if (this.controls['stateId']) {
      this.controls['stateId'].setValue('');
    }
    if (this.controls['zipCode'].value !== '') {
      this.controls['zipCode'].updateValueAndValidity();
    }
  }

  isZipValid(): AsyncValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value || !this.controls['countryId']) {
        return of(null);
      } else {
        return this.validationService.isZipValid(
          control.value,
          this.controls['countryId'].value
        ).pipe(
          map(resp => {
            if (resp.error) {
                return { zipInvalid: true };
            } else {
              return null;
            }
          })
        );
      }
    };
  }

  setCountry(countryId: number) {
    this.countryId = countryId;
    if (this.controls['countryId']) {
      this.controls['countryId'].setValue(countryId);
      this.emitCountryChange();
    }
    return this.addressClass.getStates(countryId).then(data => {
      this.states = data;
    });
  }

  preFillAddress() {
    if (this.addressValue) {
      if (this.addressValue['address1'] && this.controls['address1'] && !this.controls['address1'].value) {
        this.controls['address1'].setValue(this.addressValue['address1']);
        this.controls['address2'].setValue(this.addressValue['address2']);
        this.controls['city'].setValue(this.addressValue['city']);
      }

      if (this.addressValue['zipCode'] && this.controls['zipCode'] && !this.controls['zipCode'].value) {
        this.controls['zipCode'].setValue(this.addressValue['zipCode']);
      }

      if (this.addressValue['countryId'] && this.controls['countryId']) {
        this.setCountry(this.addressValue['countryId']).then(() => {
          if (this.addressValue['stateId'] && this.controls['stateId']) {
            this.controls['stateId'].setValue(this.addressValue['stateId']);
          }
        });
      }
    } else {
      const masterObj = this.communicationSvc.getMasterObject();
      if (masterObj) {
        if (this.fieldsConfig['address1'] && masterObj[this.fieldsConfig['address1'].name]
          && this.controls['address1'] && !this.controls['address1'].value
        ) {
          this.controls['address1'].setValue(masterObj[this.fieldsConfig['address1'].name]);
          this.controls['address2'].setValue(masterObj[this.fieldsConfig['address2'].name]);
          this.controls['city'].setValue(masterObj[this.fieldsConfig['city'].name]);
        }

        if (this.fieldsConfig['zipCode'] && masterObj[this.fieldsConfig['zipCode'].name]
          && this.controls['zipCode'] && !this.controls['zipCode'].value) {
          this.controls['zipCode'].setValue(masterObj[this.fieldsConfig['zipCode'].name]);
        }

        if (this.fieldsConfig['countryId'] && masterObj[this.fieldsConfig['countryId'].name]
          && this.controls['countryId'] && !this.controls['countryId'].value) {
          this.setCountry(masterObj[this.fieldsConfig['countryId'].name]).then(() => {
            if (this.fieldsConfig['stateId'] && masterObj[this.fieldsConfig['stateId'].name]
              && this.controls['stateId'] && !this.controls['stateId'].value) {
              this.controls['stateId'].setValue(masterObj[this.fieldsConfig['stateId'].name]);
            }
          });
        }
      }
    }
  }

  emitCountryChange() {
    const selectedCountry = this.getSelectedCountry();
    this.countryChange.emit(selectedCountry);
  }

  getSelectedCountry() {
    if (this.countries.length === 0) {
      this.addressClass.getCountries().then(countries => {
        return countries.find((country: Country) => {
          return country.id === this.controls['countryId'].value;
        });
      });
    } else {
      return this.countries.find((country: Country) => {
        return country.id === this.controls['countryId'].value;
      });
    }
  }

  processFields(config) {
    if (config['zipCode']) {
      config['zipCode'].asyncValidations.push(this.isZipValid());
    }

    return config;
  }
}

import { Injectable } from '@angular/core';
import { CommunicationService } from './communication.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, switchMap } from 'rxjs/operators';
import { of, timer } from 'rxjs';
import {
  AsyncValidatorFn,
  AbstractControl,
  ValidatorFn
} from '@angular/forms';
import { DiscoveryStoreService } from './discovery.service';
import { LanguageService } from './language.service';

const PROFILE_VALIDATE_ACTION = 'driver/profile/user/validateuserdata';
const META_VALIDATE_ACTION = 'metadata';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  private httpOptions: Object;

  constructor(
    private http: HttpClient, 
    private commService: CommunicationService,
    private discoveryService: DiscoveryStoreService,
    private languageService: LanguageService,
    ) {
      this.httpOptions = {
        withCredentials: true,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept-Language': this.languageService.getLocale(),
        })
      };
  }

  validateUserName(): AsyncValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return of(null);
      } else {
        return timer(600).pipe(
          switchMap(() => {
            return this.isUserNameTaken(control.value).pipe(
              map(resp => {
                if (resp.error) {
                  if (resp.errorId === 73) {
                    return { usernameInvalid: true };
                  } else {
                    return { usernameExists: true };
                  }
                } else {
                  return null;
                }
              })
            );
          })
        )

      }
    };
  }

  validateUserEmail(): AsyncValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return of(null);
      }
      return timer(600).pipe(
        switchMap(() => {
          return this.isEmailTaken(control.value).pipe(
            map(resp => {
              if (resp.error) {
                if (resp.errorId === 92) {
                  return { emailExists: true };
                } else {
                  return { email: true };
                }
              } else {
                return null;
              }
            })
          );
        })
      )

    };
  }

  validatePassword(): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.value) {
        if (control.value.length < 8 || control.value.length > 64) {
          return { wrongSize: true };
        }
        if (!control.value.match(/[a-z]/i)) {
          return { needChar: true };
        }
      }
    };
  }

  validateExpiration(): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.value.length > 0 && control.value.indexOf('/') !== -1) {
        if (control.value[2] !== '/') {
          return { invalid: true };
        } else {
          // if exp date is past or more than 20 years than today then return false;
          const monthYear = control.value.split('/');
          const expMonth = parseInt(monthYear[0], 10);
          const expYear = parseInt(monthYear[1], 10);

          const today = new Date();
          const thisYear = parseInt(today.getFullYear().toString().substring(2), 10);

          const result = (expMonth >= 1 && expMonth <= 12
            && (expYear >= thisYear && expYear < thisYear + 20)
            && (expYear === thisYear ? expMonth >= (today.getMonth() + 1) : true));

          if (!result) {
            return { invalid: true };
          }
        }
      }
      return null;
    };
  }

  validateSerialNumber(): AsyncValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return of(null);
      } else {
        return timer(600).pipe(
          switchMap(() => {
            return this.isSerialValid(control.value).pipe(
              map(resp => {
                if (resp.error) {
                  if (resp.message !== 'error') {
                    return {
                      server: true,
                      message: resp.message
                    }
                  }
                  return { pattern: true };
                } else {
                  return null;
                }
              })
            );
          })
        )
      }
    };
  }

  isEmailTaken(email) {
    return this.validateProfileData(JSON.stringify({ email: email }));
  }

  isUserNameTaken(name) {
    return this.validateProfileData(JSON.stringify({ username: name }));
  }

  isZipValid(zip, country) {
    return this.validateProfileData(JSON.stringify({ address: { zipCode: zip, countryId: country } }));
  }

  isDataValid(formObj: any) {
    const data = {
      fullName: '',
      username: formObj.username,
      email: formObj.email,
      password: formObj.password,
      address: {
        address1: formObj.profile_address1,
        address2: formObj.profile_address2,
        city: formObj.profile_city,
        zipCode: formObj.profile_zipCode,
        countryId: formObj.profile_countryId,
        stateId: formObj.profile_stateId
      }
    };

    if (formObj.fullName !== undefined) {
      data.fullName = formObj.fullName;
    } else if (formObj.firstname !== undefined) {
      data.fullName = formObj.firstname + ' ' + formObj.lastname;
    } else if (formObj.givenName !== undefined) {
      data.fullName = formObj.givenName + ' ' + formObj.familyName;
    }

    const paramStr = JSON.stringify(data);
    return this.validateProfileData(paramStr)
  }

  private validateProfileData(paramStr: string) {
    return this.http.post<any>(
      `${this.discoveryService.getAccountV2ApiEndpoint()}/${PROFILE_VALIDATE_ACTION}`,
      paramStr,
      this.httpOptions
    ).pipe(
      map(response => {
        return { error: false, message: 'ok', errorId: 0 };
      }),
      catchError((err) => {
        const result = { error: true, message: 'error', errorId: 0 };
        if (err.error && err.error.errorMessage) {
          result.message = err.error.errorMessage;
          if (err.error.errorId) {
            result.errorId = err.error.errorId;
          }
        }
        return of(result);
      })
    );
  }

  isSerialValid(serialNumber: string) {
    const partnerToken = this.commService.getPartnerToken();
    const data: { serialNumber: string[], token?: string } = { serialNumber: [serialNumber] };
    if (partnerToken) {
      data.token = partnerToken;
    }
    return this.validateMetaData('serialnumber/validate', JSON.stringify(data));
  }

  isPhoneValid(phoneNumber: string, country: number) {
    const data = {
      phone: phoneNumber,
      countryId: country
    };
    return this.validateMetaData('phone/validate', JSON.stringify(data));
  }

  validatePhone(phoneNumber: string, countryId: number) {
    return this.http.post<any>(`${this.discoveryService.getAccountV1ApiEndpoint()}/metadata/phone/validate`,{
      phone: phoneNumber,
      countryId: countryId
    })
  }

  private validateMetaData(action: string, params: any) {
    return this.http.post<any>(
      `${this.discoveryService.getAccountV1ApiEndpoint()}/${META_VALIDATE_ACTION}/${action}`,
      params,
      this.httpOptions
    ).pipe(
      map(response => {
        return { error: false, message: 'ok', errorId: 0 };
      }),
      catchError((err) => {
        const result = { error: true, message: 'error', errorId: 0 };
        if (err.error && err.error.errorMessage) {
          result.message = err.error.errorMessage;
          if (err.error.errorId) {
            result.errorId = err.error.errorId;
          }
        } else if (err.error && Object.keys(err.error)[0] && err.error[Object.keys(err.error)[0]].errorMessage) {
          result.message = err.error[Object.keys(err.error)[0]].errorMessage;
        }
        return of(result);
      })
    );
  }

  checkboxRequiredValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value === false) {
        return { checkboxRequired: true };
      }
      return null;
    };
  }

}

const Address = {
  component: 'cp-address-form',
  fields: {
    address1: {
      component: 'cp-input',
      required: true
    },
    address2: {
      component: 'cp-input',
      required: false
    },
    city: {
      component: 'cp-input',
      required: true
    },
    countryId: {
      component: 'cp-select',
      required: true
    },
    stateId: {
      component: 'cp-select',
      required: true
    },
    zipCode: {
      component: 'cp-input',
      required: true
    }
  }
};

const MimimumAddress = {
  component: 'cp-address-form',
  fields: {
    countryId: {
      component: 'cp-select',
      required: true
    },
    zipCode: {
      component: 'cp-input',
      required: true
    }
  }
};

const Vehicle = {
  component: 'cp-vehicle-form',
  fields: {
    makeId: {
      component: 'cp-select',
      required: true
    },
    modelId: {
      component: 'cp-select',
      required: true
    },
    year: {
      component: 'cp-select',
      required: true
    },
    colorId: {
      component: 'cp-select',
      required: true
    },
    primaryVehicle: {
      component: 'cp-checkbox',
      required: true
    }
  }
};

const ConnectionField = {
  component: 'none',
  fields: {
    id: {
      component: 'cp-input',
      required: true
    },
    key: {
      component: 'cp-checkbox',
      required: true
    },
    name: {
      component: 'cp-checkbox',
      required: true
    },
    value: {
      component: 'cp-checkbox',
      required: true
    }
  }
};

const SendVerifyCodeModel = {
  component: 'none',
  fields: {
    code: {
      component: 'cp-input',
      required: true
    },
    countryId: {
      component: 'cp-input',
      required: true
    },
    phone: {
      component: 'cp-input',
      required: true
    }
  }
};

const PhoneModel = {
  component: 'cp-phone-w-country',
  fields: {
    countryId: {
      component: 'cp-input',
      required: true
    },
    phone: {
      component: 'cp-input',
      required: true
    }
  }
};

const Card = {
  component: 'cp-card-form',
  fields: {
    nickname: {
      component: 'cp-input',
      required: true
    },
    serialNumber: {
      component: 'cp-input',
      required: true
    }
  }
};

const ChargingStation = {
  component: 'none',
  fields: {
    chargingInterrupted: {
      component: 'cp-checkbox',
      required: true
    },
    fullyCharged: {
      component: 'cp-checkbox',
      required: true
    }
  }
};

const PublicChargingStation = {
  component: 'none',
  fields: {
    receipt: {
      component: 'cp-checkbox',
      required: true
    },
    chargingInterrupted: {
      component: 'cp-checkbox',
      required: true
    },
    fullyCharged: {
      component: 'cp-checkbox',
      required: true
    }
  }
};

const NotificationMethod = {
  component: 'none',
  fields: {
    email: {
      component: 'cp-checkbox',
      required: false
    },
    push: {
      component: 'cp-checkbox',
      required: false
    },
    text: {
      component: 'cp-checkbox',
      required: false
    }
  }
};

const UserInfo = {
  component: 'none',
  fields: {
    countryId: {
      component: 'cp-select',
      required: true
    },
    email: {
      component: 'cp-input',
      required: true
    },
    phone: {
      component: 'cp-input',
      required: true
    }
  }
};

const Notification = {
  component: 'none',
  fields: {
    homeCharger: ChargingStation,
    notificationMethod: NotificationMethod,
    publicCharging: PublicChargingStation,
    userInfo: UserInfo,
  }
};

export const COMPONENT_CONFIG = {
  profile: {
    fullName: {
      component: 'cp-input',
      required: true
    },
    givenName: {
      component: 'cp-input',
      required: true
    },
    familyName: {
      component: 'cp-input',
      required: true
    },
    username: {
      component: 'cp-user-name',
      required: true
    },
    email: {
      component: 'cp-user-email',
      required: true
    },
    password: {
      component: 'cp-password',
      required: true
    },
    address: Address,
    privacyPolicyAccept: {
      component: 'cp-checkbox',
      required: false
    }
  },
  account: {
    sendRfid: {
      component: 'cp-checkbox',
      required: false
    },
    rfidAddress: Address,
    vehicle: Vehicle,
    notifications: Notification,
    cards: Card
  },
  payment: {
    ccNumber: {
      component: 'cp-input',
      required: true
    },
    cvv: {
      component: 'cp-input',
      required: true
    },
    expirationDate: {
      component: 'cp-input',
      required: true
    },
    zipCode: {
      component: 'cp-input',
      required: true
    },
    promoCode: {
      component: 'cp-input',
      required: true
    },
  },
  getTypeConfig: function(type) {
    switch (type) {
      case 'Address':
        return Address;
      case 'Vehicle':
        return Vehicle;
      case 'Notification':
        return Notification;
      case 'Card':
        return Card;
      case 'ChargingStation':
        return ChargingStation;
      case 'NotificationMethod':
        return NotificationMethod;
      case 'PublicChargingStation':
        return PublicChargingStation;
      case 'UserInfo':
        return UserInfo;
      case 'MimimumAddress':
        return MimimumAddress;
      case 'ConnectionField':
        return ConnectionField;
      case 'PhoneModel':
        return PhoneModel;
      case 'SendVerifyCodeModel':
        return SendVerifyCodeModel;
      default:
        return null;
    }
  }
};

export class FieldConfig {
  component: string;
  validations: Array<any>;
  asyncValidations: Array<any>;
  name: string;
  placeholder: string;
  value: string;
  inputMaxLength: number;
  fields: Array<FieldConfig>;
}

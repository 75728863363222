import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'htmlEntity'
})
export class HtmlEntityPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const tempEl = document.createElement('span');
    tempEl.innerHTML = value;
    return tempEl.innerText;
  }
}

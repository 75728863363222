import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AppConfig } from '../app-interfaces/interfaces';
import { AppConfigService } from '../services/app-config.service';
import md5 from 'blueimp-md5';
import { ReCaptchaService } from './re-captcha.service';
import { LanguageService } from '../services/language.service';
import { environment } from 'src/environments/environment';
import { DiscoveryStoreService } from '../services/discovery.service';
import {
  ChallengeWindowSize,
  getBrowserData,
  handle3dsVersionCheck,
  handleInitiateAuthentication,
  ICheckVersionResponseData,
  IInitiateAuthenticationResponseData,
  MethodUrlCompletion,
  TransactionStatus,
} from 'globalpayments-3ds';
import { CPMixPanel } from '../services/mixpanel.service';

@Injectable({
  providedIn: 'root'
})
export class UserPaymentService {
  orgType: string;
  authenticationSource = 'BROWSER';

  constructor(
    private http: HttpClient,
    private appConfig: AppConfigService,
    private reCaptcha: ReCaptchaService,
    private langSvc: LanguageService,
    private discoveryService: DiscoveryStoreService,
    private mixPanel: CPMixPanel,
  ) {
    this.orgType = 'WEB';
  }

  checkPromo(promoCode) {
    return this.http.post<any>(`${this.discoveryService.getAccountV2ApiEndpoint()}/metadata/validatepromocode`, { promoCode: promoCode },
      this.getHttpOptions());
  }

  getReplenishmentAmount(code) {
    return this.http.post<any>(
      environment.DISCOVERY_API,
      {
        countryCode: code,
        deviceData: {
          'type': 'not-web'
        }
      },
      this.getHttpOptions()
    ).pipe(
      map((resp) => {
        return resp.currency;
      }),
      catchError((error) => {
        return of({});
      })
    );
  }

  async authorizeCreditCard(
      ccObj: { cardNumber: string; cvv: string; expirationMonth: number; expirationYear: number; serverTransactionId?: string },
      sessionToken?: string,
      serverTransactionId?: string
  ) {
    const reCaptchaToken = await this.reCaptcha.getToken('CreditCardAuthorize').toPromise();
    const timestamp = Math.floor(Date.now() / 1000).toString();
    const signature = this.generateSignature(ccObj, timestamp);
    ccObj.serverTransactionId = serverTransactionId ? serverTransactionId : '';
    const url = `${this.discoveryService.getPaymentApiEndpoint()}v2/driver/registered/payment/creditcard/authorize?timestamp=${timestamp}&signature=${signature}`;
    return await this.http.post<any>(url, JSON.stringify(ccObj), this.getV2HttpOptions(reCaptchaToken, sessionToken)).toPromise();
  }

  private generateSignature(
      ccObj: { cardNumber: string; cvv: string; expirationMonth: number; expirationYear: number },
      timestamp: string
  ) {
    const message = `${ccObj.cardNumber}${ccObj.cvv}${ccObj.expirationMonth}${ccObj.expirationYear}${timestamp}`;
    return md5(message);
  }

  paypalSetExpressCheckout(obj) {
    return this.appConfig.getConfig().pipe(
      mergeMap((config: AppConfig) => {
        return this.http.post<any>(config.paypalSetExpressCheckout, JSON.stringify(obj), this.getHttpOptions());
      })
    );
  }

  authorizePaypal(obj) {
    return this.appConfig.getConfig().pipe(
      mergeMap((config: AppConfig) => {
        return this.http.post<any>(config.paypalAuthorizeURL, JSON.stringify(obj), this.getHttpOptions());
      })
    );
  }

  getLeascoFields(token) {
    return this.http.get<any>(`${this.discoveryService.getAccountV2ApiEndpoint()}/driver/connection/configuration?token=${token}`).pipe(
      catchError((error) => {
        return of({});
      })
    );
  }

  async checkCardVersion(masterObj: any, ccObj: any, sessionToken?: string) {
    this.mixPanel.card3dsInitialization({ 'User Type': 'Driver' });

    const config = await this.appConfig.getConfig().toPromise();
    const reCaptchaToken = await this.reCaptcha.getToken('CreditCardAuthorize').toPromise();

    const body = {
      cardNumber: ccObj.cardNumber,
      authenticationSource: this.authenticationSource,
      currency: config.currencyCode
    };

    let checkResponse = await this.http.post<any>(
        config.cc3DCheckUrl,
        JSON.stringify(body),
        this.getV2HttpOptions(reCaptchaToken, sessionToken)
    ).toPromise() as ICheckVersionResponseData;

    this.mixPanel.card3dsCheckVersion({
      'User Type': 'Driver',
      'Is Enrolled': checkResponse.enrolled,
      'Server Transaction ID': checkResponse.serverTransactionId
    });

    // skip next steps if card is not enrolled for 3D secure
    if (typeof checkResponse.enrolled === 'string' && checkResponse.enrolled !== 'true') {
      // send a success status to start authorize process
      return {
        status: TransactionStatus.AuthenticationSuccessful
      };
    }

    let methodUrlComplete = MethodUrlCompletion.Unavailable;

    if (checkResponse.methodUrl) {
      try {
        // 3dsVersionCheck always returns the same checkResponse object without change
        checkResponse = await handle3dsVersionCheck(
            checkResponse,
            {
              timeout: 10 * 1000,
              origin: config.paymentURL.replace('/payment/', '')
            }
        );
        methodUrlComplete = MethodUrlCompletion.Yes;
      } catch (e) {
        // according to global payment docs UI should not wait for a success
        // not all banks support this step
        methodUrlComplete = MethodUrlCompletion.No;
      }
    }

    return await this.AuthenticateCard3D(config, masterObj, checkResponse, ccObj, methodUrlComplete, sessionToken);
  }

  async AuthenticateCard3D(
      config: AppConfig,
      masterObj,
      checkResponse: ICheckVersionResponseData,
      ccObj: any,
      methodUrlComplete: MethodUrlCompletion,
      sessionToken?: string
  ) {
    const reCaptchaToken = await this.reCaptcha.getToken('CreditCardAuthorize').toPromise();
    const browserData = getBrowserData();
    browserData['challengeWindowSize'] = ChallengeWindowSize.FullScreen;
    browserData['acceptHeader'] = 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8';

    const body = {
      authenticationSource: this.authenticationSource,
      methodUrlComplete: methodUrlComplete,
      browserData: browserData,
      cardDetail: {
        cardNumber: ccObj.cardNumber,
        expirationMonth: Number(ccObj.expirationMonth).toString(),
        expirationYear: Number(ccObj.expirationYear).toString(),
        firstName: masterObj['givenName'],
        lastName: masterObj['familyName']
      },
      order: {
        amount: 0,
        currency: config.currencyCode,
        shippingAddress: {
          country: ccObj.address.countryCode,
          zipCode: ccObj.address.zipCode
        }
      },
      payer: {
        billingAddress: {
          country: ccObj.address.countryCode,
          zipCode: ccObj.address.zipCode
        },
        email: masterObj['email'],
        mobilePhone: {
          countryCode: masterObj['_country_dial_code'],
          subscriberNumber: masterObj['_phone']
        }
      },
      serverTransactionId: checkResponse.serverTransactionId
          ? checkResponse.serverTransactionId
          : ''
    };

    // call payment api to authenticate card for 3D secure
    const authenticateData = await this.http.post<any>(
        config.cc3DAuthenticateURL,
        JSON.stringify(body),
        this.getV2HttpOptions(reCaptchaToken, sessionToken)
    ).toPromise() as IInitiateAuthenticationResponseData;

    this.mixPanel.card3dsAuthenticate({
      'User Type': 'Driver',
      'Authenticate Status': authenticateData.status,
      'Server Transaction ID': checkResponse.serverTransactionId,
      'ACS Transaction ID': authenticateData.acsTransactionId
    });

    // skip challenge flow if it is not required
    if (authenticateData.status !== TransactionStatus.ChallengeRequired) {
      authenticateData.serverTransactionId = checkResponse.serverTransactionId;
      return authenticateData;
    }

    return await handleInitiateAuthentication(
        authenticateData,
        {
          origin: config.paymentURL.replace('/payment/', ''),
          displayMode: 'lightbox',
          windowSize: ChallengeWindowSize.FullScreen
        }
    );
  }

  getHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Accept-Language': this.langSvc.getLocale()
      })
    };
  }

  getV2HttpOptions(reCaptchaToken: string, sessionToken?: string) {
    const httpHeaders = {
      'Content-Type': 'application/json',
      'Accept': '*/*',
      'Accept-Language': this.langSvc.getLocale(),
      'CP-Captcha-Token': reCaptchaToken,
      'CP-Session-Type': this.orgType
    };

    if (sessionToken) {
      httpHeaders['CP-Session-Token'] = sessionToken;
    }

    return {
      withCredentials: true,
      headers: new HttpHeaders(httpHeaders)
    };
  }
  setOrgType(orgType: string) {
    this.orgType = orgType;
  }
}

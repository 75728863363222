<div class="form-group" [formGroup]="parentForm" *ngIf="fieldConfig">
  <input 
    type="text"
    [attr.maxlength]="fieldConfig.inputMaxLength"
    class="form-control"
    data-private="lipsum"
    (keypress)="checkChange($event)"
    (keydown.enter)='enterKeyPressed.emit()'
    #textInput
    [formControlName]="fieldConfig.name" 
    [placeholder]="translatedPlaceholder"
    [class.invalid]="updateError()" 
    [attr.aria-label]="ariaLabelOveride? ariaLabelOveride : translatedPlaceholder"
    [attr.aria-describedby]="(fieldConfig.name === 'username' ? inputId + 'help ' : '') + inputId + 'error'"
    [required]="required"
    />
 
  <small *ngIf="fieldConfig.name === 'username'" [id]="inputId + 'help'" class="form-text text-muted">{{"This is also your community name" |
    T}}</small>
  <div *ngIf="updateError()" class="has-error">
    <span *ngIf="control.errors.required" [id]="inputId + 'error'">
      {{"errorMsgRequiredField" | T: {fieldName: ariaLabelOveride? ariaLabelOveride : translatedPlaceholder} }}
    </span>
    <span *ngIf="control.errors.minlength && fieldConfig.name === 'username'" [id]="inputId + 'error'">
      {{"Username less than 6 characters." |T }}
    </span>
    <span *ngIf="control.errors.maxlength && fieldConfig.name === 'username'" [id]="inputId + 'error'">
      {{"24 characters maximum." |T }}
    </span>
    <span *ngIf="control.errors.minlength && fieldConfig.name === 'smscode'" [id]="inputId + 'error'">
      {{"4 characters minimum." |T }}
    </span>
    <span *ngIf="control.errors.maxlength && fieldConfig.name === 'smscode'" [id]="inputId + 'error'">
      {{"4 characters maximum." |T }}
    </span>
    <span *ngIf="control.errors.usernameExists" [id]="inputId + 'error'">
      {{"This username is not available." |T}}
    </span>
    <span *ngIf="control.errors.usernameInvalid" [id]="inputId + 'error'">
      {{"Username contains invalid characters." |T}}
    </span>
    <span *ngIf="control.errors.email" [id]="inputId + 'error'">
      {{ "Please enter a valid email." | T}}
    </span>
    <span *ngIf="control.errors.emailExists" [id]="inputId + 'error'">
      {{ "This email is not available." | T }}
    </span>
    <span *ngIf="control.errors.wrongSize" [id]="inputId + 'error'">
      {{"Please enter a value between 6 and 64 characters long." | T}}
    </span>
    <span *ngIf="control.errors.needDigit" [id]="inputId + 'error'">
      {{"Your password must contain at least one digit." | T }}
    </span>
    <span *ngIf="control.errors.needChar" [id]="inputId + 'error'">
      {{"Your password must contain at least one character." |T }}
    </span>
    <span *ngIf="control.errors.serailInvalid" [id]="inputId + 'error'">
      {{ "Invalid serial number." | T}}
    </span>
    <span *ngIf="control.errors.zipInvalid" [id]="inputId + 'error'">
      {{"Zip code is invalid." | T }}
    </span>
  </div>
</div>
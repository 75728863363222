import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommunicationService } from 'src/app/services/communication.service';
import { Vehicle } from 'src/app/services/models/backend-save-classes';
import { FrontendContentConfigService } from 'src/app/services/frontend-content-config.service';
import { CPMixPanel } from 'src/app/services/mixpanel.service';


@Component({
  selector: 'cp-electric-vehicle-v2',
  templateUrl: './electric-vehicle-v2.component.html',
  styleUrls: ['./electric-vehicle-v2.component.scss']
})

export class ElectricVehicleV2Component implements OnInit {
  pressedSubmit = false;
  formGroup: FormGroup;
  @ViewChild('CPEVForm') cpEvForm;
  constructor(
    private comSvc: CommunicationService,
    private frontEndConfig: FrontendContentConfigService,
    private ref: ChangeDetectorRef,
    private mixpanel: CPMixPanel
  ) { }

  ngOnInit() {
    this.frontEndConfig.setBodyTitle('whatAreYouDrivingTitle');
    this.formGroup = new FormGroup({});
    this.mixpanel.addEV();
  }
  submitForm() {
    this.cpEvForm.setValidators();
    this.ref.detectChanges();
    this.pressedSubmit = true;
    if (this.formGroup.invalid) {
      return;
    }
    const vehicleObj = new Vehicle(this.formGroup.value);
    const backEndSaveObj = {vehicle: vehicleObj};
    this.comSvc.submitFormFields(this.formGroup.value, backEndSaveObj);
    this.mixpanel.evInformation(vehicleObj);
  }

  skipEV() {
    this.formGroup.reset();
    this.comSvc.pushToMaster(this.formGroup.value, {});
    this.mixpanel.skipEV();
    this.comSvc.skipSubmitNextRoute();
  }
}
